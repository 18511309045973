export const APP_AUTH_TOKEN = process.env.REACT_APP_ID + '_auth';

export function saveToken(token: string) {
  return localStorage.setItem(APP_AUTH_TOKEN, token);
}

export function getToken() {
  return localStorage.getItem(APP_AUTH_TOKEN);
}

export function removeToken() {
  return localStorage.removeItem(APP_AUTH_TOKEN);
}

export function saveTokenQbit(token: string) {
  return localStorage.setItem('tokenQbit', token);
}

export function showOktaLogConfig(e: boolean) {
  if (e) {
    console.log(
      'process.env.REACT_APP_OKTA_CLIENT_ID',
      process.env.REACT_APP_OKTA_CLIENT_ID
    );
    console.log(
      'process.env.REACT_APP_OKTA_REDIRECT_URI',
      process.env.REACT_APP_OKTA_REDIRECT_URI
    );
    console.log(
      'process.env.REACT_APP_OKTA_ISSUER',
      process.env.REACT_APP_OKTA_ISSUER
    );
  }
}
