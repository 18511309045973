import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Button, Spin } from 'antd';
import ReportSummary from './components/ReportSummary';
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import styled from 'styled-components';
import ReportBodyVessel from './components/ReportBodyVessel';
import FilterDashboard from '../dashboard/components/FilterDashboard';
import useFilter from '../../hooks/useFilter';
import useAsset from '../../hooks/useAsset';
import { ReportActionContent } from './ReportLog';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import { logoRamusBase64 } from '../../const/pdf';
import moment from 'moment';
import * as htmlToImage from 'html-to-image';
import { generateHourArray, latToDMS, longToDMS } from '../../helpers/dateUtil';
import HChartFuelRunningHour from '../../components/charts/HChartFuelRunningHour';
import HChartMeFuelCons from '../../components/charts/HChartMeFuelCons';
import HChartAEFuelCons from '../../components/charts/HChartAEFuelCons';
import HChartBunker from '../../components/charts/HChartBunker';
import useCompany from '../../hooks/useCompany';
import { intervalOptions } from '../../helpers/map';
import HChartRpmFuelSpeed from '../../components/charts/HChartRpmFuelSpeed';
import DataCompletionInfo from '../../components/DataCompletionInfo';

interface ILocation {
  chartId: string;
}

const Report: React.FC = () => {
  const { chartId } = useParams<keyof ILocation>() as ILocation;
  const [searchParams, setSearchParams] = useSearchParams();
  const { pathname } = useLocation();
  const { dataFilter } = useFilter();
  const refVesselTracking: any = useRef();
  const refChart: any = useRef();
  const [loadingDownload, setLoadingDownload] = useState(false);
  const {
    assets2,
    fetchAssets,
    fetchAssetsWithLastData,
    isLoadingAssetSummary,
    dataCompletion,
  } = useAsset();
  const { activeCompany, fetchCompanyData } = useCompany();

  const currAsset = useMemo(() => {
    return assets2.find((item) => item.massId === dataFilter.massId);
  }, [dataFilter, assets2]);
  const location = useLocation();
  const currMassId = currAsset ? currAsset.massId : '';
  const isSingleEngine = currAsset?.isSingleEngine || false;
  const isEMS = currAsset?.isEMS || false;

  const handleOnDownload = async () => {
    setLoadingDownload(true);
    try {
      var doc = new jsPDF({
        orientation: 'landscape',
        unit: 'px',
        format: 'a4',
      });

      const pageWidth = doc.internal.pageSize.width;
      const pageHeight = doc.internal.pageSize.height;
      const startY = 20;
      const margin = 20;
      const logo = logoRamusBase64;
      let reportType: 'summary' | 'gps' | 'chart' = 'summary';
      let title = 'Report';
      let currRef: any = null;
      let imageHeight: any = 300;
      let reportName = '';

      if (pathname.includes('report-summary')) {
        reportName = 'report-summary';
        title = 'Summary Data';
        reportType = 'summary';
      } else if (pathname.includes('report-vessel-tracking')) {
        reportName = 'report-vessel-tracking';
        title = 'Vessel Tracking';
        reportType = 'gps';
        currRef = refVesselTracking;
        imageHeight = 200;
      } else if (pathname.includes('report-chart')) {
        reportType = 'chart';
        currRef = refChart;
        imageHeight = 250;
        if (chartId === 'rpm-vs-fuel-cons-vs-speed') {
          reportName = 'report-rpm-vs-fuel-cons-vs-speed';
          title =
            currMassId === '42'
              ? 'RPM vs Fuel Consumption vs Speed vs ME Running Time'
              : isEMS
              ? 'RPM vs Speed vs AE Running Time'
              : 'RPM vs Fuel Consumption vs Speed';
        } else if (chartId === 'bunker-in') {
          reportName = 'report-bunker-in';
          title = 'Bunker In';
        } else if ('me-fuel-used-vs-running-hour') {
          reportName = 'report-me-fuel-used-vs-running-hour';
          title = 'ME Fuel Used vs Running Hour';
        } else if ('port-fuel-used-vs-running-hour') {
          reportName = 'report-port-fuel-used-vs-running-hour';
          title = 'Port Fuel Used vs Running Hour';
        } else if ('starboard-fuel-used-vs-running-hour') {
          reportName = 'report-starboard-fuel-used-vs-running-hour';
          title = 'Starboard Fuel Used vs Running Hour';
        } else if ('me-fuel-consumption') {
          reportName = 'report-me-fuel-consumption';
          title = 'ME Fuel Consumption';
        } else if ('ae-fuel-consumption') {
          reportName = 'report-ae-fuel-consumption';
          title = 'AE Fuel Consumption';
        }
      }

      /* Heading Start */
      doc.setFontSize(12);
      doc.text(`Vessel: ${currAsset?.massName}`, margin, startY + 5);
      doc.setFontSize(10);
      doc.text(
        `Analyzed data: ${moment(
          Number(dataFilter.range.startAt) * 1000
        ).format('DD MMM YYYY HH:mm')} to ${moment(
          Number(dataFilter.range.endAt) * 1000
        ).format('DD MMM YYYY HH:mm')} GMT +007`,
        margin,
        startY + 17
      );
      doc.addImage(logo, 'PNG', pageWidth - (margin + 70), startY, 60, 20);
      doc.setFillColor(1, 43, 101);
      doc.rect(margin, 50, pageWidth - margin * 2, 2, 'F');
      doc.setFontSize(14);
      doc.text(title, pageWidth / 2 - title.length / 2, 70, {
        align: 'center',
      });
      /* Heading End */

      /* Content Start */
      let currImage: any = '';
      if (reportType === 'gps') {
        await htmlToImage
          .toPng(refVesselTracking.current)
          .then(function (dataUrl) {
            currImage = dataUrl;
          })
          .catch(function (error) {
            console.error('oops, something went wrong!', error);
          });
        doc.addImage(
          currImage,
          'JPEG',
          margin,
          80,
          pageWidth - margin * 2,
          imageHeight
        );
        const data = currAsset;
        autoTable(doc, {
          margin: 20,
          startY: 80 + 200 + 20,
          columns: [
            {
              title: '#',
              dataKey: 'id',
            },
            {
              title: 'Position',
              dataKey: 'position',
            },
            {
              title: 'Time',
              dataKey: 'time',
            },
            {
              title: 'Speed',
              dataKey: 'speed',
            },
          ],
          body: [
            {
              id: 'Start',
              position:
                data && data.firstDataGps
                  ? `${latToDMS(data.firstDataGps.latitude)}, ${longToDMS(
                      data.firstDataGps.longitude
                    )}`
                  : '-',
              time:
                data && data.firstDataGps ? data.firstDataGps.datetime : '-',
              speed:
                data &&
                data.dataSummary &&
                data.dataSummary.gps &&
                data.dataSummary.gps.summary
                  ? data.dataSummary.gps.summary.averageSpeed.toFixed(2) +
                    ' Knot'
                  : '-',
            },
            {
              id: 'End',
              position:
                data && data.lastDataGps
                  ? `${latToDMS(data.lastDataGps.latitude)}, ${longToDMS(
                      data.lastDataGps.longitude
                    )}`
                  : '-',
              time: data && data.lastDataGps ? data.lastDataGps.datetime : '-',
              speed:
                data &&
                data.dataSummary &&
                data.dataSummary.gps &&
                data.dataSummary.gps.summary
                  ? data.dataSummary.gps.summary.averageSpeed.toFixed(2) +
                    ' Knot'
                  : '-',
            },
          ],
        });
      } else if (reportType === 'chart') {
        await htmlToImage
          .toPng(currRef.current)
          .then(function (dataUrl) {
            currImage = dataUrl;
          })
          .catch(function (error) {
            console.error('oops, something went wrong!', error);
          });
        doc.addImage(
          currImage,
          'JPEG',
          margin,
          80,
          pageWidth - margin * 2,
          imageHeight
        );
      } else {
        autoTable(doc, {
          html: '#summaryTable',
          margin: 20,
          startY: 80,
        });
      }
      /* Content End */

      /* Footer Start */
      doc.setFillColor(1, 43, 101);
      doc.rect(margin, pageHeight - 40, pageWidth - margin * 2, 2, 'F');
      doc.setFontSize(11);
      doc.text(
        `Report generated at: ${moment().format('DD MMM YYYY HH:mm:ss')}`,
        margin,
        pageHeight - 20
      );
      /* Footer End */

      doc.save(
        `${currAsset?.massName
          .toLowerCase()
          .replace(/ |-/g, '-')}__${reportName}__${moment(
          Number(dataFilter.range.startAt) * 1000
        ).format('YYYY-MM-DD_HH_mm')}__${moment(
          Number(dataFilter.range.endAt) * 1000
        ).format('YYYY-MM-DD_HH_mm')}.pdf`
      );
    } catch (error) {
      console.error('failed download pdf', error);
    } finally {
      setLoadingDownload(false);
    }
  };

  const handleSetURLParams = (data: any) => {
    setSearchParams(data);
  };

  const dataFetchedCompRef = useRef(false);
  const dataFetchedRef = useRef(false);

  useEffect(() => {
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;

    fetchAssets();
    if (activeCompany) {
      fetchCompanyData(activeCompany.compId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeCompany]);

  useEffect(() => {
    // if (dataFetchedCompRef.current) return;
    // dataFetchedCompRef.current = true;
    const searchParams = new URLSearchParams(document.location.search);
    const queryMassId = searchParams.get('massId');
    const queryStart = searchParams.get('start');
    const queryEnd = searchParams.get('end');
    const queryAggregatedUnit = searchParams.get('aggregatedUnit');

    fetchAssetsWithLastData({
      aggregatedUnit: queryAggregatedUnit
        ? queryAggregatedUnit
        : intervalOptions[0].value,
      start: queryStart ? queryStart : undefined,
      end: queryEnd ? queryEnd : undefined,
      devcMassId: queryMassId
        ? queryMassId
        : currAsset
        ? currAsset.massId
        : 'initial',
      aggregatedLength: location.pathname.includes('/report-summary')
        ? 1
        : undefined,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const currentPath = useMemo(() => {
    if (
      !pathname.includes('report-vessel-tracking') ||
      !currAsset ||
      !currAsset.dataSummary ||
      !currAsset.dataSummary.gps ||
      currAsset.dataSummary.gps.data.length === 0
    ) {
      return [];
    }

    return currAsset.dataSummary.gps.data.map((item) => [
      item.latitude,
      item.longitude,
    ]);
  }, [pathname, currAsset]);

  const navigate = useNavigate();

  const columns = useMemo(() => {
    return generateHourArray(dataFilter.range.startAt, dataFilter.range.endAt);
  }, [dataFilter.range]);

  useEffect(() => {
    if (
      currAsset &&
      !currAsset.isSingleEngine &&
      chartId === 'me-fuel-used-vs-running-hour'
    ) {
      navigate('/report-chart/port-fuel-used-vs-running-hour');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chartId, currAsset]);

  return (
    <React.Fragment>
      {dataCompletion && (
        <DataCompletionInfo
          loading={false}
          {...dataCompletion}
          pathname={location.pathname}
        />
      )}
      <ReportActionContent>
        <FilterDashboard
          setURLParams={handleSetURLParams}
          searchParams={searchParams}
        />
        <Button
          loading={isLoadingAssetSummary || loadingDownload}
          disabled={isLoadingAssetSummary || loadingDownload}
          onClick={handleOnDownload}
          style={{ marginBottom: 8, background: '#54C95A' }}
        >
          <span style={{ color: 'white' }}>Download PDF</span>
        </Button>
      </ReportActionContent>

      {isLoadingAssetSummary ? (
        <ContainerLoading>
          <LoadingWrapper>
            <Spin />
            <div>Loading data...</div>
          </LoadingWrapper>
        </ContainerLoading>
      ) : (
        <Content
          customHeight={
            pathname.includes('report-chart')
              ? 'calc(100vh - 144px)'
              : undefined
          }
        >
          {/* REPORT SUMMARY */}
          {pathname.includes('report-summary') &&
            currAsset &&
            currAsset.dataSummary && (
              <div>
                <ReportSummary
                  isSingleEngine={isSingleEngine}
                  massId={currMassId}
                  isEMS={isEMS}
                  data={currAsset?.dataSummary}
                />
              </div>
            )}

          {/* REPORT VESSEL TRACKING */}
          {pathname.includes('report-vessel-tracking') &&
            currAsset &&
            currAsset.lastDataGps &&
            currAsset.firstDataGps && (
              <ReportBodyVessel
                ref={refVesselTracking}
                massId={currMassId}
                lastDataGPS={currAsset.lastDataGps}
                firstDataGPS={currAsset.firstDataGps}
                currentPath={currentPath}
              />
            )}

          {/* REPORT CHART */}
          {pathname.includes('report-chart') && currAsset && (
            <CardWrapper>
              {chartId === 'rpm-vs-fuel-cons-vs-speed-vs-ae' ||
              chartId === 'rpm-vs-speed-vs-ae' ? (
                <div style={{ height: '75vh' }}>
                  <HChartRpmFuelSpeed
                    ref={refChart}
                    compId={currAsset.massCompId}
                    massId={currAsset.massId}
                    isEMS={currAsset.isEMS}
                    isSingleEngine={currAsset.isSingleEngine}
                    data={currAsset.dataSummary || undefined}
                    columns={columns}
                    isLoading={loadingDownload}
                  />
                </div>
              ) : chartId === 'me-fuel-used-vs-running-hour' ? (
                <HChartFuelRunningHour
                  type='MAINENGINE'
                  ref={refChart}
                  columns={columns}
                  isSingleEngine={currAsset.isSingleEngine}
                  data={currAsset.dataSummary || undefined}
                />
              ) : chartId === 'port-fuel-used-vs-running-hour' ? (
                <HChartFuelRunningHour
                  type='PORT'
                  ref={refChart}
                  columns={columns}
                  isSingleEngine={currAsset.isSingleEngine}
                  data={currAsset.dataSummary || undefined}
                />
              ) : chartId === 'stb-fuel-used-vs-running-hour' ? (
                <HChartFuelRunningHour
                  type='STARBOARD'
                  ref={refChart}
                  columns={columns}
                  isSingleEngine={currAsset.isSingleEngine}
                  data={currAsset.dataSummary || undefined}
                />
              ) : chartId === 'me-fuel-consumption' ? (
                <HChartMeFuelCons
                  ref={refChart}
                  isSingleEngine={currAsset.isSingleEngine}
                  data={currAsset.dataSummary || undefined}
                  columns={columns}
                />
              ) : chartId === 'ae-fuel-consumption' ? (
                <HChartAEFuelCons
                  ref={refChart}
                  data={currAsset.dataSummary || undefined}
                  columns={columns}
                />
              ) : chartId === 'bunker-in' ? (
                <HChartBunker
                  ref={refChart}
                  data={currAsset.dataSummary || undefined}
                  columns={columns}
                />
              ) : (
                <>No Chart Available</>
              )}
            </CardWrapper>
          )}
        </Content>
      )}
    </React.Fragment>
  );
};
export default Report;

interface IContent {
  customHeight?: string;
}

const CardWrapper = styled.div`
  position: relative;
  border-radius: 8px;
  overflow: hidden;
  height: 100%;
  background-color: white;
`;

const Content = styled.div<IContent>`
  padding: 20px 20px;
  height: ${(props) => props.customHeight || '100%'};
  background-color: ${(props) => props.theme.colors.grayLighten_Default};
`;

export const ContainerLoading = styled.div`
  background: ${(props) => props.theme.colors.grayLighten_Default};
  height: calc(100vh - 144px);
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const LoadingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  div {
    margin-top: 10px;
    opacity: 0.5;
    text-align: center;
  }
`;
