import styled from 'styled-components';
import { TitleCard } from '../../screens/dashboard';
import HighchartsReact from 'highcharts-react-official';
import highchartsMore from 'highcharts/highcharts-more.js';
import Highcharts from 'highcharts';
import { useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import {
  generateArrays,
  generateDataTable,
  hChartConfig,
  hColors,
} from '../../helpers/chart';
import React from 'react';
import useFilter from '../../hooks/useFilter';
import { Button } from 'antd';
import { useNavigate } from 'react-router-dom';
import {
  IAEData,
  IFlowmeterData,
  IGPSData,
  IRPMData,
  ISummary,
} from '../../types/summary.type';
import {
  fillZerosWithAverage,
  mergeArraysAndSkipDuplicates,
  roundTimestampsToNearestMinute,
  roundValue,
  toHHMM,
  toKnot,
} from '../../helpers/dateUtil';
import { EmptyChart } from './HChartAEFuelCons';

highchartsMore(Highcharts);

interface IProps {
  data?: ISummary;
  massId: string;
  isDailyReport?: boolean;
  isSingleEngine?: boolean;
  isEMS?: boolean;
  compId: string;
  isDashboard?: boolean;
  onClickDownload?: () => void;
  columns?: number[];
  isLoading?: boolean;
}

const yAxisOne = [
  {
    min: 0,
    color: hColors.black,
    text: 'RPM',
  },
  {
    min: 0,
    color: hColors.orange,
    text: 'Liters',
  },
  {
    min: 0,
    color: hColors.pink,
    text: 'Knot',
    opposite: true,
  },
  {
    min: 0,
    color: hColors.black,
    text: 'Hours',
    fixed: true,
    opposite: true,
  },
];

const yAxisTwo = [
  {
    min: 0,
    color: hColors.black,
    text: 'RPM',
  },
  {
    min: 0,
    color: hColors.pink,
    text: 'Knot',
    opposite: true,
  },
  {
    min: 0,
    color: hColors.black,
    text: 'Hours',
    fixed: true,
    opposite: true,
  },
];

const HChartRpmFuelSpeedv2 = React.forwardRef<any, IProps>(
  (
    {
      data,
      massId,
      isDailyReport,
      isSingleEngine,
      isEMS,
      compId,
      isDashboard,
      onClickDownload,
      columns,
      isLoading,
    },
    ref
  ) => {
    const [dataChart, setDataChart] = useState<any>();
    const { dataFilter } = useFilter();
    const navigate = useNavigate();

    const generateYAxis = (
      data: {
        color: string;
        text: string;
        opposite?: boolean;
        fixed?: boolean;
      }[]
    ) => {
      const yAxis: Highcharts.YAxisOptions | Array<Highcharts.YAxisOptions> =
        [];
      for (let idx = 0; idx < data.length; idx++) {
        const item = data[idx];
        yAxis.push({
          labels: {
            format: '{value}',
            style: {
              color: item.color,
            },
            formatter(
              this: Highcharts.AxisLabelsFormatterContextObject,
              ctx: Highcharts.AxisLabelsFormatterContextObject
            ) {
              if (item.fixed) {
                return Number(this.value).toFixed(2);
              }
              return this.value.toString();
            },
          },
          title: {
            text:
              massId === '42' && item.text === 'Nautical Miles'
                ? 'Hour'
                : item.text,
            style: {
              color:
                massId === '42' && item.text === 'Nautical Miles'
                  ? hColors.purple
                  : item.color,
            },
          },
          lineColor:
            item.color === '#000000'
              ? hColors.lightBlack
              : massId === '42' && item.text === 'Nautical Miles'
              ? hColors.purple
              : item.color,
          lineWidth: 1,
          tickColor:
            item.color === '#000000'
              ? hColors.lightBlack
              : massId === '42' && item.text === 'Nautical Miles'
              ? hColors.purple
              : item.color,
          tickWidth: 1,
          tickLength: 5,
          opposite: item.opposite,
        });
      }

      return yAxis;
    };

    useEffect(() => {
      if (data) {
        const createDataSeries = () => {
          let currChartOption: any = {
            ...hChartConfig,
            plotOptions: {
              series: {
                groupPadding: 0.1,
                pointPadding: 0,
                animation: false,
              },
            },
            yAxis: isEMS ? generateYAxis(yAxisTwo) : generateYAxis(yAxisOne),
            series: [],
          };

          let newCategories: any[] = mergeArraysAndSkipDuplicates(
            data.gps?.timestamps || [],
            data.rpm?.timestamps || [],
            data.ae?.timestamps || [],
            data.flowmeter?.timestamps || []
          ).sort((a, b) => a - b);

          const dataSeries: any = [];

          Object.keys(data).forEach((key) => {
            if (key === 'rpm') {
              if (!isSingleEngine) {
                const portDataSeries: (number | undefined)[] = [];

                newCategories.forEach((item: any) => {
                  const isTimestampSame = data[key]?.data.find(
                    (data: IRPMData) =>
                      data['PORT'] &&
                      item ===
                        roundTimestampsToNearestMinute(
                          data['PORT'].timestamp * 1000
                        ) /
                          1000
                  );

                  if (isTimestampSame) {
                    portDataSeries.push(isTimestampSame['PORT'].rpm);
                  } else {
                    portDataSeries.push(undefined);
                  }
                });

                dataSeries.push({
                  name: 'Port RPM',
                  type: 'column',
                  yAxis: 0,
                  data: portDataSeries,
                  tooltip: {
                    valueSuffix: ' RPM',
                  },
                  color: hColors.purpleOpacity,
                  borderColor: hColors.purple,
                  zIndex: 3,
                });

                const stbDataSeries: (number | undefined)[] = [];

                newCategories.forEach((item: any) => {
                  const isTimestampSame = data[key]?.data.find(
                    (data: IRPMData) =>
                      data['STARBOARD'] &&
                      item ===
                        roundTimestampsToNearestMinute(
                          data['STARBOARD'].timestamp * 1000
                        ) /
                          1000
                  );

                  if (isTimestampSame) {
                    stbDataSeries.push(isTimestampSame['STARBOARD'].rpm);
                  } else {
                    stbDataSeries.push(undefined);
                  }
                });

                dataSeries.push({
                  name: 'Starboard RPM',
                  type: 'column',
                  yAxis: 0,
                  data: stbDataSeries,
                  tooltip: {
                    valueSuffix: ' RPM',
                  },
                  color: hColors.tealOpacity,
                  borderColor: hColors.teal,
                  zIndex: 3,
                });
              } else {
                const meDataSeries: (number | undefined)[] = [];

                newCategories.forEach((item: any) => {
                  const isTimestampSame = data[key]?.data.find(
                    (data: IRPMData) => {
                      if (
                        data['MAINENGINE'] &&
                        item ===
                          roundTimestampsToNearestMinute(
                            data['MAINENGINE'].timestamp * 1000
                          ) /
                            1000
                      ) {
                        return data;
                      } else if (
                        data['PORT'] &&
                        item ===
                          roundTimestampsToNearestMinute(
                            data['PORT'].timestamp * 1000
                          ) /
                            1000
                      ) {
                        return data;
                      } else if (
                        data['STARBOARD'] &&
                        item ===
                          roundTimestampsToNearestMinute(
                            data['STARBOARD'].timestamp * 1000
                          ) /
                            1000
                      ) {
                        return data;
                      }
                    }
                  );

                  if (isTimestampSame) {
                    meDataSeries.push(
                      isTimestampSame['MAINENGINE']
                        ? isTimestampSame['MAINENGINE'].rpm
                        : isTimestampSame['PORT']
                        ? isTimestampSame['PORT'].rpm
                        : isTimestampSame['STARBOARD']
                        ? isTimestampSame['STARBOARD'].rpm
                        : 0
                    );
                  } else {
                    meDataSeries.push(undefined);
                  }
                });

                dataSeries.push({
                  name: 'Main Engine RPM',
                  type: 'column',
                  yAxis: 0,
                  data: meDataSeries,
                  tooltip: {
                    valueSuffix: ' RPM',
                  },
                  color: hColors.purpleOpacity,
                  borderColor: hColors.purple,
                  zIndex: 3,
                });
              }
            }

            if (
              key === 'flowmeter' &&
              data['flowmeter']?.data &&
              data['flowmeter']?.data.length > 0
            ) {
              const fmDataSeries: (number | undefined)[] = [];

              if (data[key]) {
                newCategories.forEach((item: any) => {
                  const isTimestampSame = data[key]?.data.find(
                    (data: IFlowmeterData) =>
                      data &&
                      data.PORT_IN &&
                      item ===
                        roundTimestampsToNearestMinute(
                          data.PORT_IN.timestamp * 1000
                        ) /
                          1000
                  );

                  if (isTimestampSame) {
                    fmDataSeries.push(isTimestampSame.meFuelCons);
                  } else {
                    fmDataSeries.push(undefined);
                  }
                });
              }

              dataSeries.push({
                name: 'Fuel Cons.',
                type: 'areaspline',
                yAxis: 1,
                data: fillZerosWithAverage(fmDataSeries),
                marker: {
                  enabled: false,
                  // symbol: 'circle',
                  radius: 4,
                  lineColor: hColors.yellow,
                  lineWidth: 3,
                  fillColor: hColors.yellow,
                },
                lineWidth: 3,
                fill: hColors.yellow,
                tooltip: {
                  valueSuffix: ' liter',
                },
                color: hColors.yellow,
                zIndex: 1,
              });
            }

            if (key === 'gps') {
              const gpsDataSeries: (number | undefined)[] = [];

              if (data[key]) {
                newCategories.forEach((item: any) => {
                  const isTimestampSame = data[key]?.data.find(
                    (data: IGPSData) =>
                      data &&
                      item ===
                        roundTimestampsToNearestMinute(data.timestamp * 1000) /
                          1000
                  );

                  if (isTimestampSame) {
                    gpsDataSeries.push(
                      isTimestampSame.speed
                        ? parseFloat(toKnot(isTimestampSame.speed))
                        : 0
                    );
                  } else {
                    gpsDataSeries.push(undefined);
                  }
                });
              }

              dataSeries.push({
                name: 'Speed',
                type: 'spline',
                yAxis: !isEMS ? 2 : 1,
                data: fillZerosWithAverage(gpsDataSeries),
                marker: {
                  enabled: false,
                  // symbol: 'circle',
                  radius: 4,
                  lineColor: hColors.pink,
                  lineWidth: 3,
                  fillColor: hColors.pink,
                },
                tooltip: {
                  valueSuffix: ' knot',
                },
                lineWidth: 3,
                color: hColors.pink,
                zIndex: 4,
              });
            }

            if (key === 'ae') {
              const aeDataSeries: (number | undefined)[] = [];

              if (data[key]) {
                newCategories.forEach((item: any) => {
                  const isTimestampSame = data[key]?.data.find(
                    (data: IAEData) =>
                      data &&
                      data.AE1 &&
                      item ===
                        roundTimestampsToNearestMinute(
                          data.AE1.timestamp * 1000
                        ) /
                          1000
                  );

                  if (isTimestampSame) {
                    let totalAERunningSeconds = 0;
                    if (
                      isTimestampSame.AE1 &&
                      isTimestampSame.AE1.runningTime
                    ) {
                      totalAERunningSeconds += isTimestampSame.AE1.runningTime;
                    }
                    if (
                      isTimestampSame.AE2 &&
                      isTimestampSame.AE2.runningTime
                    ) {
                      totalAERunningSeconds += isTimestampSame.AE2.runningTime;
                    }
                    if (
                      isTimestampSame.AE3 &&
                      isTimestampSame.AE3.runningTime
                    ) {
                      totalAERunningSeconds += isTimestampSame.AE3.runningTime;
                    }
                    aeDataSeries.push(totalAERunningSeconds / 3600);
                  } else {
                    aeDataSeries.push(undefined);
                  }
                });
              }

              dataSeries.push({
                name: isSingleEngine ? 'ME Running Time' : 'AE Running Time',
                type: 'spline',
                yAxis: !isEMS ? 3 : 2,
                data: fillZerosWithAverage(aeDataSeries),
                marker: {
                  enabled: false,
                  // symbol: 'circle',
                  radius: 4,
                  lineColor: isSingleEngine ? hColors.purple : hColors.black,
                  lineWidth: 3,
                  fillColor: isSingleEngine ? hColors.purple : hColors.black,
                },
                tooltip: {
                  valueSuffix: ' hour',
                },
                lineWidth: 3,
                color: isSingleEngine ? hColors.purple : hColors.black,
                zIndex: 3,
              });
            }
          });

          const newDataCategories = newCategories.map((item) =>
            moment(item * 1000).format('DD/MM/YY HH:mm')
          );

          currChartOption = {
            ...currChartOption,
            xAxis: [
              {
                categories: newDataCategories,
                crosshair: true,
              },
            ],
            series: dataSeries.map((serie: any) => {
              return {
                ...serie,
                data: [...serie.data].map((value: number | undefined) => {
                  return typeof value === 'undefined'
                    ? null
                    : (value && typeof value === 'number') || value === 0
                    ? Number(value.toFixed(2))
                    : null;
                }),
              };
            }),
          };
          console.log('dataSeries', dataSeries);
          setDataChart(currChartOption);
        };

        createDataSeries();
      }

      // eslint-disable-next-spline react-hooks/exhaustive-deps
    }, [data, isSingleEngine, isEMS]);

    function onClickReport() {
      navigate(
        `/report-data-log?start=${dataFilter.range.startAt.valueOf()}&end=${dataFilter.range.endAt.valueOf()}&mode=period&value=today&aggregatedUnit=${
          dataFilter.interval
        }&massId=${massId}&compId=${compId}`
      );
    }

    function calculateAERunningTime(ae: IAEData) {
      return toHHMM(
        ae.AE1.runningSeconds + ae.AE2.runningSeconds + ae.AE3.runningSeconds
      );
    }

    const dataTable = useMemo(() => {
      if (data) {
        return generateDataTable(data);
      }
    }, [data]);

    if (!data) {
      return <></>;
    } else {
      return (
        <>
          {!isDailyReport && (
            <FlexHeader justify='space-between'>
              <TitleCard
                title={
                  !isEMS
                    ? 'RPM vs Fuel Consumption vs Speed vs AE Running Time'
                    : 'RPM vs Speed vs AE Running Time'
                }
              />
              {isDashboard && (
                <FlexHeader justify='center'>
                  <Button
                    onClick={() => onClickReport()}
                    style={{ marginRight: 10 }}
                  >
                    Show Data Log
                  </Button>
                  {isSingleEngine && massId === '42' && (
                    <Button
                      loading={isLoading}
                      onClick={() => onClickDownload && onClickDownload()}
                    >
                      Download
                    </Button>
                  )}
                </FlexHeader>
              )}
            </FlexHeader>
          )}
          <div ref={ref} style={{ height: '100%' }}>
            {dataChart && dataChart.series.length === 0 ? (
              <EmptyChart>
                <h3>Empty Data</h3>
                <p>Data series empty</p>
              </EmptyChart>
            ) : (
              <HighchartsReact
                containerProps={{
                  style: {
                    height: isDailyReport
                      ? dataFilter.interval === 'HOUR' && isEMS
                        ? '70%'
                        : dataFilter.interval === 'HOUR' && !isEMS
                        ? '60%'
                        : '100%'
                      : !isDashboard && dataFilter.interval === 'HOUR'
                      ? '70%'
                      : '100%',
                  },
                }}
                highcharts={Highcharts}
                options={{
                  ...dataChart,
                  chart: {
                    zoomType: 'xy',
                  },
                }}
                allowChartUpdate={true}
                immutable={true}
              />
            )}

            {isDailyReport &&
              !isDashboard &&
              dataFilter.interval === 'HOUR' &&
              dataTable &&
              data && (
                <div style={{ padding: 20 }}>
                  <table
                    className='custom-table-daily-report'
                    style={{
                      width: isDailyReport ? '100%' : '90%',
                      margin: '0 auto',
                    }}
                  >
                    <tbody>
                      <tr>
                        <th>Hour</th>
                        {columns &&
                          columns.map((item) => <td key={item}>{item}</td>)}
                      </tr>
                      {!isSingleEngine ? (
                        <>
                          <tr>
                            <th>Port RPM</th>
                            {dataTable.rpm &&
                            dataTable.rpm.data &&
                            dataTable.rpm.data.length > 0
                              ? dataTable.rpm.data.map(
                                  (item: IRPMData, idx: number) => (
                                    <td key={`port-rpm-${idx}`}>
                                      {item['PORT'] && item['PORT'].rpm
                                        ? roundValue(item['PORT'].rpm)
                                        : 0}
                                    </td>
                                  )
                                )
                              : generateArrays(
                                  0,
                                  columns ? columns?.length : 24
                                ).map((item) => <td>{item}</td>)}
                          </tr>
                          <tr>
                            <th>STB RPM</th>
                            {data.rpm && data.rpm.data.length > 0
                              ? data.rpm.data.map(
                                  (item: IRPMData, idx: number) => (
                                    <td key={`stb-rpm-${idx}`}>
                                      {item['STARBOARD'] &&
                                      item['STARBOARD'].rpm
                                        ? roundValue(item['STARBOARD'].rpm)
                                        : 0}
                                    </td>
                                  )
                                )
                              : generateArrays(
                                  0,
                                  columns ? columns?.length : 24
                                ).map((item) => <td>{item}</td>)}
                          </tr>
                        </>
                      ) : (
                        <>
                          <tr>
                            <th>ME RPM</th>
                            {data.rpm && data.rpm.data.length > 0
                              ? data.rpm.data.map(
                                  (item: IRPMData, idx: number) => (
                                    <td key={`me-rpm-${idx}`}>
                                      {item['MAINENGINE'] &&
                                      item['MAINENGINE'].rpm
                                        ? roundValue(item['MAINENGINE'].rpm)
                                        : item['PORT'] && item['PORT'].rpm
                                        ? roundValue(item['PORT'].rpm)
                                        : item['STARBOARD'] &&
                                          item['STARBOARD'].rpm
                                        ? roundValue(item['STARBOARD'].rpm)
                                        : 0}
                                    </td>
                                  )
                                )
                              : generateArrays(
                                  0,
                                  columns ? columns?.length : 24
                                ).map((item) => <td>{item}</td>)}
                          </tr>
                        </>
                      )}
                      {!isEMS && isSingleEngine ? (
                        <tr>
                          <th>ME Fuel Cons.</th>
                          {data.flowmeter && data.flowmeter.data.length > 0
                            ? data.flowmeter.data.map(
                                (item: IFlowmeterData, idx: number) => (
                                  <td key={`me-fuel-${idx}`}>
                                    {item && item.meFuelCons
                                      ? roundValue(item.meFuelCons)
                                      : 0}
                                  </td>
                                )
                              )
                            : generateArrays(
                                0,
                                columns ? columns?.length : 24
                              ).map((item) => <td>{item}</td>)}
                        </tr>
                      ) : !isEMS && !isSingleEngine ? (
                        <>
                          <tr>
                            <th>Port Fuel Cons.</th>
                            {data.flowmeter && data.flowmeter.data.length > 0
                              ? data.flowmeter.data.map(
                                  (item: IFlowmeterData, idx: number) => (
                                    <td key={`me-fuel-${idx}`}>
                                      {item && item.portFuelCons
                                        ? roundValue(item.portFuelCons)
                                        : 0}
                                    </td>
                                  )
                                )
                              : generateArrays(
                                  0,
                                  columns ? columns?.length : 24
                                ).map((item) => <td>{item}</td>)}
                          </tr>
                          <tr>
                            <th>STB Fuel Cons.</th>
                            {data.flowmeter && data.flowmeter.data.length > 0
                              ? data.flowmeter.data.map(
                                  (item: IFlowmeterData, idx: number) => (
                                    <td key={`me-fuel-${idx}`}>
                                      {item && item.stbFuelCons
                                        ? roundValue(item.stbFuelCons)
                                        : 0}
                                    </td>
                                  )
                                )
                              : generateArrays(
                                  0,
                                  columns ? columns?.length : 24
                                ).map((item) => <td>{item}</td>)}
                          </tr>
                        </>
                      ) : (
                        false
                      )}
                      {massId === '42' ? (
                        <tr>
                          <th>ME Running Time</th>
                          {data.rpm && data.rpm.data.length > 0
                            ? data.rpm.data.map(
                                (item: IRPMData, idx: number) => (
                                  <td key={`me-time-${idx}`}>
                                    {item['MAINENGINE'] &&
                                    item['MAINENGINE'].runningTime
                                      ? toHHMM(item['MAINENGINE'].runningTime)
                                      : item['PORT'] && item['PORT'].runningTime
                                      ? toHHMM(item['PORT'].runningTime)
                                      : item['STARBOARD'] &&
                                        item['STARBOARD'].runningTime
                                      ? toHHMM(item['STARBOARD'].runningTime)
                                      : generateArrays(
                                          0,
                                          columns ? columns?.length : 24
                                        )}
                                  </td>
                                )
                              )
                            : generateArrays(
                                0,
                                columns ? columns?.length : 24
                              ).map((item) => <td>{item}</td>)}
                        </tr>
                      ) : (
                        <tr>
                          <th>AE Running Time</th>
                          {data.ae && data.ae.data.length > 0
                            ? data.ae.data.map((item: IAEData, idx: number) => (
                                <td key={`ae-running-${idx}`}>
                                  {item ? calculateAERunningTime(item) : 0}
                                </td>
                              ))
                            : generateArrays(
                                0,
                                columns ? columns?.length : 24
                              ).map((item) => <td>{item}</td>)}
                        </tr>
                      )}
                      <tr>
                        <th>Speed</th>
                        {data.gps && data.gps.data.length > 0
                          ? data.gps.data.map((item: IGPSData, idx: number) => (
                              <td key={`speed-${idx}`}>
                                {roundValue(item.speed || 0)}
                              </td>
                            ))
                          : generateArrays(0, columns ? columns?.length : 24)}
                      </tr>
                    </tbody>
                  </table>
                </div>
              )}
          </div>
        </>
      );
    }
  }
);

interface IFlexHeader {
  justify: string;
}

const FlexHeader = styled.div<IFlexHeader>`
  display: flex;
  align-items: center;
  justify-content: ${(props) => props.justify};
`;

export default HChartRpmFuelSpeedv2;
