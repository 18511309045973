import React, { useEffect, useMemo, useState } from 'react';
import { ColumnsType } from 'antd/lib/table';
import CardInfo from '../../dashboard/components/CardInfo';
import {
  toHHMMSS,
  toKM,
  toKnot,
  toMiles,
  toNM,
} from '../../../helpers/dateUtil';
import { ISummary } from '../../../types/summary.type';
import styled from 'styled-components';

export interface table {
  description: string;
  value: string;
  unit: string;
}

const columns: ColumnsType<table> = [
  {
    title: 'Description',
    dataIndex: 'label1',
    key: 'label1',
  },
  {
    title: 'Value',
    dataIndex: 'value1',
    key: 'value1',
  },
  {
    title: 'Unit',
    dataIndex: 'unit1',
    key: 'unit1',
  },
  {
    title: 'Description',
    dataIndex: 'label2',
    key: 'label2',
  },
  {
    title: 'Value',
    dataIndex: 'value2',
    key: 'value2',
  },
  {
    title: 'Unit',
    dataIndex: 'unit2',
    key: 'unit2',
  },
];

interface IProps {
  data?: ISummary;
  isEMS: boolean;
  isSingleEngine: boolean;
  massId: string;
  isDailyReport?: boolean;
}

const unitDatas = [
  {
    value: 'KM',
    label: 'KM',
  },
  {
    value: 'NM',
    label: 'NM',
  },
  {
    value: 'MILES',
    label: 'MILES',
  },
];

const speedDatas = [
  {
    value: 'KNOT',
    label: 'KNOT',
  },
  {
    value: 'KM/H',
    label: 'KM/H',
  },
];

const ReportSummary = React.forwardRef<any, IProps>((props, ref) => {
  const { data, isDailyReport } = props;
  const [dataTables, setDataTables] = useState<any>();
  const [unit, setUnit] = useState<'KM' | 'NM' | 'MILES'>('KM');
  const [speed, setSpeed] = useState<'KNOT' | 'KM/H'>('KNOT');

  useEffect(() => {
    if (data) {
      let currDataTables;
      const constructDataTables = () => {
        if (data) {
          const rpm = data.rpm && data.rpm.summary;
          const fm =
            data.flowmeter &&
            data.flowmeter.summary &&
            data.flowmeter.data.length > 0
              ? data.flowmeter.summary
              : undefined;
          const ae = data.ae && data.ae.summary;

          /**
           * NOTE:
           * - props.massId === '42' artinya ada perlakuan khusus untuk Meratus Medan 1
           */

          // Engine Speed & Fuel Cons
          // Single Engine
          const meEngineSpeed =
            rpm?.average.MAINENGINE ||
            rpm?.average.PORT ||
            rpm?.average.STARBOARD ||
            'N/A';
          const meRunningHour = rpm
            ? toHHMMSS(
                (rpm.runningTime.PORT || 0) + (rpm.runningTime.STARBOARD || 0)
              )
            : 'N/A';

          // PORT
          const portEngineSpeed = rpm ? rpm.average.PORT.toFixed(2) : 'N/A';
          const portFuelCons =
            fm && fm.portEngineCons ? fm.portEngineCons.toFixed(2) : 'N/A';
          const portRunningHour =
            rpm && !props.isSingleEngine && rpm.runningTime.PORT
              ? toHHMMSS(rpm.runningTime.PORT)
              : 'N/A';

          // STARBOARD
          const stbEngineSpeed = rpm ? rpm.average.STARBOARD.toFixed(2) : 'N/A';
          const stbFuelCons =
            fm && fm.stbEngineCons && !props.isSingleEngine
              ? fm.stbEngineCons.toFixed(2)
              : 'N/A';
          const stbRunningHour =
            rpm && !props.isSingleEngine
              ? toHHMMSS(rpm.runningTime.STARBOARD)
              : 'N/A';

          // AE Running Hour & Fuel Cons
          const ae1RunningHour = ae ? toHHMMSS(ae.runningSeconds.AE1) : 'N/A';
          const ae1FuelCons =
            ae && ae.fuelConsumption.AE1
              ? ae.fuelConsumption.AE1.toFixed(2)
              : 'N/A';
          const ae2RunningHour = ae ? toHHMMSS(ae.runningSeconds.AE2) : 'N/A';
          const ae2FuelCons =
            ae && ae.fuelConsumption.AE2
              ? ae.fuelConsumption.AE2.toFixed(2)
              : 'N/A';
          const ae3RunningHour = ae ? toHHMMSS(ae.runningSeconds.AE3) : 'N/A';
          const ae3FuelCons =
            ae && ae.fuelConsumption.AE3
              ? ae.fuelConsumption.AE3.toFixed(2)
              : 'N/A';

          // Total Fuel Used
          const engineFuelUsed = fm
            ? (fm.portEngineCons || 0) + (fm.stbEngineCons || 0)
            : 0;
          const aeFuelUsed =
            (ae?.fuelConsumption.AE1 || 0) +
            (ae?.fuelConsumption.AE2 || 0) +
            (ae?.fuelConsumption.AE3 || 0);

          const totalFuelUsed = (
            engineFuelUsed + (props.massId === '42' ? 0 : aeFuelUsed)
          ).toFixed(2);

          if (props.isSingleEngine) {
            currDataTables = [
              {
                label1: 'ME Engine Speed',
                value1:
                  typeof meEngineSpeed === 'number'
                    ? meEngineSpeed.toFixed(2)
                    : meEngineSpeed,
                unit1: 'RPM',
                label2: 'ME Total Fuel Used',
                value2: engineFuelUsed.toFixed(2),
                unit2: 'Liter',
              },
              {
                label1: 'ME Engine Running Hour',
                value1: meRunningHour,
                unit1: 'RPM',
                label2: '',
                value2: '',
                unit2: '',
              },
              {
                label1: 'Port AE1 Running Hour',
                value1: ae1RunningHour,
                unit1: 'Hour',
                label2: '',
                value2: '',
                unit2: '',
              },
              {
                label1: 'Ctr AE2 Running Hour',
                value1: ae2RunningHour,
                unit1: 'Hour',
                label2: 'Total AE Fuel Used',
                value2: props.massId !== '42' ? aeFuelUsed.toFixed(2) : 'N/A',
                unit2: 'Liter',
              },
              {
                label1: 'Stb AE3 Running Hour',
                value1: ae3RunningHour,
                unit1: 'Hour',
                label2: 'Total Fuel Used',
                value2: totalFuelUsed,
                unit2: 'Liter',
              },
            ];
          } else {
            currDataTables = [
              {
                label1: 'Port ME Engine Speed',
                value1: portEngineSpeed,
                unit1: 'RPM',
                label2: 'Port ME Total Fuel Used',
                value2: portFuelCons,
                unit2: 'Liter',
              },
              {
                label1: 'Ctr ME Engine Speed',
                value1: 'N/A',
                unit1: 'RPM',
                label2: 'Ctr ME Total Fuel Used',
                value2: 'N/A',
                unit2: 'Liter',
              },
              {
                label1: 'Stb ME Engine Speed',
                value1: stbEngineSpeed,
                unit1: 'RPM',
                label2: 'Stb ME Total Fuel Used',
                value2: stbFuelCons,
                unit2: 'Liter',
              },
              {
                label1: 'Port ME Running Hour',
                value1: portRunningHour,
                unit1: 'Hour',
                label2: 'AE 1 Total Fuel Used',
                value2: ae1FuelCons,
                unit2: 'Liter',
              },
              {
                label1: 'Ctr ME Running Hour',
                value1: 'N/A',
                unit1: 'Hour',
                label2: 'AE 2 Total Fuel Used',
                value2: ae2FuelCons,
                unit2: 'Liter',
              },
              {
                label1: 'Stb ME Running Hour',
                value1: stbRunningHour,
                unit1: 'Hour',
                label2: 'AE 3 Total Fuel Used',
                value2: ae3FuelCons,
                unit2: 'Liter',
              },
              {
                label1: 'AE 1 Running Hour',
                value1: ae1RunningHour,
                unit1: 'Hour',
                label2: 'ME Total Fuel Used',
                value2: engineFuelUsed.toFixed(2),
                unit2: 'Liter',
              },
              {
                label1: 'AE 2 Running Hour',
                value1: ae2RunningHour,
                unit1: 'Hour',
                label2: 'AE Total Fuel Used',
                value2: aeFuelUsed.toFixed(2),
                unit2: 'Liter',
              },
              {
                label1: 'AE 3 Running Hour',
                value1: ae3RunningHour,
                unit1: 'Hour',
                label2: 'Total Fuel Used',
                value2: totalFuelUsed,
                unit2: 'Liter',
              },
            ];
          }
        }
      };

      constructDataTables();
      setDataTables(currDataTables);
    }
  }, [data, props.massId, props.isSingleEngine]);

  const totalAeRunningTime = useMemo(() => {
    let result = 0;
    if (data && data.ae) {
      result = Object.values(data.ae.summary.runningSeconds).reduce(
        (acc, curr) => acc + curr,
        0
      );
    }

    return result;
  }, [data]);

  const totalDistance = useMemo(() => {
    let result = 'N/A';
    if (data && data.gps) {
      switch (unit) {
        case 'KM':
          result = toKM(data.gps.summary.distance);
          break;
        case 'MILES':
          result = toMiles(data.gps.summary.distance);
          break;
        case 'NM':
          result = toNM(data.gps.summary.distance);
          break;

        default:
          break;
      }
    }

    return result;
  }, [data, unit]);

  const averageSpeed = useMemo(() => {
    let result = 'N/A';
    if (data && data.gps && data.gps.summary) {
      if (speed === 'KNOT') {
        result = toKnot(data.gps.summary.averageSpeed);
      } else {
        result = data.gps.summary.averageSpeed.toFixed(2);
      }
    }

    return result;
  }, [data, speed]);

  const totalCruise = useMemo(() => {
    let result = 'N/A';
    if (data && data.gps && data.gps.summary) {
      switch (unit) {
        case 'KM':
          result = toKM(data.gps.summary.totalDistance);
          break;
        case 'MILES':
          result = toMiles(data.gps.summary.totalDistance);
          break;
        case 'NM':
          result = toNM(data.gps.summary.totalDistance);
          break;

        default:
          break;
      }
    }
    return result;
  }, [data, unit]);

  const handleChangeUnit = (value: any) => {
    setUnit(value);
  };

  const handleChangeSpeed = (value: any) => {
    setSpeed(value);
  };

  return (
    <>
      {!isDailyReport && (
        <SummaryInfo style={{ marginBottom: 20 }}>
          <CardInfo
            style={{ width: '100%' }}
            label='Total Cruise'
            value={totalCruise}
            unit={unit}
            onChange={handleChangeUnit}
            dropdown={!isDailyReport}
            options={unitDatas}
          />
          <CardInfo
            style={{ width: '100%' }}
            label='Average Speed'
            value={averageSpeed}
            unit={speed}
            onChange={handleChangeSpeed}
            dropdown={!isDailyReport}
            options={speedDatas}
          />
          <CardInfo
            style={{ width: '100%' }}
            label='Distance'
            value={totalDistance}
            unit={unit}
          />
          <CardInfo
            style={{ width: '100%' }}
            label='Total AE Running Time'
            value={toHHMMSS(totalAeRunningTime)}
            unit='Hour'
          />
        </SummaryInfo>
      )}
      {dataTables && dataTables.length > 0 && (
        <table
          className={
            isDailyReport ? 'custom-table-daily-report-summary' : 'custom-table'
          }
          id='summaryTable'
        >
          <thead>
            <tr>
              {columns.map((item) => (
                <th key={item.key}>{item.title?.toString()}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {dataTables.map((row: any) => (
              <tr key={row.key}>
                {Object.values(row).map((value: any, idx: number) => (
                  <td
                    key={`${idx}-${value}`}
                    style={{ color: value === 'N/A' ? 'gray' : 'black' }}
                  >
                    {value}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      )}
      {/* <Table
        id='summaryTable'
        ref={ref}
        size='small'
        columns={columns}
        dataSource={dataTables}
        pagination={false}
      /> */}
    </>
  );
});

const SummaryInfo = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
`;

export default ReportSummary;
