import * as L from 'leaflet';
import useMapConfig from '../../../hooks/useMapConfig';
import React, {
  forwardRef,
  ReactNode,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import 'leaflet-rotatedmarker';
import {
  MapContainer,
  TileLayer,
  Marker,
  Popup,
  AttributionControl,
  Polyline,
  FeatureGroup,
  Tooltip,
  CircleMarker,
  useMap,
} from 'react-leaflet';
import { position, tileLayers, zoomConfig } from '../../../helpers/map';
import styled from 'styled-components';
import vesselOnline from '../../../assets/images/vessel-online.svg';
import vesselOffline from '../../../assets/images/vessel-offline.svg';
import MapEventCoordinate from './MapEventCoordinate';
import MapControl from './MapControl';
import { EditControl } from 'react-leaflet-draw';
import MapVesselPopup from './MapVesselPopup';
import MapSettings from './MapSettings';
import { AssetType, initialAssetType } from '../../../types/asset.type';
import moment from 'moment';

interface IProps {
  showSummary: boolean;
  zoomOnScrollWheel?: boolean;
  isFullPage?: boolean;
  height?: number;
  isHome?: boolean;
  showSummaryTable?: boolean;
  customLastCoords?: [number, number];
  customFirstCoords?: [number, number];
  dataMaps: AssetType[];
}

const BaseMap = React.forwardRef<any, IProps>(
  (
    {
      showSummary,
      zoomOnScrollWheel = true,
      isFullPage = true,
      isHome,
      showSummaryTable,
      dataMaps,
    },
    ref
  ) => {
    const {
      showOnlineVessel,
      showOfflineVessel,
      showLabel,
      trailWidth,
      trailOpacity,
      mapType,
      showModalSettings,
      customCenterCoords,
    } = useMapConfig();
    const [selectedAsset, setSelectedAsset] = useState<AssetType>({
      ...initialAssetType,
    });
    const [allCoordinates, setAllCoordinates] = useState<number[][]>([]);
    const mapRef: any = useRef(false);

    const handleClickMarker = (asset: AssetType) => {
      setSelectedAsset(asset);
    };

    const _renderMarker = (isOnline: boolean) => {
      return new L.Icon({
        iconUrl: isOnline ? vesselOnline : vesselOffline,
        iconRetinaUrl: isOnline ? vesselOnline : vesselOffline,
        style: {
          transform: 'rotate(45deg)',
        },
        iconAnchor: [25, 25],
      });
    };

    const _renderTooltip = (isOnline: boolean, name: string) => {
      if (showLabel) {
        return (
          <Tooltip
            key={name}
            className={`custom-tooltip ${
              isOnline ? 'custom-tooltip-online' : 'custom-tooltip-offline'
            }`}
            direction='bottom'
            offset={[0, 20]}
            opacity={1}
            permanent
          >
            {name}
          </Tooltip>
        );
      } else {
        return (
          <Tooltip
            key={name + '1'}
            className={`custom-tooltip ${
              isOnline ? 'custom-tooltip-online' : 'custom-tooltip-offline'
            }`}
            direction='bottom'
            offset={[0, 20]}
            opacity={1}
          >
            {name}
          </Tooltip>
        );
      }
    };

    const handleClearPolyline = () => {};

    function getAllLastCoordinates() {
      const allAssetsLastCoordinate: number[][] = [];
      for (let i = 0; i < dataMaps.length; i++) {
        const data = dataMaps[i];
        if (data.lastDataGps) {
          allAssetsLastCoordinate.push([
            data.lastDataGps.latitude,
            data.lastDataGps.longitude,
          ]);
        }
      }
      setAllCoordinates(allAssetsLastCoordinate);
    }

    useEffect(() => {
      if (dataMaps && dataMaps.length > 0) {
        // constructMapData();
        getAllLastCoordinates();
      }
    }, [dataMaps]);

    const dataAssets = useMemo(() => {
      let results: AssetType[] = [];
      if (dataMaps.length > 0) {
        if (!showOnlineVessel) {
          results = [...dataMaps].filter((item) => !item.isOnline);
        } else if (!showOfflineVessel) {
          results = [...dataMaps].filter((item) => item.isOnline);
        } else {
          results = [...dataMaps];
        }
      }
      return results;
    }, [dataMaps, showOnlineVessel, showOfflineVessel]);

    const handleOnCreatePolyline = (e: any) => {
      const layer = e.layer;
      var coords = layer.getLatLngs();
      var length = 0;
      for (var i = 0; i < coords.length - 1; i++) {
        length += coords[i].distanceTo(coords[i + 1]);
      }
      e.layer.bindTooltip(`Total Distance: ${(length / 1000).toFixed(2)} KM`);
      e.layer.openTooltip();
    };

    return (
      <React.Fragment>
        <div
          ref={ref}
          style={{
            width: '100%',
            height: isFullPage ? '100vh' : '100%',
          }}
          className={showSummary ? 'base-map' : 'base-map-collapse'}
        >
          {allCoordinates.length > 0 && (
            <MapContainer
              ref={mapRef}
              id='baseMap'
              boundsOptions={{
                paddingTopLeft: isHome ? [400, 0] : [0, 0],
                maxZoom: 10,
              }}
              bounds={
                allCoordinates.length > 1 || allCoordinates.length > 0
                  ? allCoordinates
                  : [position]
              }
              zoomControl={false}
              zoom={isHome ? zoomConfig.defaultZoom : 8}
              maxZoom={zoomConfig.maxZoom}
              minZoom={zoomConfig.minZoom}
              scrollWheelZoom={zoomOnScrollWheel}
              attributionControl={false}
              style={{
                width: '100%',
                height: isFullPage ? '100vh' : '100%',
              }}
            >
              {/* Map Tile */}
              <TileLayer
                attribution={
                  tileLayers.find((item) => item.type === mapType)
                    ?.attribution || 'Ramus'
                }
                url={
                  tileLayers.find((item) => item.type === mapType)?.url ||
                  'https://mt1.google.com/vt/lyrs=y&x={x}&y={y}&z={z}'
                }
                maxZoom={zoomConfig.maxZoom}
                minZoom={zoomConfig.minZoom}
              />

              {/* Markers */}
              {dataAssets
                .filter((item) => item.checked)
                .map((item, idx) => (
                  <RotatedMarker
                    key={idx}
                    rotationOrigin='center'
                    rotationAngle={item.lastDataGps?.track || 0}
                    icon={_renderMarker(item.isOnline || false)}
                    position={{
                      lat: item.lastDataGps?.latitude,
                      lng: item.lastDataGps?.longitude,
                    }}
                    onClick={() => handleClickMarker(item)}
                  >
                    <Popup offset={[0, -15]} className='custom-popup'>
                      <MapVesselPopup asset={selectedAsset} />
                    </Popup>
                    {_renderTooltip(item.isOnline || false, `${item.massName}`)}
                  </RotatedMarker>
                ))}

              {/* Vessel Trail */}
              {dataAssets
                .filter((item) => item.showTrail)
                .map(
                  (item, idx) =>
                    item.dataSummary &&
                    item.dataSummary.gps && (
                      <div key={idx}>
                        {/* Polyline */}
                        <Polyline
                          // smoothFactor={2}
                          pathOptions={{
                            color: item.isOnline ? '#55C95A' : '#FE4545',
                            weight: trailWidth,
                            fillOpacity: trailOpacity,
                            opacity: trailOpacity,
                          }}
                          positions={item
                            .dataSummary!.gps.data.filter(
                              (item) => item.latitude && item.longitude
                            )
                            .map((coord: any) => ({
                              lat: coord.latitude,
                              lng: coord.longitude,
                            }))}
                        />
                        {/* First Coordinate */}
                        <CircleMarker
                          center={{
                            lat: item.firstDataGps!.latitude,
                            lng: item.firstDataGps!.longitude,
                          }}
                          radius={zoomConfig.defaultZoom - 3}
                          fill
                          fillOpacity={1}
                          fillColor={'#ffffff'}
                          pathOptions={{
                            color: item.isOnline ? '#55C95A' : '#FE4545',
                          }}
                        />
                      </div>
                    )
                )}

              {/* Map Control */}
              <FeatureGroup>
                <div>
                  <EditControl
                    draw={{
                      polyline: {
                        shapeOptions: {
                          color: 'yellow',
                        },
                      },
                      polygon: false,
                      rectangle: false,
                      circle: false,
                      marker: false,
                      circlemarker: false,
                    }}
                    onCreated={(e) => handleOnCreatePolyline(e)}
                    position='topright'
                  />
                </div>
              </FeatureGroup>
              <MapControl
                onCLickClearLine={handleClearPolyline}
                showMapSettings={!showSummary}
                showSummaryTable={showSummaryTable}
              />

              {/* Map Attribution */}
              <AttributionControl
                position='bottomright'
                prefix={`© ${moment().year()} RAMUS`}
              />

              {/* Cursor Coordinate */}
              <MapEventCoordinate showSummary={showSummary} />

              {/* Other Action */}
              {customCenterCoords && (
                <HandleFlyTo coords={customCenterCoords} />
              )}
            </MapContainer>
          )}
        </div>

        {/* Modal Map Setting */}
        {showModalSettings && (
          <ModalSettings isFullPage={isFullPage}>
            <TitleModal>Map Settings</TitleModal>
            <MapSettings />
          </ModalSettings>
        )}
      </React.Fragment>
    );
  }
);

const HandleFlyTo = ({ coords }: any) => {
  const map = useMap();
  map.flyTo(coords, map.getZoom());

  return null;
};

interface MarkerProps {
  children?: ReactNode;
  rotationAngle: number;
  rotationOrigin: string;
  icon: any;
  position: any;
  onClick: () => void;
}
export type Ref = any;

const RotatedMarker = forwardRef<Ref, MarkerProps>((props, forwardRef: any) => {
  const markerRef: any = useRef();

  const { rotationAngle, rotationOrigin } = props;
  useEffect(() => {
    const marker = markerRef.current;
    if (marker) {
      marker.setRotationAngle(rotationAngle);
      marker.setRotationOrigin(rotationOrigin);
    }
  }, [rotationAngle, rotationOrigin]);

  return (
    <Marker
      eventHandlers={{
        click: () => {
          props.onClick();
        },
      }}
      ref={(ref) => {
        markerRef.current = ref;
        if (forwardRef) {
          forwardRef.current = ref;
        }
      }}
      {...props}
    >
      {props.children}
    </Marker>
  );
});

const ModalSettings = styled.div<{ isFullPage: boolean }>`
  width: 320px;
  border-radius: 8px;
  position: absolute;
  z-index: 990;
  bottom: 50px;
  right: 60px;
  max-height: 820px;
  overflow-y: auto;
  background: white;
  height: ${(props) => (!props.isFullPage ? '340px' : 'auto')};
`;

const TitleModal = styled.div`
  background-color: ${(props) => props.theme.colors.grayLighten_Default};
  padding: 0 25px;
  height: 50px;
  color: ${(props) => props.theme.colors.grayDarken_Dark};
  font-weight: 600;
  display: flex;
  font-size: 16px;
  align-items: center;
`;

export default BaseMap;
