import { AxiosResponse } from "axios";
import { httpRequest } from "../helpers/api";
import { DefaultResponseListProps } from "../types/config.type";
import { DeviceType } from "../types/device.type";

const DeviceSvc = {
  // Device Log
  getDeviceLogs(config: any) {
    return httpRequest.get("/master/device_log", config);
  },
  getLogInformation(param: any) {
    return httpRequest.get(
      `/master/device_log?findField=${param.findField}&findValue=${param.findValue}&page=${param.page}`
    );
  },
  getLogInformationByDate(param: any) {
    return httpRequest.get(
      `/master/device_log?findField=${param.findField}&findValue=${param.findValue}&page=${param.page}`
    );
  },
  addDeviceLog(data: any) {
    return httpRequest.post("/master/device_log", data);
  },
  updateDeviceLog(data: any, id: any) {
    return httpRequest.put(`/master/device_log/${id}`, data);
  },
  deleteLogDevice(id: any) {
    return httpRequest.delete(`/master/device_log/${id}`);
  },

  // Device
  getDevices(
    config: any
  ): Promise<AxiosResponse<DefaultResponseListProps<DeviceType>>> {
    return httpRequest.get("/master/device", config);
  },
  getDevicesByAssetId(param: any) {
    return httpRequest.get(
      `/master/device?findField=${param.findField}&findValue=${param.findValue}`
    );
  },
  addDevice(data: any) {
    return httpRequest.post("/master/device", data);
  },
  updateDevice(data: any, id: any) {
    return httpRequest.put(`/master/device/${id}`, data);
  },
  deleteDevice(id: any) {
    return httpRequest.delete(`/master/device/${id}`);
  },

  // Series
  getSeriesDeviceWeekly(config: any) {
    return httpRequest.get("/series/base/last/1/week", config);
  },
  getSeriesDevicesWeekly(devices: any) {
    const config: any = {
      filter: {
        device: devices,
      },
    };
    return httpRequest.get("/series/base/last/1/week", config);
  },
};

export default DeviceSvc;
