import formatcoords from 'formatcoords';

export type Map = {
  data: string;
};

export const latitudeFormat = (lat: number, lng: number) => {
  const res = formatcoords(lat, lng).format('DD MM ss X', {
    latLonSeparator: ', ',
    decimalPlaces: 0,
  });
  return res.split(', ')[0];
};

export const longitudeFormat = (lat: number, lng: number) => {
  const res = formatcoords(lat, lng).format('DD MM ss X', {
    latLonSeparator: ', ',
    decimalPlaces: 0,
  });
  return res.split(', ')[1];
};

export const degreesToRadians = (degrees: number) => {
  const pi = Math.PI;
  return degrees * (pi / 180);
};
