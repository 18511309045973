import React, { useEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import { intervalOptions, optionsGauge } from '../../helpers/map';
import BaseMap from '../home/components/BaseMap';
import FilterDashboard from './components/FilterDashboard';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import highchartsMore from 'highcharts/highcharts-more.js';
import solidGauge from 'highcharts/modules/solid-gauge.js';
import { Spin } from 'antd';
import CardInfo from './components/CardInfo';
import { useLocation, useSearchParams } from 'react-router-dom';
import { emsAssets, fixedValueString } from '../../helpers/constant';
import HChartRpmFuelSpeedv2 from '../../components/charts/HChartRpmFuelSpeed';
import useFilter from '../../hooks/useFilter';
import useAsset from '../../hooks/useAsset';
import { InfoCircleOutlined } from '@ant-design/icons';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import { logoRamusBase64 } from '../../const/pdf';
import * as htmlToImage from 'html-to-image';
import moment from 'moment';
import useCompany from '../../hooks/useCompany';
import {
  degreesToRadians,
  latitudeFormat,
  longitudeFormat,
} from '../../helpers/map-util';
import { generateHourArray, toHHMMSS } from '../../helpers/dateUtil';
import { IAEData } from '../../types/summary.type';
import DataCompletionInfo from '../../components/DataCompletionInfo';

highchartsMore(Highcharts);
solidGauge(Highcharts);

const Dashboard: React.FC = () => {
  const { isLoadingFilter, dataFilter } = useFilter();
  const {
    assetObject,
    isEMS,
    assets2,
    fetchAssets,
    fetchAssetsWithLastData,
    isLoadingAsset,
    isLoadingAssetSummary,
    dataCompletion,
  } = useAsset();
  const { fetchCompanyData, activeCompany } = useCompany();
  let [searchParams, setSearchParams] = useSearchParams();
  const [loadingDownload, setLoadingDownload] = useState(false);
  const refChart: any = useRef();

  const currAsset = useMemo(() => {
    return assets2.find((item) => item.massId === dataFilter.massId);
  }, [dataFilter, assets2]);

  const isDataExist = currAsset;
  const currMassId = currAsset ? currAsset.massId : '';
  const isSingleEngine = currAsset?.isSingleEngine;

  const handleSetURLParams = (data: any) => {
    setSearchParams(data);
  };

  const formatDataRPM = (
    value: 'STARBOARD' | 'PORT' | 'MAINENGINE',
    singleEngine?: boolean
  ) => {
    let rpm: number = 0;
    if (currAsset) {
      if (singleEngine) {
        if (currAsset.dataSummary?.rpm?.summary.average.MAINENGINE) {
          rpm = currAsset.dataSummary?.rpm?.summary.average.MAINENGINE;
        } else if (currAsset.dataSummary?.rpm?.summary.average.STARBOARD) {
          rpm = currAsset.dataSummary?.rpm?.summary.average.STARBOARD;
        } else if (currAsset.dataSummary?.rpm?.summary.average.PORT) {
          rpm = currAsset.dataSummary?.rpm?.summary.average.PORT;
        }
      } else {
        rpm = currAsset.dataSummary?.rpm?.summary.average[value] || 0;
      }
    }
    return rpm;
  };

  const lastUpdatedRPM = (
    value: 'STARBOARD' | 'PORT' | 'MAINENGINE',
    singleEngine?: boolean
  ) => {
    if (
      isDataExist &&
      currAsset.dataSummary?.rpm &&
      currAsset.dataSummary.rpm.data.length > 0
    ) {
      let newValue = value;
      const lastRpmData =
        currAsset.dataSummary?.rpm.data[
          currAsset.dataSummary?.rpm.data.length - 1
        ];
      if (singleEngine) {
        if (lastRpmData['MAINENGINE'] && lastRpmData['MAINENGINE'].datetime) {
          newValue = 'MAINENGINE';
        } else if (
          lastRpmData['STARBOARD'] &&
          lastRpmData['STARBOARD'].datetime
        ) {
          newValue = 'STARBOARD';
        } else {
          newValue = 'PORT';
        }
      }

      if (lastRpmData[newValue] && lastRpmData[newValue].timestamp) {
        const dateTime = moment(
          new Date(Number(lastRpmData[newValue].timestamp) * 1000)
        ).format('DD MMM yyyy HH:mm');
        return `Last updated RPM: ${dateTime}`;
      } else {
        return `Last updated RPM: Not set (Data RPM ${newValue} empty)`;
      }
    }
    return `Last updated RPM: Not set (Data RPM empty)`;
  };

  async function handleDownloadPDF() {
    setLoadingDownload(true);
    try {
      var doc = new jsPDF({
        orientation: 'landscape',
        unit: 'px',
        format: 'a4',
      });

      const pageWidth = doc.internal.pageSize.width;
      const pageHeight = doc.internal.pageSize.height;
      const startY = 20;
      const margin = 20;
      const logo = logoRamusBase64;
      let reportType = 'chart';
      let title =
        currMassId === '42'
          ? 'RPM vs Fuel Consumption vs Speed vs ME Running Time'
          : isEMS
          ? 'RPM vs Speed vs AE Running Time'
          : 'RPM vs Fuel Consumption vs Speed';
      let imageHeight: any = 300;
      let reportName = isEMS
        ? 'report-rpm-vs-speed'
        : 'report-rpm-vs-fuel-cons-vs-speed';
      const massName =
        assetObject[currMassId].devices[
          Object.keys(assetObject[currMassId].devices)[0]
        ].massName;

      /* Heading Start */
      doc.setFontSize(12);
      doc.text(`Vessel: ${massName}`, margin, startY + 5);
      doc.setFontSize(10);
      doc.text(
        `Analyzed data: ${moment(dataFilter.range.startAt).format(
          'DD MMM YYYY HH:mm'
        )} to ${moment(dataFilter.range.endAt).format(
          'DD MMM YYYY HH:mm'
        )} GMT +007`,
        margin,
        startY + 17
      );
      doc.addImage(logo, 'PNG', pageWidth - (margin + 70), startY, 60, 20);
      doc.setFillColor(1, 43, 101);
      doc.rect(margin, 50, pageWidth - margin * 2, 2, 'F');
      doc.setFontSize(14);
      doc.text(title, pageWidth / 2 - title.length / 2, 70, {
        align: 'center',
      });
      /* Heading End */

      /* Content Start */
      let currImage: any = '';
      if (reportType === 'chart') {
        await htmlToImage
          .toPng(refChart.current)
          .then(function (dataUrl) {
            currImage = dataUrl;
          })
          .catch(function (error) {
            console.error('failed convert html to image', error);
          });
        doc.addImage(
          currImage,
          'JPEG',
          margin,
          80,
          pageWidth - margin * 2,
          imageHeight
        );
      } else {
        autoTable(doc, {
          html: '#summaryTable',
          margin: 20,
          startY: 80,
        });
      }
      /* Content End */

      /* Footer Start */
      doc.setFillColor(1, 43, 101);
      doc.rect(margin, pageHeight - 40, pageWidth - margin * 2, 2, 'F');
      doc.setFontSize(11);
      doc.text(
        `Report generated at: ${moment().format('DD MMM YYYY HH:mm:ss')}`,
        margin,
        pageHeight - 20
      );
      /* Footer End */

      doc.save(
        `${(massName || 'report')
          .toLowerCase()
          .replace(/ |-/g, '-')}__${reportName}__${moment(
          dataFilter.range.startAt
        ).format('YYYY-MM-DD_HH_mm')}__${moment(dataFilter.range.endAt).format(
          'YYYY-MM-DD_HH_mm'
        )}.pdf`
      );
    } catch (error) {
      console.error('failed download pdf', error);
    } finally {
      setLoadingDownload(false);
    }
  }

  const dataFetchedCompRef = useRef(false);
  const dataFetchedRef = useRef(false);

  useEffect(() => {
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;

    fetchAssets();
    if (activeCompany) {
      fetchCompanyData(activeCompany.compId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeCompany]);

  useEffect(() => {
    if (dataFetchedCompRef.current) return;
    dataFetchedCompRef.current = true;

    const searchParams = new URLSearchParams(document.location.search);
    const queryMassId = searchParams.get('massId');
    const queryStart = searchParams.get('start');
    const queryEnd = searchParams.get('end');
    const queryAggregatedUnit = searchParams.get('aggregatedUnit');

    fetchAssetsWithLastData({
      aggregatedUnit: queryAggregatedUnit
        ? queryAggregatedUnit
        : intervalOptions[0].value,
      start: queryStart ? queryStart : undefined,
      end: queryEnd ? queryEnd : undefined,
      devcMassId: queryMassId ? queryMassId : undefined,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const dataMaps = useMemo(
    () =>
      assets2
        .filter((item) => item.dataSummary && item.massId === currAsset?.massId)
        .map((item) => ({
          ...item,
          showTrail: true,
        })),
    [assets2, currAsset]
  );

  const dataGPS = useMemo(() => {
    if (currAsset) {
      return [
        {
          label: 'Last Latitude',
          value: latitudeFormat(
            currAsset.lastDataGps?.latitude || 0,
            currAsset.lastDataGps?.longitude || 0
          ),
          iconType: 'marker',
          smallValue: true,
        },
        {
          label: 'Last Longitude',
          value: longitudeFormat(
            currAsset.lastDataGps?.latitude || 0,
            currAsset.lastDataGps?.longitude || 0
          ),
          iconType: 'marker',
          smallValue: true,
        },
        {
          label: 'Time UTC',
          value: moment().utc().format('DD MMM YYYY, HH:mm UTC'),
          iconType: 'time',
        },
        {
          label: 'Time Local',
          value:
            moment().tz('Asia/Jakarta').format('DD MMM YYYY, HH:mm') + ' WIB',
          iconType: 'time',
        },
        {
          label: 'Avg. Speed',
          value:
            currAsset.dataSummary?.gps?.summary.averageSpeed.toFixed(2) +
              ' Knot' || '-',
          iconType: 'compass',
        },
        {
          label: 'Last Heading',
          value: `${degreesToRadians(currAsset.lastDataGps?.track!).toFixed(
            2
          )} °`,
          iconType: 'compass',
        },
      ];
    }
  }, [currAsset]);

  const location = useLocation();

  return (
    <React.Fragment>
      {dataCompletion && (
        <DataCompletionInfo
          loading={false}
          {...dataCompletion}
          pathname={location.pathname}
        />
      )}
      <FilterDashboard
        setURLParams={handleSetURLParams}
        searchParams={searchParams}
      />
      {isLoadingFilter || isLoadingAssetSummary ? (
        <ContainerLoading>
          <LoadingWrapper>
            <Spin />
            <div>Loading data...</div>
          </LoadingWrapper>
        </ContainerLoading>
      ) : isDataExist ? (
        <>
          <ContentWrapper>
            <MapCard>
              {currAsset.lastDataGps ? (
                <BaseMap
                  showSummary={false}
                  zoomOnScrollWheel={false}
                  isFullPage={false}
                  dataMaps={dataMaps}
                />
              ) : isLoadingAsset ? (
                <h1>Loading data map...</h1>
              ) : (
                <ContainerEmptyData>
                  <InfoCircleOutlined size={140} />
                  <div>Data gps not found</div>
                </ContainerEmptyData>
              )}
            </MapCard>

            <ShipInfoCard>
              {dataGPS && dataGPS.length > 0 ? (
                dataGPS.map((item, key) => (
                  <CardInfo
                    style={
                      key === 2
                        ? { gridColumn: '1/3' }
                        : key === 3
                        ? { gridColumn: '1/3' }
                        : {}
                    }
                    key={key}
                    label={item.label}
                    value={item.value}
                    iconType={item.iconType}
                    unit={undefined}
                    smallValue={item.smallValue}
                  />
                ))
              ) : (
                <ContainerEmptyData isGrid>
                  <InfoCircleOutlined size={140} />
                  <div>Data gps not found</div>
                </ContainerEmptyData>
              )}
            </ShipInfoCard>

            <GaugeCard
              title='Port'
              isSingleEngine={isSingleEngine}
              subtitle={lastUpdatedRPM(
                isSingleEngine ? 'MAINENGINE' : 'PORT',
                isSingleEngine
              )}
              rpm={formatDataRPM('PORT', isSingleEngine)}
              isEMS={
                emsAssets.includes(currAsset.massId) ||
                isEMS ||
                (!currAsset.dataSummary?.flowmeter &&
                  !currAsset.dataSummary?.flowmeter?.summary &&
                  currAsset.dataSummary?.flowmeter?.data.length === 0)
              }
              currentFlow={'-'}
              averageFlow={
                !currAsset.dataSummary?.flowmeter &&
                !currAsset.dataSummary?.flowmeter?.summary &&
                currAsset.dataSummary?.flowmeter?.data.length === 0
                  ? '0'
                  : fixedValueString(
                      currAsset.dataSummary?.flowmeter?.summary.portAverageFlow
                    ) || '0'
              }
              fuelConsumption={
                !currAsset.dataSummary?.flowmeter &&
                !currAsset.dataSummary?.flowmeter?.summary &&
                currAsset.dataSummary?.flowmeter?.data.length === 0
                  ? '0'
                  : fixedValueString(
                      currAsset.dataSummary?.flowmeter?.summary.portEngineCons
                    ) || '0'
              }
            />

            {!isSingleEngine && (
              <GaugeCard
                title='Starboard'
                subtitle={lastUpdatedRPM('STARBOARD')}
                rpm={formatDataRPM('STARBOARD')}
                isEMS={
                  emsAssets.includes(currAsset.massId) ||
                  isEMS ||
                  (!currAsset.dataSummary?.flowmeter &&
                    !currAsset.dataSummary?.flowmeter?.summary &&
                    currAsset.dataSummary?.flowmeter?.data.length === 0)
                }
                currentFlow={'-'}
                averageFlow={
                  !currAsset.dataSummary?.flowmeter &&
                  !currAsset.dataSummary?.flowmeter?.summary &&
                  currAsset.dataSummary?.flowmeter?.data.length === 0
                    ? '0'
                    : fixedValueString(
                        currAsset.dataSummary?.flowmeter?.summary
                          .starboardAverageFlow
                      ) || '0'
                }
                fuelConsumption={
                  !currAsset.dataSummary?.flowmeter &&
                  !currAsset.dataSummary?.flowmeter?.summary &&
                  currAsset.dataSummary?.flowmeter?.data.length === 0
                    ? '0'
                    : fixedValueString(
                        currAsset.dataSummary?.flowmeter?.summary.stbEngineCons
                      ) || '0'
                }
              />
            )}

            {currAsset.dataSummary?.ae &&
              currAsset.dataSummary?.ae?.summary &&
              currAsset.dataSummary?.ae?.data.length > 0 && (
                <AuxiliaryEngineCard>
                  <AECardItem
                    type='AE1'
                    fuelCons={
                      currAsset.dataSummary.ae.summary.fuelConsumption.AE1
                    }
                    runningHour={
                      currAsset.dataSummary.ae.summary.runningSeconds.AE1
                    }
                    lastUpdated={currAsset.dataSummary.ae.data}
                  />
                  <AECardItem
                    type='AE2'
                    fuelCons={
                      currAsset.dataSummary.ae.summary.fuelConsumption.AE2
                    }
                    runningHour={
                      currAsset.dataSummary.ae.summary.runningSeconds.AE2
                    }
                    lastUpdated={currAsset.dataSummary.ae.data}
                  />
                  <AECardItem
                    type='AE3'
                    fuelCons={
                      currAsset.dataSummary.ae.summary.fuelConsumption.AE3
                    }
                    runningHour={
                      currAsset.dataSummary.ae.summary.runningSeconds.AE3
                    }
                    lastUpdated={currAsset.dataSummary.ae.data}
                  />
                </AuxiliaryEngineCard>
              )}

            {!isLoadingFilter && (
              <MultiChartCard style={{ height: '460px' }}>
                <HChartRpmFuelSpeedv2
                  isDashboard
                  ref={refChart}
                  compId={currAsset.massCompId}
                  massId={currAsset.massId}
                  isEMS={currAsset.isEMS}
                  isSingleEngine={currAsset.isSingleEngine}
                  data={currAsset.dataSummary || undefined}
                  columns={generateHourArray(
                    dataFilter.range.startAt,
                    dataFilter.range.endAt
                  )}
                  isLoading={loadingDownload}
                  onClickDownload={handleDownloadPDF}
                />
              </MultiChartCard>
            )}
          </ContentWrapper>
        </>
      ) : (
        <ContainerLoading>
          <LoadingWrapper>
            <InfoCircleOutlined style={{ fontSize: 30 }} />
            <h3>
              <b>Tidak ada data</b>
            </h3>
            <div>Data for GPS, RPM, Flowmeter and AE are empty</div>
          </LoadingWrapper>
        </ContainerLoading>
      )}
    </React.Fragment>
  );
};

const MultiChartCard = styled.div`
  grid-column: 1/4;
  border-radius: 8px;
  background-color: white;
`;

const AuxiliaryEngineCard = styled.div`
  background-color: ${(props) => props.theme.colors.grayLighten_Default};
  border-radius: 8px;
  overflow: hidden;
  display: grid;
  gap: 10px;
  grid-template-rows: repeat(3, 1fr);
  h3 {
    margin: 0;
    font-weight: 600;
    font-size: 16px;
    color: ${(props) => props.theme.colors.grayDarken_Dark};
  }
  > div {
    background-color: white;
    padding: 10px 15px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-radius: 5px;
    span {
      display: block;
      color: ${(props) => props.theme.colors.grayDarken_Default};
      font-size: 12px;
    }
    > div {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
    }
  }
`;

interface IAECardItem {
  type: 'AE1' | 'AE2' | 'AE3';
  fuelCons: any;
  runningHour: any;
  lastUpdated: IAEData[];
}

const AECardItem: React.FC<IAECardItem> = (props) => {
  const lastUpdate = useMemo(() => {
    const lastData =
      props.lastUpdated[props.lastUpdated.length - 1] &&
      props.lastUpdated[props.lastUpdated.length - 1][props.type] &&
      props.lastUpdated[props.lastUpdated.length - 1][props.type].timestamp
        ? props.lastUpdated[props.lastUpdated.length - 1][props.type].timestamp
        : undefined;
    return lastData
      ? moment(lastData * 1000).format('DD MMM YYYY, HH:mm')
      : 'Data Empty';
  }, [props.lastUpdated, props.type]);

  const title = useMemo(() => {
    let result = '-';
    switch (props.type) {
      case 'AE1':
        result = 'AE1';
        break;
      case 'AE2':
        result = 'AE2';
        break;
      case 'AE3':
        result = 'AE3';
        break;

      default:
        break;
    }

    return result;
  }, [props.type]);

  return (
    <div>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          borderBottom: '1px solid lightgray',
          paddingBottom: 5,
        }}
      >
        <h3>{title}</h3>
        <span>Last updated: {lastUpdate}</span>
      </div>
      <div>
        <div>
          <span>Fuel Consumption</span>
          <Flex>
            <GaugeValue>{fixedValueString(props.fuelCons)}</GaugeValue>
            <GaugeUnit>L/h</GaugeUnit>
          </Flex>
        </div>
        <div>
          <span>Running Hour</span>
          <Flex>
            <GaugeValue>{toHHMMSS(props.runningHour)}</GaugeValue>
            {/* <GaugeUnit>hour</GaugeUnit> */}
          </Flex>
        </div>
      </div>
    </div>
  );
};

interface IGaugeCard extends ITitleCard {
  currentFlow: string;
  averageFlow: string;
  fuelConsumption: string;
  rpm: number;
  isEMS: boolean;
  isSingleEngine?: boolean;
}

const GaugeCard: React.FC<IGaugeCard> = (props) => {
  const newOptionGauge = {
    ...optionsGauge,
    series: [
      {
        ...optionsGauge.series[0],
        data: [parseInt(props.rpm.toFixed(0))],
      },
    ],
  };
  return (
    <WrapperGaugeChart isSingleEngine={props.isSingleEngine}>
      <TitleCard
        showTitleBorder
        title={props.title}
        subtitle={props.subtitle}
      />
      <HighchartsReact highcharts={Highcharts} options={newOptionGauge} />
      {!props.isEMS ? (
        <SummaryGaugeChart>
          <FlexColumn>
            <GaugeTitleItem>Current Flow</GaugeTitleItem>
            <Flex>
              <GaugeValue>{props.currentFlow}</GaugeValue>
              <GaugeUnit>L/h</GaugeUnit>
            </Flex>
          </FlexColumn>
          <FlexColumn>
            <GaugeTitleItem>Average Flow</GaugeTitleItem>
            <Flex>
              <GaugeValue>{props.averageFlow}</GaugeValue>
              <GaugeUnit>L/h</GaugeUnit>
            </Flex>
          </FlexColumn>
          <FlexColumn>
            <GaugeTitleItem>Fuel Cons.</GaugeTitleItem>
            <Flex>
              <GaugeValue>{props.fuelConsumption}</GaugeValue>
              <GaugeUnit>L/h</GaugeUnit>
            </Flex>
          </FlexColumn>
        </SummaryGaugeChart>
      ) : (
        <FlowmeterEmpty>
          <span>No flowmeter data</span>
        </FlowmeterEmpty>
      )}
    </WrapperGaugeChart>
  );
};

interface ITitleCard {
  title: string;
  subtitle?: string;
  showTitleBorder?: boolean;
}

export const TitleCard: React.FC<ITitleCard> = (props) => {
  return (
    <FlexBetween
      style={{
        padding: '10px 15px',
        marginBottom: 10,
        width: '100%',
        borderBottom: props.showTitleBorder ? '1px solid lightgray' : 'none',
      }}
    >
      <Title>{props.title}</Title>
      {props.subtitle && <span>{props.subtitle}</span>}
    </FlexBetween>
  );
};

interface IContainerEmptyData {
  isGrid?: boolean;
}

const ContainerEmptyData = styled.div<IContainerEmptyData>`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px solid lightgray;
  border-radius: 5px;
  background-color: white;
  grid-column: ${(props) => (props.isGrid ? '1/3' : 'auto')};
  grid-row: ${(props) => (props.isGrid ? '1/5' : 'auto')};
`;

const FlowmeterEmpty = styled.div`
  background: ${(props) => props.theme.colors.grayLighten_Light};
  height: 80px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  span {
    text-align: center;
    color: ${(props) => props.theme.colors.grayDarken_Dark};
  }
`;

const Title = styled.div`
  font-weight: 600;
  font-size: 20px;
  color: ${(props) => props.theme.colors.grayDarken_Dark};
`;

const SummaryGaugeChart = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: 1fr 1fr 1fr;
  padding: 15px 20px;
  background-color: ${(props) => props.theme.colors.grayLighten_Light};
  width: 100%;
`;

const FlexColumn = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const GaugeTitleItem = styled.div`
  font-weight: 600;
  font-size: 12px;
  color: ${(props) => props.theme.colors.grayDarken_Default};
`;

const GaugeValue = styled.div`
  font-weight: 600;
  font-size: 20px;
`;

const GaugeUnit = styled.div`
  font-size: 16px;
  margin-left: 5px;
  color: ${(props) => props.theme.colors.grayDarken_Default};
`;

const ContentWrapper = styled.div`
  display: grid;
  gap: 20px 20px;
  width: 100%;
  padding: 20px 20px;
  background: ${(props) => props.theme.colors.grayLighten_Default};
  grid-template-columns: repeat(2, 1fr) 400px;
  grid-template-rows: 420px 345px 570px;
`;

const MapCard = styled.div`
  position: relative;
  border-radius: 8px;
  overflow: hidden;
  grid-column: 1/3;
  grid-row: 1;
`;

const ShipInfoCard = styled.div`
  grid-column: 3/4;
  grid-row: 1;
  display: grid;
  gap: 10px;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(4, 1fr);
`;

const FlexBetween = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  span {
    font-size: 12px;
    color: ${(props) => props.theme.colors.grayDarken_Default};
  }
`;

const Flex = styled.div`
  display: flex;
  align-items: center;
  span {
    margin-left: 5px;
    color: ${(props) => props.theme.colors.grayDarken_Default};
  }
`;

interface IWrapperGaugeChart {
  isSingleEngine?: boolean;
}

const WrapperGaugeChart = styled.div<IWrapperGaugeChart>`
  border-radius: 8px;
  overflow: hidden;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  grid-column: ${(props) => (props.isSingleEngine ? '1/3' : 'auto')};
`;

const ContainerLoading = styled.div`
  background: ${(props) => props.theme.colors.grayLighten_Default};
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const LoadingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  div {
    margin-top: 10px;
    opacity: 0.5;
    text-align: center;
  }
`;

export default Dashboard;
