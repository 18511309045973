import moment from 'moment';
import 'moment-timezone';

moment.tz.setDefault('Asia/Jakarta');

function lastWeek() {
  const beforeOneWeek = new Date(
    new Date().getTime() - 60 * 60 * 24 * 7 * 1000
  );
  const day = beforeOneWeek.getDay();
  const diffToMonday = beforeOneWeek.getDate() - day + (day === 0 ? -6 : 1);
  return {
    monday: new Date(beforeOneWeek.setDate(diffToMonday)),
    sunday: new Date(beforeOneWeek.setDate(diffToMonday + 6)),
  };
}

function getIntervals() {
  return [
    {
      label: 'Last 30 Days',
      value: 'last_days_30',
      mode: 'last',
      duration: 1,
      unit: 'month',
      aggregatedUnit: 'DAY',
      range: {
        start: new Date().setDate(new Date().getDate() - 30),
        end: new Date().getTime(),
      },
    },
    {
      label: 'Last Month',
      value: 'last_month',
      mode: 'period',
      aggregatedUnit: 'DAY',
      range: {
        start: new Date(
          new Date(new Date().setMonth(new Date().getMonth() - 1)).setDate(1)
        ).setHours(0, 0, 0, 0),
        end: new Date(new Date().setDate(0)).setHours(23, 59, 59),
      },
    },
    {
      label: 'Last 7 Days',
      value: 'last_days_7',
      mode: 'last',
      duration: 1,
      unit: 'week',
      aggregatedUnit: 'DAY',
      range: {
        start: new Date().setDate(new Date().getDate() - 7),
        end: new Date().getTime(),
      },
    },
    {
      label: 'Last Week',
      value: 'last_week',
      mode: 'period',
      aggregatedUnit: 'DAY',
      range: {
        start: lastWeek().monday.getTime(),
        end: lastWeek().sunday.getTime(),
      },
    },
    {
      label: 'Today',
      value: 'today',
      mode: 'period',
      aggregatedUnit: 'MINUTE',
      range: {
        start: new Date().setHours(0, 0, 0, 0),
        end: new Date().getTime(),
      },
    },
    {
      label: 'Last Day',
      value: 'last_day',
      mode: 'last',
      duration: 1,
      unit: 'day',
      aggregatedUnit: 'MINUTE',
      // range: {
      //   start: new Date().setHours(0, 0, 0, 0),
      //   end: new Date().getTime(),
      // },
    },
    {
      label: 'Custom',
      value: 'custom',
      mode: 'period',
      selectable: false,
    },
  ];
}

function getIntervalObject() {
  const intervalsObject: any = {};
  getIntervals().forEach((interval) => {
    intervalsObject[interval.value] = interval;
  });
  return intervalsObject;
}

export const dataIntervalOptions = [
  {
    label: 'By Minute',
    value: 'MINUTE',
    seconds: 60,
  },
  {
    label: 'Hourly',
    value: 'HOUR',
    seconds: 3600,
  },
  {
    label: 'Daily',
    value: 'DAY',
    seconds: 86400,
  },
  {
    label: 'Weekly',
    value: 'WEEK',
    seconds: 604800,
  },
  {
    label: 'Monthly',
    value: 'MONTH',
    seconds: 2592000,
  },
];

function getSeconds(timeUnit: string) {
  let seconds = 0;
  dataIntervalOptions.forEach((option) => {
    if (option.value === timeUnit) {
      seconds = option.seconds;
    }
  });
  return seconds;
}

export const dateUtil = {
  byInterval(interval: any) {
    return getIntervalObject()[interval];
  },
  intervals: getIntervals(),
  dataIntervalOptions,
  msToS(intervalObject: any) {
    const alteredIntervalObject = JSON.parse(JSON.stringify(intervalObject));
    if (
      intervalObject.range &&
      intervalObject.range.start &&
      intervalObject.range.end
    ) {
      alteredIntervalObject.range.start = intervalObject.range.start / 1000;
      alteredIntervalObject.range.end = intervalObject.range.end / 1000;
    }
    return alteredIntervalObject;
  },
  queryToDateParam(query: any) {
    const selectedParam = getIntervalObject()[query.value];
    if (query.start && query.end) {
      selectedParam.range = {
        start: parseInt(query.start),
        end: parseInt(query.end),
      };
    }
    selectedParam.aggregatedUnit = query.aggregatedUnit;
    if (query.timezone) selectedParam.timezone = query.timezone;
    return selectedParam;
  },
  dateParamToQuery(date: any) {
    const query: any = {};
    if (date.mode) query.mode = date.mode;
    if (date.duration) query.duration = date.duration;
    if (date.range && date.range.start) query.start = date.range.start;
    if (date.range && date.range.end) query.end = date.range.end;
    if (date.unit) query.unit = date.unit;
    if (date.value) query.value = date.value;
    if (date.aggregatedUnit) query.aggregatedUnit = date.aggregatedUnit;
    return query;
  },
  toLocalDate(timeInSec: number) {
    const datetime = timeInSec * 1000;
    const a = new Date(datetime);
    const months = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ];
    const year = a.getFullYear();
    const month = months[a.getMonth()];
    const date = a.getDate().toString().padStart(2, '0');
    const hour = a.getHours().toString().padStart(2, '0');
    const min = a.getMinutes().toString().padStart(2, '0');
    const sec = a.getSeconds().toString().padStart(2, '0');
    const time = `${date} ${month} ${year} ${hour}:${min}:${sec}`;
    return time;
  },
  autoMaxData(dateParam: any) {
    let secondsLength = 86400;
    if (dateParam.range) {
      secondsLength = dateParam.range.end - dateParam.range.start;
    }
    const unitInSeconds = getSeconds(dateParam.aggregatedUnit);
    const expectedDataLength = secondsLength / unitInSeconds;
    const maxDataLimit = 50;
    if (!dateParam.timezone) {
      dateParam.timezone = (new Date().getTimezoneOffset() / 60) * -1;
    }
    console.log(`expectedDataLength: ${expectedDataLength}`);
    console.log(`maxDataLimit: ${maxDataLimit}`);
    if (expectedDataLength > maxDataLimit) {
      dateParam.aggregatedLength = parseInt(
        (expectedDataLength / maxDataLimit).toString()
      );
    }
    return dateParam;
  },
};

export function convertDateToStandard(date: any) {
  // return new Date(date).toISOString();
  // const dateDate = new Date();
  // const gmt = (dateDate.getTimezoneOffset() / 60) * -1;
  // let gmtText = '';
  // if (gmt === 0) {
  //   //
  // } else if (gmt > 0) {
  //   gmtText = `+${gmt}`;
  // } else {
  //   gmtText = `${gmt}`;
  // }
  return `${moment(date).format('DD MMMM yyyy HH:mm:ss')} GMT ${moment(
    date
  ).format('ZZ')}`;
}

export const toHHMMSS = (seconds: number) => {
  if (!seconds) {
    return '00:00:00';
  }
  const SECONDS_PER_DAY = 86400;
  const HOURS_PER_DAY = 24;
  const days = Math.floor(seconds / SECONDS_PER_DAY);
  const remainderSeconds = seconds % SECONDS_PER_DAY;
  const hms = new Date(remainderSeconds * 1000).toISOString().substring(11, 19);
  return hms.replace(/^(\d+)/, (h) =>
    `${Number(h) + days * HOURS_PER_DAY}`.padStart(2, '0')
  );
};

export const toHHMM = (date: string | number) => {
  return moment(date).format('HH:MM');
};

export const toKM = (value: number) => {
  if (!value) {
    return '0.00';
  }
  return (value / 1000).toFixed(2);
};

export const toMiles = (value: number) => {
  if (!value) {
    return '0.00';
  }
  return (value * 0.00062137).toFixed(2);
};

export const toNM = (value: number) => {
  if (!value) {
    return '0.00';
  }
  return (value * 0.00053996).toFixed(2);
};

export const toKnot = (value: number) => {
  if (!value) {
    return '0.00';
  }
  return (value / 1.852).toFixed(2);
};

function timeConverter(datetime: any) {
  const a = new Date(datetime);
  const months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];
  const year = a.getFullYear();
  const month = months[a.getMonth()];
  const date = a.getDate().toString().padStart(2, '0');
  const hour = a.getHours().toString().padStart(2, '0');
  const min = a.getMinutes().toString().padStart(2, '0');
  const sec = a.getSeconds().toString().padStart(2, '0');
  const time = `${date} ${month} ${year} ${hour}:${min}:${sec}`;
  return time;
}

export const toLocalDate = (value: any) => {
  if (!value) return '-';
  return timeConverter(value * 1000);
};

function toDegreesMinutesAndSeconds(coordinate: any) {
  const absolute = Math.abs(coordinate);
  const degrees = Math.floor(absolute);
  const minutesNotTruncated = (absolute - degrees) * 60;
  const minutes = Math.floor(minutesNotTruncated);
  const seconds = Math.floor((minutesNotTruncated - minutes) * 60);

  return `${degrees}° ${minutes}' ${seconds}''`;
}

export const latToDMS = (value: any) => {
  if (!value) return '-';
  const cardinal = value > 0 ? 'N' : 'S';
  return `${toDegreesMinutesAndSeconds(value)} ${cardinal}`;
};

export const longToDMS = (value: any) => {
  if (!value) return '-';
  const cardinal = value > 0 ? 'E' : 'W';
  return `${toDegreesMinutesAndSeconds(value)} ${cardinal}`;
};

export const roundValue = (x: number) => {
  if (!x) {
    return '0.00';
  }

  if (x < 1 && x > 0) {
    return x.toFixed(2);
  }

  // return Math.round(x);
  if (x > 999) {
    return x.toFixed(0);
  }

  return x.toFixed(2);
};

export function generateHourArray(startDate: any, endDate: any) {
  const currStartDate = moment(startDate);
  const currEndDate = moment(endDate);
  const start = currStartDate.hours();
  const end = currEndDate.hours();
  const diff = currEndDate.date() - currStartDate.date();
  const hours = [];

  if (start > 23 || end > 23) {
    throw new Error('Invalid hour input. Hours must be between 0 and 23.');
  }

  if (diff === 0) {
    for (let i = start; i <= end; i++) {
      hours.push(i);
    }
  } else {
    for (let i = start; i <= 23; i++) {
      hours.push(i);
    }

    if (diff > 0 && end > 0) {
      for (let l = 0; l <= end; l++) {
        hours.push(l);
      }
    }
  }

  return hours;
}

export function generateTimestampArray(
  startTime: number,
  endTime: number,
  interval?: number
) {
  const currInterval = interval || 60; // in seconds
  const timestampArray = [];
  let currentTimestamp = startTime;

  while (currentTimestamp < endTime) {
    timestampArray.push(currentTimestamp);
    currentTimestamp += currInterval;
  }

  return timestampArray;
}

export function roundTimestampsToNearestMinute(timestamp: number) {
  const date = new Date(timestamp);
  const roundedMinutes = Math.round(date.getMinutes());
  date.setMinutes(roundedMinutes);
  date.setSeconds(0);
  date.setMilliseconds(0);
  return date.getTime();
}

export function mergeArraysAndSkipDuplicates(...arrays: any) {
  const mergedArray = [];
  const mergedSet = new Set();

  for (const array of arrays) {
    for (const element of array) {
      if (!mergedSet.has(element)) {
        mergedSet.add(element);
        mergedArray.push(element);
      }
    }
  }

  return mergedArray;
}

export function fillZerosWithAverage(arr: any[]) {
  for (let i = 1; i < arr.length - 1; i++) {
    if (arr[i] === 0 && arr[i - 1] > 0 && arr[i + 1] > 0) {
      const average = (arr[i - 1] + arr[i + 1]) / 2;
      arr[i] = average;
    }
  }
  return arr;
}
