import { Button, Collapse, Steps } from 'antd';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import {
  CompanyCard,
  CompanyContainer,
} from '../../components/ModalChangeCompany';
import AuthLayout from '../layout/AuthLayout';
import { useNavigate, useParams } from 'react-router-dom';
import useMapConfig from '../../hooks/useMapConfig';
import AppIcon from '../../components/AppIcon';
import useCompany from '../../hooks/useCompany';
import CompanyImage from '../../components/CompanyImage';
import { AppFeatureProps } from '../../types/config.type';
import { ICompAuthStatus } from '../../types/company.type';
import useAuth from '../../hooks/useAuth';

export const appFeatures: AppFeatureProps[] = [
  {
    id: 'fms',
    name: 'Fuel Monitoring System',
    description: 'Select Group and Company that you want to trace',
    disabled: false,
  },
  {
    id: 'ams',
    name: 'Asset Monitoring',
    description: 'Select Group and Company that you want to trace',
    disabled: true,
  },
  {
    id: 'bms',
    name: 'Bunkering Monitoring',
    description: 'Select Group and Company that you want to trace',
    disabled: false,
  },
  {
    id: 'tlm',
    name: 'Tank Level Monitoring',
    description: 'Select Group and Company that you want to trace',
    disabled: true,
  },
];

const { Panel } = Collapse;
const { Step } = Steps;

interface ILocation {
  step: string;
}

const ChooseApplication: React.FC = () => {
  const navigate = useNavigate();
  const { step } = useParams<keyof ILocation>() as ILocation;
  const { selectedApp, setCurrentApp } = useMapConfig();
  const { loginData, handlePermission } = useAuth();
  const {
    activeCompany,
    availableCompaniesByGroup,
    isLoadingCompanyData,
    setCurrentActiveCompany,
  } = useCompany();

  const [currentStep, setCurrentStep] = useState<number>(0);

  const handleConfirm = () => {
    if (selectedApp && selectedApp?.id === 'fms') {
      navigate('/home');
    } else if (selectedApp && selectedApp?.id === 'bms') {
      navigate('/home-bms');
    }
  };

  const handleSelectApp = (app: AppFeatureProps) => {
    setCurrentApp(app);
  };

  const handleSetCompany = async (company: ICompAuthStatus) => {
    const allowToGetCompany = handlePermission(['master_company'], 'read');
    await setCurrentActiveCompany({
      compId: company.compId,
      setCompany: true,
      getCompany: allowToGetCompany,
    });
  };

  useEffect(() => {
    if (step) {
      setCurrentStep(parseInt(step));
    }
  }, [step]);

  return (
    <AuthLayout variant='secondary'>
      <Steps
        style={{
          padding: '20px 50px',
          margin: 0,
          background: '#e8ecf3',
          borderBottom: 'thin solid #e8ecf3',
        }}
        size='small'
        current={currentStep}
        onChange={(current) => setCurrentStep(current)}
      >
        <Step
          title='Select Company'
          description={'Select Group and Company that you want to trace'}
        />
        <Step
          disabled={!activeCompany}
          title='Select Application'
          description={'Select application that you want to use'}
        />
      </Steps>

      <Content>
        {/* <Steps items={items} current={currentStep} /> */}

        {currentStep === 1 ? (
          <Grid>
            {appFeatures.map((item) => (
              <GridItem
                disabled={item.disabled}
                active={selectedApp ? selectedApp.id === item.id : false}
                key={item.id}
                title={item.disabled ? 'Under Development' : undefined}
                onClick={() => !item.disabled && handleSelectApp(item)}
              >
                <span>
                  <AppIcon
                    disabled={item.disabled}
                    id={item.id}
                    style={{ width: 32 }}
                  />
                </span>
                <TitleItem
                  disabled={item.disabled}
                  active={selectedApp ? selectedApp.id === item.id : false}
                >
                  {item.name}
                </TitleItem>
                <SubTitleItem>{item.description}</SubTitleItem>
              </GridItem>
            ))}
          </Grid>
        ) : currentStep === 0 ? (
          <Collapse
            style={{
              maxHeight: 480,
              overflowY: 'auto',
            }}
            accordion
            defaultActiveKey={[String(0)]}
            bordered={false}
          >
            {availableCompaniesByGroup.map((item, idx) => (
              <Panel
                header={`${item.group.grcpName} (${item.companies.length})`}
                key={String(idx)}
              >
                <CompanyContainer>
                  {item.companies.map((company, idx) => (
                    <CompanyCard
                      active={
                        activeCompany
                          ? activeCompany?.compId === company.compId
                          : false
                      }
                      key={idx}
                      onClick={() => handleSetCompany(company)}
                    >
                      <CompanyImage compImage={company.compImage} />
                      <div>{company.compName}</div>
                    </CompanyCard>
                  ))}
                </CompanyContainer>
              </Panel>
            ))}
          </Collapse>
        ) : (
          false
        )}
      </Content>

      <Action>
        <Content>
          {currentStep === 0 ? (
            <Button
              loading={isLoadingCompanyData}
              disabled={!activeCompany}
              onClick={() => setCurrentStep(1)}
              style={{ width: '100%' }}
              type='primary'
            >
              Next
            </Button>
          ) : currentStep === 1 ? (
            <Flex>
              <Button
                onClick={() => setCurrentStep(0)}
                style={{ width: '100%' }}
                type='default'
              >
                Back
              </Button>
              <Button
                disabled={
                  !activeCompany || !selectedApp || isLoadingCompanyData
                }
                onClick={() => handleConfirm()}
                style={{ width: '100%' }}
                type='primary'
              >
                Confirm Selection
              </Button>
            </Flex>
          ) : (
            false
          )}
        </Content>
      </Action>
    </AuthLayout>
  );
};

export default ChooseApplication;

const Content = styled.div`
  width: 600px;
  height: 100%;
  overflow-y: auto;
`;

const Flex = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 15px;
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 24px;
  padding: 20px 0;
`;

interface IGridItem {
  active: boolean;
  disabled?: boolean;
}

const Action = styled.div`
  border-top: 1px solid #e8ecf3;
  padding: 15px 20px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const GridItem = styled.div<IGridItem>`
  padding: 24px 20px;
  gap: 4px;
  display: flex;
  flex-direction: column;
  border: ${(props) =>
    props.active ? '1px solid #0957C2' : '1px solid #e8ecf3'};
  box-shadow: 0px 3px 2px 0px #0000000d;
  border-radius: 8px;
  background-color: ${(props) => (props.disabled ? '#eef0f4' : 'white')};
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  text-align: center;
  align-items: center;
  span {
    margin-bottom: 10px;
    display: inline-block;
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${(props) => (props.disabled ? '#E3E7EF' : '#e8ecf3')};
    border-radius: 5px;
  }
`;

const TitleItem = styled.div<IGridItem>`
  font-weight: 600;
  font-size: 16px;
  color: ${(props) =>
    props.disabled ? '#92A1B6' : props.active ? '#0957C2' : '#000'};
`;

const SubTitleItem = styled.div`
  font-size: 12px;
  color: #768499;
`;
