import { useContext, useState } from "react";
import ConfigContext from "../context/ConfigContext";

export default function useConfigApp() {
  const { config, setConfig } = useContext(ConfigContext);
  const { counterBatch, sidebarCollapsed } = config;

  const toggleSidebar = (value?: boolean) => {
    if (typeof value === "boolean") {
      setConfig({
        ...config,
        sidebarCollapsed: value,
      });
    } else if (sidebarCollapsed) {
      setConfig({
        ...config,
        sidebarCollapsed: false,
      });
    } else {
      setConfig({
        ...config,
        sidebarCollapsed: true,
      });
    }
  };

  return {
    sidebarCollapsed,
    counterBatch,
    toggleSidebar,
  };
}
