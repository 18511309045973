import { SeriesProps } from './series.type';
import { DeviceMassType, DeviceProps, DeviceType } from './device.type';
import { IGPSData, ISummary } from './summary.type';

export interface IAssetData {
  massCompId: string;
  massCreatedTime: string;
  massCreatedUserId: string;
  massFileId: string;
  massGsmNo: string;
  massId: string;
  massImei: string;
  massInstallationDate: string;
  massName: string;
  massPicContact: string;
  massPicName: string;
  massSateliteId: string;
  massSatDeviceId: string;
  massSateliteOperator: string;
  massSystemType: string;
  massTelcoOperator: string;
  massType: string;
  massUpdatedTime: string;
  massUpdatedUserId: string;
  massDevice: DeviceProps[];
  massImage?: string;
}

export const initialAsset: IAssetData = {
  massCompId: '',
  massCreatedTime: '',
  massCreatedUserId: '',
  massFileId: '',
  massGsmNo: '',
  massId: '',
  massImei: '',
  massInstallationDate: '',
  massName: '',
  massPicContact: '',
  massPicName: '',
  massSateliteId: '',
  massSatDeviceId: '',
  massSateliteOperator: '',
  massSystemType: '',
  massTelcoOperator: '',
  massType: '',
  massUpdatedTime: '',
  massUpdatedUserId: '',
  massDevice: [],
};

export interface AEType {
  interval: number;
  start: number;
  end: number;
  runningTime: {
    AE1: number;
    AE2: number;
    AE3: number;
  };
  runningSeconds: {
    AE1: number;
    AE2: number;
    AE3: number;
  };
  fuelConsumption: {
    AE1: number;
    AE2: number;
    AE3: number;
  };
}

export interface FlowMeterType {
  interval: number;
  start: number;
  end: number;
  PORT_IN: {
    _averageFlowRate: number;
    _dataFreq: number;
  };
  PORT_OUT: {
    _averageFlowRate: number;
    _dataFreq: number;
  };
  STARBOARD_OUT: {
    _averageFlowRate: number;
    _dataFreq: number;
  };
  STARBOARD_IN: {
    _averageFlowRate: number;
    _dataFreq: number;
  };
  portTotalAverageFlow: number;
  portAverageFlow: number;
  portEngineCons: number;
  starboardTotalAverageFlow: number;
  starboardAverageFlow: number;
  stbEngineCons: number;
}

export interface GPSType {
  interval: number;
  start: number;
  end: number;
  totalPolyLength: number;
  totalDistance: number;
  averageSpeed: number;
  distance: number;
  calculatedSpeed: number;
  calculatedSpeedKMh: number;
}

export interface RPMType {
  singleEngine: boolean;
  interval: number;
  start: number;
  end: number;
  runningTime: {
    STARBOARD: number;
  };
  dataCount: {
    STARBOARD: number;
  };
  average: {
    PORT: number;
    STARBOARD: number;
    MAINENGINE: number;
  };
  totalRPM: {
    STARBOARD: 225646.1064453125;
  };
}

export interface CurrentAEType {
  no: number;
  rpm: number;
  id: number;
  deviceId: string;
  timestamp: number;
  datetime: string;
  additional: {
    AVG_dlaeRpm_0: number;
    SUM_dlaeRpm_1: number;
  };
  runningTime: number;
  runningSeconds: number;
  fuelConsumption: number;
}

export interface FlowMeterRawType {
  massFlowrate: number;
  density: number;
  temperature: number;
  volumeFlowrate: number;
  massTotal: number;
  volumeTotal: number;
  massInventory: number;
  volumeInventory: number;
  id: number;
  deviceId: string;
  timestamp: number;
  datetime: string;
  additional: {
    MIN_dlfmVolInventory_0: number;
    MAX_dlfmVolInventory_1: number;
    AVG_dlfmVolFlowrate_2: number;
    AVG_dlfmDensity_3: number;
    AVG_dlfmTemp_4: number;
  };
  volumeFlow: number;
}

export interface CurrentFlowMeterType {
  PORT_IN: FlowMeterRawType;
  PORT_OUT: FlowMeterRawType;
  STARBOARD_IN: FlowMeterRawType;
  STARBOARD_OUT: FlowMeterRawType;
  portFuelCons: number;
  portInFlow: number;
  portInDens: number;
  portOutFlow: number;
  portOutDens: number;
  portFlow: number;
  stbFuelCons: number;
  stbInFlow: number;
  stbInDens: number;
  stbOutFlow: number;
  stbOutDens: number;
  stbFlow: number;
  meFuelCons: number;
}

export interface CurrentGPSType extends SeriesProps {
  polyLength: number;
  additional: {
    AVG_dlgpSpeed_0: number;
    AVG_dlgpTrack_1: number;
    AVG_dlgpAltitude_2: number;
    SUM_dlgpDistance_3: number;
  };
}

export interface CurrentGPSRaw {
  type: string;
  no: number;
  rpm: number;
  id: number;
  timestamp: string;
  additional: {
    AVG_dlrpRpm_0: number;
    COUNT_dlrpRpm_1: number;
  };
  runningTime: number;
  datetime: string;
}
export interface CurrentRPMType {
  [T: string]: CurrentGPSRaw;
}

export interface AssetType extends DeviceMassType {
  devices: { [T: string]: DeviceType };
  datas?: any;
  summaries?: {
    ae: AEType;
    flowmeter: FlowMeterType;
    gps: GPSType;
    rpm: RPMType;
  };
  firstData?: {
    ae?: { [T: string]: CurrentAEType };
    flowmeter?: CurrentFlowMeterType;
    gps?: CurrentGPSType;
    rpm?: CurrentRPMType;
  };
  lastData?: {
    ae: { [T: string]: CurrentAEType };
    flowmeter: CurrentFlowMeterType;
    gps: CurrentGPSType;
    rpm: CurrentRPMType;
  };
  start?: number;
  end?: number;
  dataSummary?: ISummary;
  lastDataGps?: IGPSData;
  firstDataGps?: IGPSData;
  isOnline?: boolean;
  checked?: boolean;
  showTrail: boolean;
  isEMS?: boolean;
  isSingleEngine?: boolean;
}

export const initialAssetType: AssetType = {
  massId: '',
  massCompId: '',
  massName: '',
  massType: '',
  massCreatedUserId: '',
  massCreatedTime: '',
  massUpdatedUserId: '',
  massUpdatedTime: '',
  devices: {},
  showTrail: false,
};
