import React, { Suspense } from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  useNavigate,
} from 'react-router-dom';
import PrivateRoute from './PrivateRoute';
import PublicRoute from './PublicRoute';
import useAuth from '../hooks/useAuth';
import { Spin } from 'antd';
import CompanySelection from '../screens/auth/CompanySelection';
import Report from '../screens/report/index';
import ChooseApplication from '../screens/application';
import PublicRestrictedRoute from './PublicRestricted';
import ReportLog from '../screens/report/ReportLog';
import {
  SecureRoute,
  Security,
  LoginCallback,
  useOktaAuth,
} from '@okta/okta-react';
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import DeviceDetail from '../screens/device/detail';
import ReportDaily from '../screens/report/daily/ReportDaily';
import HomeBMS from '../screens/homeBMS';
import DeviceDetailBMS from '../screens/homeBMS/DetailDeviceBMS';
import CompanyDetail from '../screens/company/detail';
import ReportBunkerLog from '../screens/report/ReportBunkerLog';

const NotFound = React.lazy(() => import('../NotFound'));

const Login = React.lazy(() => import('../screens/auth/Login'));
// const LoginPhone = React.lazy(() => import("../screens/auth/LoginPhone"));
const ForgotPassword = React.lazy(
  () => import('../screens/auth/ForgotPassword')
);
const ResetPassword = React.lazy(() => import('../screens/auth/ResetPassword'));

const Dashboard = React.lazy(() => import('../screens/dashboard'));
const Home = React.lazy(() => import('../screens/home'));
const User = React.lazy(() => import('../screens/user'));
const UserEdit = React.lazy(() => import('../screens/user/Edit'));
const UserDetail = React.lazy(() => import('../screens/user/Detail'));
const Role = React.lazy(() => import('../screens/role'));
const RoleEdit = React.lazy(() => import('../screens/role/Edit'));
const RoleDetail = React.lazy(() => import('../screens/role/Detail'));
const Group = React.lazy(() => import('../screens/groupCompany'));
const Company = React.lazy(() => import('../screens/company'));
const Asset = React.lazy(() => import('../screens/asset'));
const Device = React.lazy(() => import('../screens/device'));

const RootNavigator: React.FC = () => {
  const oktaAuth = new OktaAuth({
    issuer: process.env.REACT_APP_OKTA_ISSUER,
    clientId: process.env.REACT_APP_OKTA_CLIENT_ID,
    // redirectUri: 'https://beta.ramus.id/api/security/authentication/login',
    redirectUri: process.env.REACT_APP_OKTA_REDIRECT_URI,
    scopes: ['openid', 'profile', 'email'], // Use authorization_code flow
    responseType: 'code',
    pkce: false,
  });

  // const navigate = useNavigate();

  // const restoreOriginalUri = async (_oktaAuth: any, originalUri: any) => {
  //   history.replace(toRelativeUrl(originalUri || '/', window.location.origin));
  // };

  const restoreOriginalUri = async (_oktaAuth: any, originalUri: any) => {
    // navigate("/", { replace: true });
  };

  const { isLoggedIn } = useAuth();

  return (
    <Suspense
      fallback={
        <div
          style={{
            height: '100vh',
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Spin spinning={true} />
        </div>
      }
    >
      <Router>
        <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
          <Routes>
            <Route
              path='/'
              element={<Navigate to={isLoggedIn ? '/home' : '/login'} />}
            />
            <Route element={<PublicRestrictedRoute />}>
              <Route
                path='/select-application'
                element={<ChooseApplication />}
              />
            </Route>
            <Route element={<PublicRoute />}>
              <Route path='/login' element={<Login />} />
              {/* <Route path="/phone-login" element={<LoginPhone />} /> */}
              <Route path='/forgot-password' element={<ForgotPassword />} />
              <Route
                path='/reset-password/:sessionId'
                element={<ResetPassword />}
              />
              <Route path='/select-company' element={<CompanySelection />} />
              <Route path='/dailyreport/export' element={<ReportDaily />} />
            </Route>
            <Route element={<PrivateRoute />}>
              <Route path='*' element={<NotFound />} />
              <Route path='/dashboard' element={<Dashboard />} />
              <Route path='/home' element={<Home />} />
              <Route path='/home-bms' element={<HomeBMS />} />

              <Route path='/users' element={<User />} />
              <Route path='/users/add' element={<UserEdit />} />
              <Route path='/user/:userId/edit' element={<UserEdit />} />
              <Route path='/users/:userId' element={<UserDetail />} />

              <Route path='/roles' element={<Role />} />
              <Route path='/roles/add' element={<RoleEdit />} />
              <Route path='/roles/:roleId/edit' element={<RoleEdit />} />
              <Route path='/roles/:roleId' element={<RoleDetail />} />

              <Route path='/group' element={<Group />} />
              <Route path='/company' element={<Company />} />
              <Route path='/company/:compId' element={<CompanyDetail />} />
              <Route path='/asset' element={<Asset />} />
              <Route path='/devices' element={<Device />} />
              <Route path='/devices/:devcName' element={<DeviceDetail />} />
              <Route path='/report-bms-log' element={<ReportBunkerLog />} />

              <Route path='/report-summary' element={<Report />} />
              <Route path='/report-vessel-tracking' element={<Report />} />
              <Route path='/report-chart/:chartId' element={<Report />} />
              <Route path='/report-data-log' element={<ReportLog />} />
              <Route path='/login/callback' element={LoginCallback} />
            </Route>
          </Routes>
        </Security>
      </Router>
    </Suspense>
  );
};
export default RootNavigator;
