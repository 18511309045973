import moment from "moment";
import styled from "styled-components";

interface IProps {
  massName: string;
  start: string;
  end: string;
}

const ReportDailyHeader: React.FC<IProps> = ({ massName, start, end }) => {
  return (
    <div>
      <RPTitle>Daily Report</RPTitle>
      <RPHeaderWrapper>
        <div>
          <h4>Vessel: {massName}</h4>
          <span>
            Analyzed data: {moment(start).format("DD MMM YYYY HH:mm")} to{" "}
            {moment(end).format("DD MMM YYYY HH:mm")} GMT +007
          </span>
        </div>
        <img src='/images/logo-ramus.png' alt='ramus logo' />
      </RPHeaderWrapper>
    </div>
  );
};

const RPTitle = styled.h2`
  font-size: 22px;
  color: black;
  font-weight: bold;
  text-align: center;
  padding-top: 30px;
`;

const RPHeaderWrapper = styled.div`
  border-bottom: 3px solid #012b65;
  padding-bottom: 5px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  h4 {
    color: black;
    margin: 0;
    font-weight: bold;
    font-size: 18px;
  }
  img {
    width: 120px;
  }
  span {
    font-size: 14px;
    color: black;
  }
`;

export default ReportDailyHeader;
