import React, { useEffect, useState, useRef, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import CustomForm from '../../components/CustomForm';
import { Button, DatePicker, Select, Spin, Table } from 'antd';
import { intervalOptions, rangeOptions } from '../../helpers/map';
import moment, { Moment } from 'moment';
import type { RangePickerProps } from 'antd/es/date-picker';
import {
  IAEData,
  IAESummary,
  IFlowmeterData,
  IFlowmeterSummary,
  IGPSData,
  IGPSSummary,
  IRPMData,
  IRPMSummary,
  initialGPSData,
} from '../../types/summary.type';
import useAsset from '../../hooks/useAsset';
import {
  generateTimestampArray,
  roundTimestampsToNearestMinute,
  toHHMMSS,
  toKM,
  toKnot,
} from '../../helpers/dateUtil';
import SeriesSvc from '../../services/SeriesSvc';
import CardInfo from '../dashboard/components/CardInfo';
import { hColors } from '../../helpers/chart';
import HChartDevice, {
  IPropsChartDevice,
} from '../../components/charts/HChartDevice';
import Highcharts from 'highcharts';
import { singleEngineAssets } from '../../helpers/constant';
moment.tz.setDefault('Asia/Jakarta');

interface DataDeviceFilter {
  rangeType: string;
  range: {
    startAt: Moment | Date | string;
    endAt: Moment | Date | string;
  };
  interval: string;
}

const initialDataFilter: DataDeviceFilter = {
  rangeType: rangeOptions[4].value,
  range: {
    startAt: moment().set({ hour: 0, minute: 0, second: 0 }),
    endAt: moment(),
  },
  interval: intervalOptions[0].value,
};

const { RangePicker } = DatePicker;

const dateTimeFormat = 'DD-MM-YYYY HH:mm';

const windowHeight = window.innerHeight;

interface IDataLogGPS {
  fields: any[];
  data: IGPSData[];
}

interface IDataLogPowerSingle {
  onAC: number | undefined;
  batteryFail: number | undefined;
  batteryVoltage: number | undefined;
  id: number | undefined;
  deviceUniqueId: number | undefined;
  timestamp: number;
}

interface IDataLogPower {
  fields: any[];
  data: IDataLogPowerSingle[];
}

interface IDataLogGatewaySingle {
  cpuUsage: number | undefined;
  memoryFree: number | undefined;
  spaceFree: number | undefined;
  id: number | undefined;
  deviceUniqueId: number | undefined;
  timestamp: number;
}

interface IDataLogGateway {
  fields: any[];
  data: IDataLogGatewaySingle[];
}

interface IDataLogRPMSingle {
  portRPM: number | undefined | null;
  stbRPM: number | undefined | null;
  timestamp: number;
}

interface IDataLogRPM {
  fields: any[];
  data: IDataLogRPMSingle[];
}

interface IDataLogAESingle {
  runningTimeAE1: number | undefined | null;
  runningTimeAE2: number | undefined | null;
  runningTimeAE3: number | undefined | null;
  timestamp: number;
}

interface IDataLogAE {
  fields: any[];
  data: IDataLogAESingle[];
}

interface IFlowmeterDataLog {
  timestamp: number;
  volumeFlowrate: number | undefined | null;
  volumeTotal: number | undefined | null;
  density: number | undefined | null;
  massFlowrate: number | undefined | null;
  massTotal: number | undefined | null;
  temperature: number | undefined | null;
}

const initialFlowmeterDataLog: IFlowmeterDataLog = {
  timestamp: 0,
  volumeFlowrate: undefined,
  volumeTotal: undefined,
  density: undefined,
  massFlowrate: undefined,
  massTotal: undefined,
  temperature: undefined,
};

interface IDataLogFm {
  fields: any[];
  data: IFlowmeterDataLog[];
}

const DeviceDetail = () => {
  // const { devcId, devcType } = useParams<keyof ILocation>() as ILocation;
  const { state } = useLocation();
  const { devcMassId, devcName, devcLabel, devcId, devcType, devcUniqueId } =
    state;
  const [dataFilter, setDataFilter] = useState({
    ...initialDataFilter,
  });
  const [isLoading, setLoading] = useState(false);
  const { fetchSummarySingleDevice } = useAsset();

  const [dataLogRPM, setDataLogRPM] = useState<IDataLogRPM>({
    fields: [],
    data: [],
  });
  const [dataLogGPS, setDataLogGPS] = useState<IDataLogGPS>({
    fields: [],
    data: [],
  });
  const [dataLogFM, setDataLogFM] = useState<IDataLogFm>({
    fields: [],
    data: [],
  });
  const [dataLogAE, setDataLogAE] = useState<IDataLogAE>({
    fields: [],
    data: [],
  });
  const [dataLogGateway, setDataLogGateway] = useState<IDataLogGateway>({
    fields: [],
    data: [],
  });
  const [dataLogPower, setDataLogPower] = useState<IDataLogPower>({
    fields: [],
    data: [],
  });

  const [aesTotal, setAesTotal] = useState([]);
  const [gpsSummary, setGpsSummary] = useState({
    totalCruise: 0,
    averageSpeed: 0,
    distance: 0,
  });
  const [flowmeterSummary, setFlowmeterSummary] = useState({
    averageFlow: 0,
    flowingTime: 0,
    totalFlow: 0,
  });
  const [dataChart, setDataChart] = useState<IPropsChartDevice>();
  const [customPlotOptions, setCustomPlotOptions] = useState<any>();
  const dataFetchedRef = useRef(false);

  const onChangeRangeType = async (value: string) => {
    let rangeType: any;

    const found = rangeOptions.find((item) => item.value === value);

    if (found) {
      rangeType = found.value;
    }

    let interval = dataFilter.interval;
    let startAt = moment();
    let endAt = moment();
    if (value === 'today' || value === 'last_day') {
      interval = intervalOptions[0].value;
    } else {
      interval = intervalOptions[2].value;
    }
    switch (value) {
      case 'last_days_30':
        startAt = moment().subtract(30, 'day');
        endAt = moment();
        break;
      case 'last_month':
        startAt = moment()
          .subtract(1, 'month')
          .startOf('month')
          .set({ hour: 0, minute: 0, second: 0 });
        endAt = moment()
          .subtract(1, 'month')
          .endOf('month')
          .set({ hour: 23, minute: 59, second: 59 });
        break;
      case 'last_days_7':
        startAt = moment().subtract(7, 'days');
        endAt = moment();
        break;
      case 'last_week':
        startAt = moment().subtract(1, 'week').startOf('week');
        endAt = moment();
        break;
      case 'today':
        startAt = moment().set({ hour: 0, minute: 0, second: 0 });
        endAt = moment();
        break;

      default:
        break;
    }
    setDataFilter((oldVal) => ({
      ...oldVal,
      rangeType,
      interval,
      range: {
        startAt,
        endAt,
      },
    }));
  };

  const onChangeInterval = (interval: string) => {
    setDataFilter((oldVal) => ({
      ...oldVal,
      interval,
    }));
  };

  const onChangeDateRange: RangePickerProps['onChange'] = (
    dates,
    dateStrings
  ) => {
    if (dates) {
      setDataFilter((oldData) => ({
        ...oldData,
        rangeType: rangeOptions[6].value,
        range: {
          startAt: moment(dateStrings[0]),
          endAt: moment(dateStrings[1]),
        },
      }));
    } else {
      console.log('Clear');
    }
  };

  const constructDataChartGPS = (
    data: {
      datetime: string;
      altitude: number | null;
      speed: number | null;
      distance: number | null;
    }[]
  ) => {
    const dataXAxis = data.map((item) => item.datetime);

    const dataSeries: any = [
      {
        name: 'Altitude',
        color: hColors.pink,
        type: 'line',
        yAxis: 0,
        data: data.map((item) => item.altitude),
      },
      {
        name: 'Speed',
        color: hColors.teal,
        type: 'line',
        yAxis: 1,
        data: data.map((item) => item.speed),
      },
      {
        name: 'Distance',
        color: hColors.purple,
        type: 'line',
        yAxis: 2,
        data: data.map((item) => item.distance),
      },
    ];

    return {
      dataXAxis,
      dataYAxis: yAxisGPS,
      dataSeries: dataSeries,
      title: devcName,
    };
  };

  const constructDataChartAE = (data: IDataLogAESingle[]) => {
    const dataXAxis = data.map((item) =>
      moment(item.timestamp * 1000).format('DD-MM-YY HH:mm')
    );

    setCustomPlotOptions({
      column: {
        stacking: 'normal',
        // dataLabels: {
        //   enabled: true,
        // },
      },
    });

    const dataSeries: any = [
      {
        name: 'AE 1',
        color: hColors.purple,
        type: 'column',
        data: data.map((item) => item.runningTimeAE1),
      },
      {
        name: 'AE 2',
        color: hColors.teal,
        type: 'column',
        data: data.map((item) => item.runningTimeAE2),
      },
      {
        name: 'AE 3',
        color: hColors.orange,
        type: 'column',
        data: data.map((item) => item.runningTimeAE3),
      },
    ];

    return {
      dataXAxis,
      dataYAxis: yAxisAE,
      dataSeries: dataSeries,
      title: devcName,
    };
  };

  const constructDataChartRPM = (data: IDataLogRPMSingle[]) => {
    const dataXAxis = data.map((item) =>
      moment(item.timestamp * 1000).format('DD-MM-YY HH:mm')
    );

    let dataSeries: any = [];

    if (singleEngineAssets.includes(devcMassId)) {
      dataSeries = [
        {
          name: 'ME RPM',
          color: hColors.purple,
          type: 'line',
          data: data.map((item) => item.portRPM),
        },
      ];
    } else {
      dataSeries = [
        {
          name: 'PORT',
          color: hColors.purple,
          type: 'line',
          data: data.map((item) => item.portRPM),
        },
        {
          name: 'STARBOARD',
          color: hColors.teal,
          type: 'line',
          data: data.map((item) => item.stbRPM),
        },
      ];
    }

    return {
      dataXAxis,
      dataYAxis: yAxisRPM,
      dataSeries: dataSeries,
      title: devcName,
    };
  };

  const constructDataChartFlowmeter = (data: IFlowmeterDataLog[]) => {
    const dataXAxis = data.map((item) =>
      moment(item.timestamp * 1000).format('DD-MM-YY HH:mm:ss')
    );
    const dataSeries: any = [
      {
        name: 'Volume Flow Rate',
        color: hColors.purple,
        type: 'line',
        yAxis: 0,
        data: data.map((item: any) => item.volumeFlowrate),
        tooltip: {},
      },
      {
        name: 'Volume Total',
        color: hColors.blue,
        type: 'line',
        yAxis: 1,
        data: data.map((item: any) => item.volumeTotal),
        tooltip: {
          // valueSuffix: " MT/HR",
          formatter(
            this: Highcharts.AxisLabelsFormatterContextObject,
            ctx: Highcharts.AxisLabelsFormatterContextObject
          ) {
            return typeof this.value === 'number'
              ? this.value.toFixed(2)
              : this.value + ' lala';
          },
        },
      },
      {
        name: 'Density',
        color: hColors.pink,
        type: 'line',
        yAxis: 2,
        data: data.map((item: any) => item.density),
        tooltip: {
          // valueSuffix: " MT/HR",
        },
      },
      {
        name: 'Mass Flow Rate',
        color: hColors.orange,
        type: 'line',
        yAxis: 3,
        data: data.map((item: any) => item.massFlowrate),
        tooltip: {
          // valueSuffix: " L",
        },
      },
      {
        name: 'Mass Total',
        color: hColors.green,
        type: 'line',
        yAxis: 4,
        data: data.map((item: any) => item.massTotal),
        tooltip: {
          // valueSuffix: " L",
        },
      },
      {
        name: 'Temperature',
        color: hColors.darkBlue,
        type: 'line',
        yAxis: 5,
        data: data.map((item: any) => item.temperature),
        tooltip: {
          // valueSuffix: " oC",
        },
      },
    ];

    return {
      dataXAxis,
      dataYAxis: yAxisFlowmeter,
      dataSeries,
      title: devcName,
    };
  };

  const handleOnApplyFilter = async () => {
    if (devcType === 'gateway' || devcType === 'power') {
      await handleFetchDataSeries();
    } else {
      await handleFetchDataSummary();
    }
  };

  const handleFetchDataSummary = async () => {
    try {
      setLoading(true);
      const res: any = await fetchSummarySingleDevice({
        aggregatedUnit: dataFilter.interval
          ? dataFilter.interval
          : intervalOptions[0].value,
        start: dataFilter.range.startAt
          ? dataFilter.range.startAt.valueOf().toString()
          : undefined,
        end: dataFilter.range.endAt
          ? dataFilter.range.endAt.valueOf().toString()
          : undefined,
        devcMassId: devcMassId ? devcMassId : undefined,
        devcType: devcType ? [devcType] : undefined,
        aggregatedLength: 1,
      });

      let dateStart = moment(dataFilter.range.startAt).unix();
      let dateEnd = moment(dataFilter.range.endAt).unix();

      if (
        !['MINUTE', 'HOUR'].includes(dataFilter.interval) &&
        res.timestamps.length > 0
      ) {
        const timestampsSorted = res.timestamps.sort(
          (a: any, b: any) => Number(a) - Number(b)
        );
        dateStart = Number(timestampsSorted[0]);
        dateEnd = Number(timestampsSorted[res.timestamps.length - 1]);
      }

      const newTimestamp = generateTimestampArray(
        dateStart,
        dateEnd,
        dataFilter.interval === 'MINUTE'
          ? 60
          : dataFilter.interval === 'HOUR'
          ? 3600
          : 86400
      );

      switch (devcType) {
        case 'gps':
          const currDataLogGPS: any[] = [];
          const dataRawGPS = res as {
            summary: IGPSSummary;
            data: IGPSData[];
          };

          newTimestamp.forEach((timestamp: number) => {
            // Generate GPS Data
            const isGPSTimestampSame = dataRawGPS.data.find(
              (item) =>
                timestamp ===
                roundTimestampsToNearestMinute(item.timestamp * 1000) / 1000
            );

            if (isGPSTimestampSame) {
              currDataLogGPS.push({
                ...isGPSTimestampSame,
                timestamp,
              });
            } else {
              currDataLogGPS.push({
                track: undefined,
                satellites: undefined,
                altitude: undefined,
                geoidal: undefined,
                latitude: undefined,
                longitude: undefined,
                polyline: undefined,
                speed: undefined,
                distance: undefined,
                id: undefined,
                deviceId: undefined,
                datetime: undefined,
                additional: undefined,
                polyLength: undefined,
                timestamp,
              });
            }
          });

          let gpsSummary = {
            distance: dataRawGPS.summary?.distance || 0,
            averageSpeed: dataRawGPS.summary?.averageSpeed || 0,
            totalCruise: dataRawGPS.summary?.totalDistance || 0,
          };

          setDataLogGPS({
            fields: [
              {
                dataIndex: 'timestamp',
                title: 'Date Time',
                class: 'text-center',
                render: (value: number) => {
                  return moment(value * 1000).format('DD-MM-YY HH:mm:ss');
                },
              },
              {
                dataIndex: 'latitude',
                title: 'Latitude',
                class: 'text-center',
                render: (value: string) => {
                  return typeof value === 'undefined' ? '-' : value;
                },
              },
              {
                dataIndex: 'longitude',
                title: 'Longitude',
                class: 'text-center',
                render: (value: string) => {
                  return typeof value === 'undefined' ? '-' : value;
                },
              },
              {
                dataIndex: 'altitude',
                title: 'Altitude',
                class: 'text-center',
                render: (value: string) => {
                  return typeof value === 'undefined' ? '-' : value;
                },
              },
              {
                dataIndex: 'track',
                title: 'Heading',
                class: 'text-center',
                render: (value: string) => {
                  return typeof value === 'undefined' ? '-' : value;
                },
              },
              {
                dataIndex: 'speed',
                title: 'Speed (Km/h)',
                class: 'text-center',
                render: (value: any) => {
                  return typeof value === 'undefined'
                    ? '-'
                    : typeof value === 'number'
                    ? value.toFixed(2)
                    : value;
                },
              },
              {
                dataIndex: 'distance',
                title: 'Distance (Km)',
                class: 'text-center',
                render: (value: any) => {
                  return typeof value === 'undefined'
                    ? '-'
                    : typeof value === 'number'
                    ? value.toFixed(2)
                    : value;
                },
              },
            ],
            data: currDataLogGPS,
          });

          setGpsSummary(gpsSummary);
          setDataChart(
            constructDataChartGPS(
              currDataLogGPS.map((item) => ({
                datetime: moment(item.timestamp * 1000).format(
                  'DD-MM-YY HH:mm:ss'
                ),
                altitude:
                  typeof item.altitude === 'undefined' ? null : item.altitude,
                speed: typeof item.speed === 'undefined' ? null : item.speed,
                distance:
                  typeof item.distance === 'undefined' ? null : item.distance,
              }))
            )
          );
          break;
        case 'rpm':
          const currDataLogRPM: IDataLogRPMSingle[] = [];
          const dataRawRPM = res as {
            summary: IRPMSummary;
            data: IRPMData[];
          };

          newTimestamp.forEach((timestamp: number, idx: number) => {
            // Generate PORT RPM Data
            const isPortTimestampSame = dataRawRPM.data.find(
              (item) =>
                item['PORT'] &&
                timestamp ===
                  roundTimestampsToNearestMinute(
                    item['PORT'].timestamp * 1000
                  ) /
                    1000
            );

            // Generate STARBOARD RPM Data
            const isStbTimestampSame = dataRawRPM.data.find(
              (item) =>
                item['STARBOARD'] &&
                timestamp ===
                  roundTimestampsToNearestMinute(
                    item['STARBOARD'].timestamp * 1000
                  ) /
                    1000
            );

            if (isPortTimestampSame && isStbTimestampSame) {
              currDataLogRPM.push({
                portRPM: isPortTimestampSame['PORT'].rpm,
                stbRPM: isStbTimestampSame['STARBOARD'].rpm,
                timestamp,
              });
            } else if (isPortTimestampSame) {
              currDataLogRPM.push({
                portRPM: isPortTimestampSame['PORT'].rpm,
                stbRPM: undefined,
                timestamp,
              });
            } else if (isStbTimestampSame) {
              currDataLogRPM.push({
                portRPM: undefined,
                stbRPM: isStbTimestampSame['STARBOARD'].rpm,
                timestamp,
              });
            } else {
              currDataLogRPM.push({
                portRPM: undefined,
                stbRPM: undefined,
                timestamp,
              });
            }
          });

          const isSingleEngine = singleEngineAssets.includes(devcMassId);

          if (!isSingleEngine) {
            setDataLogRPM({
              fields: [
                {
                  dataIndex: 'timestamp',
                  title: 'Date Time',
                  class: 'text-center',
                  render: (value: number) => {
                    return typeof value === 'undefined'
                      ? '-'
                      : moment(value * 1000).format('DD-MM-YY HH:mm:ss');
                  },
                },
                {
                  dataIndex: 'portRPM',
                  title: 'PORT RPM',
                  class: 'text-center',
                  render: (value: any) => {
                    return typeof value === 'undefined'
                      ? '-'
                      : typeof value === 'number'
                      ? value.toFixed(2)
                      : value;
                  },
                },
                {
                  dataIndex: 'stbRPM',
                  title: 'STARBOARD RPM',
                  class: 'text-center',
                  render: (value: any) => {
                    return typeof value === 'undefined'
                      ? '-'
                      : typeof value === 'number'
                      ? value.toFixed(2)
                      : value;
                  },
                },
              ],
              data: currDataLogRPM,
            });

            setDataChart(
              constructDataChartRPM(
                currDataLogRPM.map((item) => {
                  return {
                    ...item,
                    portRPM:
                      typeof item.portRPM === 'undefined' ? null : item.portRPM,
                    stbRPM:
                      typeof item.stbRPM === 'undefined' ? null : item.stbRPM,
                  };
                })
              )
            );
          } else {
            setDataLogRPM({
              fields: [
                {
                  dataIndex: 'timestamp',
                  title: 'Date Time',
                  class: 'text-center',
                  render: (value: number) => {
                    return typeof value === 'undefined'
                      ? '-'
                      : moment(value * 1000).format('DD-MM-YY HH:mm:ss');
                  },
                },
                {
                  dataIndex: 'portRPM',
                  title: 'ME RPM',
                  class: 'text-center',
                  render: (value: any) => {
                    return typeof value === 'undefined'
                      ? '-'
                      : typeof value === 'number'
                      ? value.toFixed(2)
                      : value;
                  },
                },
              ],
              data: currDataLogRPM,
            });

            setDataChart(
              constructDataChartRPM(
                currDataLogRPM.map((item) => {
                  return {
                    ...item,
                    portRPM:
                      typeof item.portRPM === 'undefined' ? null : item.portRPM,
                  };
                })
              )
            );
          }

          break;
        case 'ae':
          const currDataLogAE: IDataLogAESingle[] = [];
          const dataRawAE = res as {
            summary: IAESummary;
            data: IAEData[];
          };

          newTimestamp.forEach((timestamp: number) => {
            // Generate AE1 Data
            const isAE1TimestampSame = dataRawAE.data.find(
              (item) =>
                item.AE1 &&
                timestamp ===
                  roundTimestampsToNearestMinute(item.AE1.timestamp * 1000) /
                    1000
            );

            // Generate AE2 Data
            const isAE2TimestampSame = dataRawAE.data.find(
              (item) =>
                item.AE2 &&
                timestamp ===
                  roundTimestampsToNearestMinute(item.AE2.timestamp * 1000) /
                    1000
            );

            // Generate AE2 Data
            const isAE3TimestampSame = dataRawAE.data.find(
              (item) =>
                item.AE2 &&
                timestamp ===
                  roundTimestampsToNearestMinute(item.AE3.timestamp * 1000) /
                    1000
            );

            currDataLogAE.push({
              runningTimeAE1: isAE1TimestampSame
                ? isAE1TimestampSame.AE1.runningSeconds
                : undefined,
              runningTimeAE2: isAE2TimestampSame
                ? isAE2TimestampSame.AE2.runningSeconds
                : undefined,
              runningTimeAE3: isAE3TimestampSame
                ? isAE3TimestampSame.AE3.runningSeconds
                : undefined,
              timestamp,
            });
          });

          let aesTotal: any = [];
          aesTotal[0] = dataRawAE.summary?.runningSeconds?.AE1 || 0;
          aesTotal[1] = dataRawAE.summary?.runningSeconds?.AE2 || 0;
          aesTotal[2] = dataRawAE.summary?.runningSeconds?.AE3 || 0;

          setDataLogAE({
            fields: [
              {
                dataIndex: 'timestamp',
                title: 'Date Time',
                class: 'text-center',
                render: (value: number) => {
                  return moment(value * 1000).format('DD-MM-YY HH:mm:ss');
                },
              },
              {
                dataIndex: 'runningTimeAE1',
                title: 'AE1 Running Second',
                class: 'text-center',
                render: (value: any) => {
                  return typeof value === 'undefined'
                    ? '-'
                    : typeof value === 'number'
                    ? value.toFixed(2)
                    : value;
                },
              },
              {
                dataIndex: 'runningTimeAE2',
                title: 'AE2 Running Second',
                class: 'text-center',
                render: (value: any) => {
                  return typeof value === 'undefined'
                    ? '-'
                    : typeof value === 'number'
                    ? value.toFixed(2)
                    : value;
                },
              },
              {
                dataIndex: 'runningTimeAE3',
                title: 'AE3 Running Second',
                class: 'text-center',
                render: (value: any) => {
                  return typeof value === 'undefined'
                    ? '-'
                    : typeof value === 'number'
                    ? value.toFixed(2)
                    : value;
                },
              },
            ],
            data: currDataLogAE,
          });

          setAesTotal(aesTotal);
          setDataChart(
            constructDataChartAE(
              currDataLogAE.map((item) => {
                return {
                  ...item,
                  runningTimeAE1:
                    typeof item.runningTimeAE1 === 'undefined'
                      ? null
                      : item.runningTimeAE1,
                  runningTimeAE2:
                    typeof item.runningTimeAE2 === 'undefined'
                      ? null
                      : item.runningTimeAE2,
                  runningTimeAE3:
                    typeof item.runningTimeAE3 === 'undefined'
                      ? null
                      : item.runningTimeAE3,
                };
              })
            )
          );
          break;
        case 'flowmeter':
          const currDataLogFm: IFlowmeterDataLog[] = [];

          let flowmeterSummary = {
            averageFlow: 0,
            flowingTime: 0,
            totalFlow: 0,
          };

          const dataRawFm = res as {
            summary: IFlowmeterSummary;
            data: IFlowmeterData[];
          };

          newTimestamp.forEach((timestamp: number) => {
            // Generate PORT_IN flowmeter
            if (devcLabel === 'PORT_IN') {
              const isFmPortInTimestampSame = dataRawFm.data.find(
                (item) =>
                  item.PORT_IN &&
                  timestamp ===
                    roundTimestampsToNearestMinute(
                      item.PORT_IN.timestamp * 1000
                    ) /
                      1000
              );

              // Generate flowmeter data
              if (isFmPortInTimestampSame) {
                currDataLogFm.push({
                  timestamp,
                  volumeFlowrate:
                    isFmPortInTimestampSame.PORT_IN.volumeFlowrate,
                  volumeTotal: isFmPortInTimestampSame.PORT_IN.volumeTotal,
                  density: isFmPortInTimestampSame.PORT_IN.density,
                  massFlowrate: isFmPortInTimestampSame.PORT_IN.massFlowrate,
                  massTotal: isFmPortInTimestampSame.PORT_IN.massTotal,
                  temperature: isFmPortInTimestampSame.PORT_IN.temperature,
                });
              } else {
                currDataLogFm.push({
                  ...initialFlowmeterDataLog,
                  timestamp,
                });
              }

              // Generate flowmeter summary
              flowmeterSummary.averageFlow =
                dataRawFm.summary?.PORT_IN?._averageFlowRate || 0;
              flowmeterSummary.flowingTime =
                dataRawFm.summary?.PORT_IN?._flowTime || 0;
              flowmeterSummary.totalFlow =
                dataRawFm.summary?.PORT_IN?._totalFlow || 0;
            }

            // Generate PORT_OUT flowmeter
            if (devcLabel === 'PORT_OUT') {
              const isFmPortOutTimestampSame = dataRawFm.data.find(
                (item) =>
                  timestamp ===
                  roundTimestampsToNearestMinute(
                    item.PORT_OUT.timestamp * 1000
                  ) /
                    1000
              );

              // Generate flowmeter data
              if (isFmPortOutTimestampSame) {
                currDataLogFm.push({
                  timestamp,
                  volumeFlowrate:
                    isFmPortOutTimestampSame.PORT_OUT.volumeFlowrate,
                  volumeTotal: isFmPortOutTimestampSame.PORT_OUT.volumeTotal,
                  density: isFmPortOutTimestampSame.PORT_OUT.density,
                  massFlowrate: isFmPortOutTimestampSame.PORT_OUT.massFlowrate,
                  massTotal: isFmPortOutTimestampSame.PORT_OUT.massTotal,
                  temperature: isFmPortOutTimestampSame.PORT_OUT.temperature,
                });
              } else {
                currDataLogFm.push({
                  ...initialFlowmeterDataLog,
                  timestamp,
                });
              }

              // Generate flowmeter summary
              flowmeterSummary.averageFlow =
                dataRawFm.summary?.PORT_OUT?._averageFlowRate || 0;
              flowmeterSummary.flowingTime =
                dataRawFm.summary?.PORT_OUT?._flowTime || 0;
              flowmeterSummary.totalFlow =
                dataRawFm.summary?.PORT_OUT?._totalFlow || 0;
            }

            // Generate STARBOARD_IN flowmeter
            if (devcLabel === 'STARBOARD_IN') {
              const isFmStbInTimestampSame = dataRawFm.data.find(
                (item) =>
                  timestamp ===
                  roundTimestampsToNearestMinute(
                    item.STARBOARD_IN.timestamp * 1000
                  ) /
                    1000
              );

              // Generate flowmeter data
              if (isFmStbInTimestampSame) {
                currDataLogFm.push({
                  timestamp,
                  volumeFlowrate:
                    isFmStbInTimestampSame.STARBOARD_IN.volumeFlowrate,
                  volumeTotal: isFmStbInTimestampSame.STARBOARD_IN.volumeTotal,
                  density: isFmStbInTimestampSame.STARBOARD_IN.density,
                  massFlowrate:
                    isFmStbInTimestampSame.STARBOARD_IN.massFlowrate,
                  massTotal: isFmStbInTimestampSame.STARBOARD_IN.massTotal,
                  temperature: isFmStbInTimestampSame.STARBOARD_IN.temperature,
                });
              } else {
                currDataLogFm.push({
                  ...initialFlowmeterDataLog,
                  timestamp,
                });
              }

              // Generate flowmeter summary
              flowmeterSummary.averageFlow =
                dataRawFm.summary?.STARBOARD_IN?._averageFlowRate || 0;
              flowmeterSummary.flowingTime =
                dataRawFm.summary?.STARBOARD_IN?._flowTime || 0;
              flowmeterSummary.totalFlow =
                dataRawFm.summary?.STARBOARD_IN?._totalFlow || 0;
            }

            // Generate STARBOARD_OUT flowmeter
            if (devcLabel === 'STARBOARD_OUT') {
              const isFmStbOutTimestampSame = dataRawFm.data.find(
                (item) =>
                  timestamp ===
                  roundTimestampsToNearestMinute(
                    item.STARBOARD_OUT.timestamp * 1000
                  ) /
                    1000
              );

              // Generate flowmeter data
              if (isFmStbOutTimestampSame) {
                currDataLogFm.push({
                  timestamp,
                  volumeFlowrate:
                    isFmStbOutTimestampSame.STARBOARD_OUT.volumeFlowrate,
                  volumeTotal:
                    isFmStbOutTimestampSame.STARBOARD_OUT.volumeTotal,
                  density: isFmStbOutTimestampSame.STARBOARD_OUT.density,
                  massFlowrate:
                    isFmStbOutTimestampSame.STARBOARD_OUT.massFlowrate,
                  massTotal: isFmStbOutTimestampSame.STARBOARD_OUT.massTotal,
                  temperature:
                    isFmStbOutTimestampSame.STARBOARD_OUT.temperature,
                });
              } else {
                currDataLogFm.push({
                  ...initialFlowmeterDataLog,
                  timestamp,
                });
              }

              // Generate flowmeter summary
              flowmeterSummary.averageFlow =
                dataRawFm.summary?.STARBOARD_OUT?._averageFlowRate || 0;
              flowmeterSummary.flowingTime =
                dataRawFm.summary?.STARBOARD_OUT?._flowTime || 0;
              flowmeterSummary.totalFlow =
                dataRawFm.summary?.STARBOARD_OUT?._totalFlow || 0;
            }

            // Generate BUNKER_IN flowmeter
            if (devcLabel === 'BUNKER_IN') {
              const isFmStbOutTimestampSame = dataRawFm.data.find(
                (item) =>
                  item.BUNKER_IN &&
                  timestamp ===
                    roundTimestampsToNearestMinute(
                      item.BUNKER_IN.timestamp * 1000
                    ) /
                      1000
              );

              // Generate flowmeter data
              if (
                isFmStbOutTimestampSame &&
                isFmStbOutTimestampSame.BUNKER_IN
              ) {
                currDataLogFm.push({
                  timestamp,
                  volumeFlowrate:
                    isFmStbOutTimestampSame.BUNKER_IN.volumeFlowrate,
                  volumeTotal: isFmStbOutTimestampSame.BUNKER_IN.volumeTotal,
                  density: isFmStbOutTimestampSame.BUNKER_IN.density,
                  massFlowrate: isFmStbOutTimestampSame.BUNKER_IN.massFlowrate,
                  massTotal: isFmStbOutTimestampSame.BUNKER_IN.massTotal,
                  temperature: isFmStbOutTimestampSame.BUNKER_IN.temperature,
                });
              } else {
                currDataLogFm.push({
                  ...initialFlowmeterDataLog,
                  timestamp,
                });
              }

              // Generate flowmeter summary
              flowmeterSummary.averageFlow =
                dataRawFm.summary?.BUNKER_IN?._averageFlowRate || 0;
              flowmeterSummary.flowingTime =
                dataRawFm.summary?.BUNKER_IN?._flowTime || 0;
              flowmeterSummary.totalFlow =
                dataRawFm.summary?.BUNKER_IN?._totalFlow || 0;
            }
          });

          setDataLogFM({
            fields: [
              {
                dataIndex: 'timestamp',
                title: 'Date Time',
                class: 'text-center',
                render: (value: number) => {
                  return moment(value * 1000).format('DD-MM-YY HH:mm:ss');
                },
              },
              {
                dataIndex: 'volumeFlowrate',
                title: 'Volume Flow Rate',
                class: 'text-center',
                render: (value: any) => {
                  return typeof value === 'undefined'
                    ? '-'
                    : typeof value === 'number'
                    ? value.toFixed(2)
                    : value;
                },
              },
              {
                dataIndex: 'volumeTotal',
                title: 'Volume Total',
                class: 'text-center',
                render: (value: any) => {
                  return typeof value === 'undefined'
                    ? '-'
                    : typeof value === 'number'
                    ? value.toFixed(2)
                    : value;
                },
              },
              { dataIndex: 'density', title: 'Density', class: 'text-center' },
              {
                dataIndex: 'massFlowrate',
                title: 'Mass Flow Rate',
                class: 'text-center',
                render: (value: any) => {
                  return typeof value === 'undefined'
                    ? '-'
                    : typeof value === 'number'
                    ? value.toFixed(2)
                    : value;
                },
              },
              {
                dataIndex: 'massTotal',
                title: 'Mass Total',
                class: 'text-center',
                render: (value: any) => {
                  return typeof value === 'undefined'
                    ? '-'
                    : typeof value === 'number'
                    ? value.toFixed(2)
                    : value;
                },
              },
              {
                dataIndex: 'temperature',
                title: 'Temperature',
                class: 'text-center',
                render: (value: any) => {
                  return typeof value === 'undefined'
                    ? '-'
                    : typeof value === 'number'
                    ? value.toFixed(2)
                    : value;
                },
              },
            ],
            data: currDataLogFm,
          });

          setFlowmeterSummary(flowmeterSummary);
          setDataChart(
            constructDataChartFlowmeter(
              currDataLogFm.map((item) => {
                return {
                  ...item,
                  volumeFlowrate:
                    typeof item.volumeFlowrate === 'undefined'
                      ? null
                      : item.volumeFlowrate,
                  volumeTotal:
                    typeof item.volumeTotal === 'undefined'
                      ? null
                      : item.volumeTotal,
                  density:
                    typeof item.density === 'undefined' ? null : item.density,
                  massFlowrate:
                    typeof item.massFlowrate === 'undefined'
                      ? null
                      : item.massFlowrate,
                  massTotal:
                    typeof item.massTotal === 'undefined'
                      ? null
                      : item.massTotal,
                  temperature:
                    typeof item.temperature === 'undefined'
                      ? null
                      : item.temperature,
                };
              })
            )
          );
          break;
      }
    } catch (error) {
      console.error('failed fetch data device', error);
    } finally {
      setLoading(false);
    }
  };

  async function handleFetchDataSeries() {
    try {
      const response = await SeriesSvc.getAggregatedDataByPeriod(
        devcType,
        Math.floor(Number(dataFilter.range.startAt.valueOf()) / 1000),
        Math.floor(Number(dataFilter.range.endAt.valueOf()) / 1000),
        {
          params: {
            aggregatedUnit: dataFilter.interval,
            device: [devcUniqueId],
          },
        }
      );

      let dateStart = moment(dataFilter.range.startAt).unix();
      let dateEnd = moment(dataFilter.range.endAt).unix();

      if (response.data && response.data.data && response.data.data.series) {
        switch (devcType) {
          case 'gateway':
            const currDataLogGateway: IDataLogGatewaySingle[] = [];
            const dataRawGateway = response.data.data.series;

            if (
              !['MINUTE', 'HOUR'].includes(dataFilter.interval) &&
              dataRawGateway.length > 0
            ) {
              const timestampsSorted = dataRawGateway.sort(
                (a: any, b: any) => Number(a.timestamp) - Number(b.timestamp)
              );
              dateStart = Number(timestampsSorted[0].timestamp);
              dateEnd = Number(
                timestampsSorted[dataRawGateway.length - 1].timestamp
              );
            }

            const newTimestampGateway = generateTimestampArray(
              dateStart,
              dateEnd,
              dataFilter.interval === 'MINUTE'
                ? 60
                : dataFilter.interval === 'HOUR'
                ? 3600
                : 86400
            );

            newTimestampGateway.forEach((timestamp) => {
              // Generate Gateway Data
              const isGatewayTimestampSame = dataRawGateway.find(
                (item: any) =>
                  timestamp ===
                  roundTimestampsToNearestMinute(item.timestamp * 1000) / 1000
              );

              if (isGatewayTimestampSame) {
                currDataLogGateway.push({
                  cpuUsage: isGatewayTimestampSame.cpuUsage,
                  memoryFree: isGatewayTimestampSame.memoryFree,
                  spaceFree: isGatewayTimestampSame.spaceFree,
                  id: isGatewayTimestampSame.id,
                  deviceUniqueId: devcUniqueId,
                  timestamp,
                });
              } else {
                currDataLogGateway.push({
                  cpuUsage: undefined,
                  memoryFree: undefined,
                  spaceFree: undefined,
                  id: undefined,
                  deviceUniqueId: devcUniqueId || undefined,
                  timestamp,
                });
              }
            });

            setDataLogGateway({
              fields: [
                {
                  dataIndex: 'timestamp',
                  title: 'Date Time',
                  render: (value: number) => {
                    return moment(value * 1000).format('DD-MM-YY HH:mm:ss');
                  },
                },
                {
                  dataIndex: 'id',
                  title: 'ID',
                  render: (value: any) =>
                    typeof value === 'undefined' ? '-' : value,
                },
                {
                  dataIndex: 'deviceUniqueId',
                  title: 'Device Id',
                  render: (value: any) =>
                    typeof value === 'undefined' ? '-' : value,
                },
                {
                  dataIndex: 'cpuUsage',
                  title: 'CPU Usage',
                  render: (value: any) =>
                    typeof value === 'undefined' ? '-' : value,
                },
                {
                  dataIndex: 'memoryFree',
                  title: 'Memory Free',
                  render: (value: any) =>
                    typeof value === 'undefined' ? '-' : value,
                },
                {
                  dataIndex: 'spaceFree',
                  title: 'Space Free',
                  render: (value: any) =>
                    typeof value === 'undefined' ? '-' : value,
                },
                {
                  dataIndex: 'timestamp',
                  title: 'Timestamp',
                  render: (value: any) =>
                    typeof value === 'undefined' ? '-' : value,
                },
              ],
              data: currDataLogGateway,
            });
            break;

          case 'power':
            const currDataLogPower: IDataLogPowerSingle[] = [];
            const dataRawPower = response.data.data.series;

            if (
              !['MINUTE', 'HOUR'].includes(dataFilter.interval) &&
              dataRawGateway.length > 0
            ) {
              const timestampsSorted = dataRawGateway.sort(
                (a: any, b: any) => Number(a.timestamp) - Number(b.timestamp)
              );
              dateStart = Number(timestampsSorted[0].timestamp);
              dateEnd = Number(
                timestampsSorted[dataRawGateway.length - 1].timestamp
              );
            }

            const newTimestampPower = generateTimestampArray(
              dateStart,
              dateEnd,
              dataFilter.interval === 'MINUTE'
                ? 60
                : dataFilter.interval === 'HOUR'
                ? 3600
                : 86400
            );

            newTimestampPower.forEach((timestamp) => {
              // Generate Power Data
              const isPowerTimestampSame = dataRawPower.find(
                (item: any) =>
                  timestamp ===
                  roundTimestampsToNearestMinute(item.timestamp * 1000) / 1000
              );

              if (isPowerTimestampSame) {
                currDataLogPower.push({
                  onAC: isPowerTimestampSame.onAc,
                  batteryFail: isPowerTimestampSame.batteryFail,
                  batteryVoltage: isPowerTimestampSame.batteryVoltage,
                  id: isPowerTimestampSame.id,
                  deviceUniqueId: devcUniqueId,
                  timestamp,
                });
              } else {
                currDataLogPower.push({
                  onAC: undefined,
                  batteryFail: undefined,
                  batteryVoltage: undefined,
                  id: undefined,
                  deviceUniqueId: devcUniqueId || undefined,
                  timestamp,
                });
              }
            });

            setDataLogPower({
              fields: [
                {
                  dataIndex: 'timestamp',
                  title: 'Date Time',
                  render: (value: number) => {
                    return moment(value * 1000).format('DD-MM-YY HH:mm:ss');
                  },
                },
                {
                  dataIndex: 'id',
                  title: 'ID',
                  render: (value: any) =>
                    typeof value === 'undefined' ? '-' : value,
                },
                {
                  dataIndex: 'deviceUniqueId',
                  title: 'Device Id',
                  render: (value: any) =>
                    typeof value === 'undefined' ? '-' : value,
                },
                {
                  dataIndex: 'onAC',
                  title: 'On AC',
                  render: (value: any) =>
                    typeof value === 'undefined' ? '-' : value,
                },
                {
                  dataIndex: 'batteryFail',
                  title: 'battery Fail',
                  render: (value: any) =>
                    typeof value === 'undefined' ? '-' : value,
                },
                {
                  dataIndex: 'batteryVoltage',
                  title: 'Battery Voltage',
                  render: (value: any) =>
                    typeof value === 'undefined' ? '-' : value,
                },
                {
                  dataIndex: 'timestamp',
                  title: 'Timestamp',
                },
              ],
              data: currDataLogPower,
            });
            break;

          default:
            break;
        }
      }
    } catch (error) {
      console.error('failed get data series');
    }
  }

  useEffect(() => {
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;

    if (devcType === 'gateway' || devcType === 'power') {
      handleFetchDataSeries();
    } else {
      handleFetchDataSummary();
    }
  }, [devcType]);

  const _renderTableDeviceLog = () => {
    switch (devcType) {
      case 'rpm':
        return (
          <Table
            style={{
              padding: 15,
            }}
            size='small'
            loading={isLoading}
            columns={dataLogRPM.fields || []}
            dataSource={dataLogRPM.data || []}
            pagination={{
              pageSizeOptions: undefined,
              pageSize: 1000,
              total: dataLogRPM.data.length,
              showTotal: (total, range) =>
                `${range[0]}-${range[1]} of ${total} items`,
            }}
            scroll={{ y: windowHeight - 380 }}
          />
        );
      case 'gps':
        return (
          <Table
            style={{
              padding: 15,
            }}
            size='small'
            loading={isLoading}
            columns={dataLogGPS.fields || []}
            dataSource={dataLogGPS.data || []}
            pagination={{
              pageSizeOptions: undefined,
              pageSize: 1000,
              total: dataLogGPS.data.length,
              showTotal: (total, range) =>
                `${range[0]}-${range[1]} of ${total} items`,
            }}
            scroll={{ y: windowHeight - 380 }}
          />
        );
      case 'flowmeter':
        return (
          <Table
            style={{
              padding: 15,
            }}
            size='small'
            loading={isLoading}
            columns={dataLogFM.fields || []}
            dataSource={dataLogFM.data || []}
            pagination={{
              pageSizeOptions: undefined,
              pageSize: 1000,
              total: dataLogFM.data.length,
              showTotal: (total, range) =>
                `${range[0]}-${range[1]} of ${total} items`,
            }}
            scroll={{ y: windowHeight - 380 }}
          />
        );
      case 'ae':
        return (
          <Table
            style={{
              padding: 15,
            }}
            size='small'
            loading={isLoading}
            columns={dataLogAE.fields || []}
            dataSource={dataLogAE.data || []}
            pagination={{
              pageSizeOptions: undefined,
              pageSize: 1000,
              total: dataLogAE.data.length,
              showTotal: (total, range) =>
                `${range[0]}-${range[1]} of ${total} items`,
            }}
            scroll={{ y: windowHeight - 380 }}
          />
        );
      case 'gateway':
        return (
          <Table
            style={{
              padding: 15,
            }}
            size='small'
            loading={isLoading}
            columns={dataLogGateway.fields || []}
            dataSource={dataLogGateway.data || []}
            pagination={{
              pageSizeOptions: undefined,
              pageSize: 1000,
              total: dataLogGateway.data.length,
              showTotal: (total, range) =>
                `${range[0]}-${range[1]} of ${total} items`,
            }}
            scroll={{ y: windowHeight - 380 }}
          />
        );
      case 'power':
        return (
          <Table
            style={{
              padding: 15,
            }}
            size='small'
            loading={isLoading}
            columns={dataLogPower.fields || []}
            dataSource={dataLogPower.data || []}
            pagination={{
              pageSizeOptions: undefined,
              pageSize: 1000,
              total: dataLogPower.data.length,
              showTotal: (total, range) =>
                `${range[0]}-${range[1]} of ${total} items`,
            }}
            scroll={{ y: windowHeight - 380 }}
          />
        );

      default:
        break;
    }
  };

  return (
    <React.Fragment>
      <FilterWrapper isLastDay={dataFilter.rangeType == 'last_day'}>
        <CustomForm label='Range Type'>
          <Select
            value={dataFilter.rangeType}
            style={{ width: 140 }}
            disabled={isLoading}
            loading={isLoading}
            onChange={onChangeRangeType}
            options={rangeOptions}
          />
        </CustomForm>
        {dataFilter.rangeType && dataFilter.rangeType !== 'last_day' ? (
          <CustomForm label='Range'>
            <RangePicker
              style={{ width: 350 }}
              disabled={isLoading}
              showTime
              value={[
                moment(dataFilter.range.startAt, dateTimeFormat),
                moment(dataFilter.range.endAt, dateTimeFormat),
              ]}
              format='DD MMM YYYY HH:mm'
              onChange={onChangeDateRange}
            />
          </CustomForm>
        ) : (
          false
        )}
        <CustomForm label='Interval'>
          <Select
            value={dataFilter.interval}
            style={{ width: 100 }}
            disabled={isLoading}
            loading={isLoading}
            onChange={onChangeInterval}
            options={intervalOptions}
          />
        </CustomForm>
        <Button
          loading={isLoading}
          style={{ width: 120 }}
          onClick={handleOnApplyFilter}
          type='primary'
        >
          Apply
        </Button>
      </FilterWrapper>

      {isLoading ? (
        <LoadingWrapper>
          <Spin />
        </LoadingWrapper>
      ) : (
        <>
          {devcType === 'ae' && (
            <GridWrapper row={aesTotal.length}>
              {aesTotal.map((item, index) => (
                <CardInfo
                  key={index}
                  style={{
                    width: '100%',
                    borderColor: 'lightgray',
                    borderWidth: '1px',
                    borderStyle: 'solid',
                  }}
                  label={`AE ${index + 1} Running Hour`}
                  value={toHHMMSS(item)}
                  iconType='time'
                />
              ))}
            </GridWrapper>
          )}

          {devcType === 'gps' && (
            <GridWrapper row={3}>
              <CardInfo
                style={{
                  width: '100%',
                  borderColor: 'lightgray',
                  borderWidth: '1px',
                  borderStyle: 'solid',
                }}
                label='Total Cruise'
                unit='KM'
                value={toKM(gpsSummary.totalCruise)}
                iconType='marker'
              />
              <CardInfo
                style={{
                  width: '100%',
                  borderColor: 'lightgray',
                  borderWidth: '1px',
                  borderStyle: 'solid',
                }}
                label='Average Speed'
                unit='Knot'
                value={toKnot(gpsSummary.averageSpeed)}
                iconType='dashboard'
              />
              <CardInfo
                style={{
                  width: '100%',
                  borderColor: 'lightgray',
                  borderWidth: '1px',
                  borderStyle: 'solid',
                }}
                label='Distance'
                unit='KM'
                value={toKM(gpsSummary.distance)}
                iconType='compass'
              />
            </GridWrapper>
          )}

          {devcType === 'flowmeter' && (
            <GridWrapper row={3}>
              <CardInfo
                style={{
                  width: '100%',
                  borderColor: 'lightgray',
                  borderWidth: '1px',
                  borderStyle: 'solid',
                }}
                label='Flowtime'
                value={toHHMMSS(flowmeterSummary.flowingTime)}
                iconType='marker'
              />
              <CardInfo
                style={{
                  width: '100%',
                  borderColor: 'lightgray',
                  borderWidth: '1px',
                  borderStyle: 'solid',
                }}
                label='Average Flow Rate'
                unit='L/h'
                value={flowmeterSummary.averageFlow.toFixed(2)}
                iconType='dashboard'
              />
              <CardInfo
                style={{
                  width: '100%',
                  borderColor: 'lightgray',
                  borderWidth: '1px',
                  borderStyle: 'solid',
                }}
                label='Total Flow'
                unit='Liters'
                value={flowmeterSummary.totalFlow.toFixed(2)}
                iconType='compass'
              />
            </GridWrapper>
          )}

          {devcType && dataChart && (
            <div
              style={{
                height: 700,
                paddingLeft: 10,
                paddingRight: 10,
              }}
            >
              <HChartDevice
                {...dataChart}
                customPlotOptions={customPlotOptions}
              />
            </div>
          )}

          {_renderTableDeviceLog()}
        </>
      )}
    </React.Fragment>
  );
};

const yAxisFlowmeter = [
  {
    color: hColors.purple,
    text: 'Volume Flow Rate',
  },
  {
    color: hColors.blue,
    text: 'Volume Total',
  },
  {
    color: hColors.pink,
    text: 'Density',
  },
  {
    color: hColors.orange,
    text: 'Mass Flow Rate',
    opposite: true,
  },
  {
    color: hColors.green,
    text: 'Mass Total',
    opposite: true,
  },
  {
    color: hColors.darkBlue,
    text: 'Temperature',
    opposite: true,
  },
];

const yAxisAE = [
  {
    color: hColors.black,
    text: 'Running Second',
  },
];

const yAxisRPM = [
  {
    color: hColors.black,
    text: 'RPM',
  },
];

const yAxisGateway = [
  {
    color: hColors.pink,
    text: 'GHz',
  },
  {
    color: hColors.black,
    text: 'Gb',
    opposite: true,
  },
];

const yAxisGPS = [
  {
    color: hColors.pink,
    text: 'Altitude',
  },
  {
    color: hColors.teal,
    text: 'Km/h',
    opposite: true,
  },
  {
    color: hColors.purple,
    text: 'Meter',
    opposite: true,
  },
];

interface IFilterWrapper {
  isLastDay: boolean;
}

interface IGridWrapper {
  row: number;
}

const GridWrapper = styled.div<IGridWrapper>`
  display: grid;
  padding: 0 20px;
  gap: 15px;
  grid-template-columns: ${(props) =>
    [...Array(props.row).keys()].map(() => '1fr').join(' ')};
`;

const FilterWrapper = styled.div<IFilterWrapper>`
  display: grid;
  flex-direction: row;
  align-items: flex-end;
  gap: 10px;
  grid-template-columns: ${(props) =>
    props.isLastDay ? '140px 350px 120px' : '140px 350px 120px 120px'};
  padding: 10px 20px;
`;

const LoadingWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export default DeviceDetail;
