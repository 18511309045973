import { useEffect } from 'react';
import { createGlobalState } from 'react-hooks-global-state';
import { AppFeatureProps } from '../types/config.type';

export enum EMapTileType {
  satellite = 'satellite',
  vector = 'vector',
}

interface MapConfigProps {
  showOnlineVessel: boolean;
  showOfflineVessel: boolean;
  showLabel: boolean;
  showTrail: boolean;
  trailWidth: number;
  trailOpacity: number;
  mapType: EMapTileType;
  isFullScreen: boolean;
  showModalSettings: boolean;
  selectedApp?: AppFeatureProps;
  selectedTrailLength: string;
  showTableSummary: boolean;
  selectedVessel: Array<any>;
  customCenterCoords?: L.LatLngExpression;
}

const initialState: MapConfigProps = {
  selectedVessel: [],
  showOnlineVessel: true,
  showOfflineVessel: true,
  showLabel: true,
  showTrail: false,
  trailWidth: 3,
  trailOpacity: 1,
  mapType: EMapTileType.satellite,
  isFullScreen: false,
  showModalSettings: false,
  selectedApp: {
    id: 'fms',
    name: 'Fuel Monitoring System',
    description: 'Select Group and Company that you want to trace',
    disabled: false,
  },
  selectedTrailLength: '3',
  showTableSummary: false,
  customCenterCoords: undefined,
};

const { useGlobalState } = createGlobalState(initialState);

export default function useMapConfig() {
  const [showOnlineVessel, setShowOnlineVessel] =
    useGlobalState('showOnlineVessel');
  const [showOfflineVessel, setShowOfflineVessel] =
    useGlobalState('showOfflineVessel');
  const [showLabel, setShowLabel] = useGlobalState('showLabel');
  const [showTrail, setShowTrail] = useGlobalState('showTrail');
  const [trailWidth, setTrailWidth] = useGlobalState('trailWidth');
  const [trailOpacity, setTrailOpacity] = useGlobalState('trailOpacity');
  const [mapType, setMapType] = useGlobalState('mapType');
  const [isFullScreen, setIsFullScreen] = useGlobalState('isFullScreen');
  const [showModalSettings, setShowModalSettings] =
    useGlobalState('showModalSettings');
  const [selectedApp, setSelectedApp] = useGlobalState('selectedApp');
  const [selectedTrailLength, setSelectedTrailLength] = useGlobalState(
    'selectedTrailLength'
  );
  const [selectedVessel, setSelectedVessel] = useGlobalState('selectedVessel');
  const [showTableSummary, setShowTableSummary] =
    useGlobalState('showTableSummary');
  const [customCenterCoords, setCustomCenterCoords] =
    useGlobalState('customCenterCoords');

  const setCurrentApp = (app: AppFeatureProps) => {
    setSelectedApp(app);
    localStorage.setItem('app', JSON.stringify(app));
  };

  const currApp = localStorage.getItem('app');

  useEffect(() => {
    if (currApp) {
      setSelectedApp(JSON.parse(currApp));
    }
  }, [currApp]);

  return {
    showOnlineVessel,
    showOfflineVessel,
    showLabel,
    showTrail,
    trailWidth,
    trailOpacity,
    mapType,
    isFullScreen,
    showModalSettings,
    selectedApp,
    selectedTrailLength,
    showTableSummary,
    selectedVessel,
    customCenterCoords,
    setShowOfflineVessel,
    setShowOnlineVessel,
    setShowLabel,
    setShowTrail,
    setTrailWidth,
    setTrailOpacity,
    setMapType,
    setIsFullScreen,
    setShowModalSettings,
    setSelectedApp,
    setCurrentApp,
    setSelectedTrailLength,
    setShowTableSummary,
    setSelectedVessel,
    setCustomCenterCoords,
  };
}
