import { Button, Spin } from 'antd';
import moment from 'moment';
import styled from 'styled-components';
import StatusTag from '../../../components/StatusTag';
import {
  degreesToRadians,
  latitudeFormat,
  longitudeFormat,
} from '../../../helpers/map-util';
import { useNavigate } from 'react-router-dom';
import { toKnot } from '../../../helpers/dateUtil';
import { LoadingWrapper } from '../../report';
import { AssetType } from '../../../types/asset.type';
import { CustomSwitch } from './MapSettings';
import useAsset from '../../../hooks/useAsset';
import { useEffect, useState } from 'react';
import { baseUrl } from '../../../helpers/api';

interface IProps {
  asset: AssetType;
}

const MapVesselPopup: React.FC<IProps> = ({ asset }) => {
  const navigate = useNavigate();
  const start = moment().startOf('day').valueOf();
  const end = moment().valueOf();
  const [tmpToggle, setTmpToggle] = useState<boolean>(false);
  const { toggleAssetTrail, assets2 } = useAsset();

  function onClickReport() {
    navigate(
      `/report-data-log?start=${start}&end=${end}&mode=period&value=today&aggregatedUnit=HOUR&massId=${asset.massId}&compId=${asset.massCompId}`
    );
  }

  function onClickDashboard() {
    navigate(
      `/dashboard?start=${start}&end=${end}&mode=period&value=today&aggregatedUnit=HOUR&massId=${asset.massId}&compId=${asset.massCompId}`
    );
  }

  function handleToggleTrail() {
    const currAsset = assets2.find((item) => item.massId === asset.massId);
    setTmpToggle(!currAsset?.showTrail);
    toggleAssetTrail({
      massId: asset.massId,
    });
  }

  const convertTimestampToDate = (timestamp?: number) => {
    if (timestamp) {
      return moment(timestamp * 1000).format('DD MMM YYYY HH:mm');
    }

    return '-';
  };

  useEffect(() => {
    setTmpToggle(asset.showTrail);
  }, [asset]);

  return (
    <Container className='custom-popup'>
      {false ? (
        <LoadingWrapper>
          <Spin />
        </LoadingWrapper>
      ) : (
        <>
          <TitleWrapper>
            <Title>{asset.massName}</Title>
            {/* <X style={{ cursor: "pointer" }} onClick={handleClosePopup} /> */}
          </TitleWrapper>
          <img
            src={
              asset.massImage
                ? `${baseUrl}/${asset.massImage}`
                : '/images/empty-image.png'
            }
          />
          <ContentWrapper>
            <FlexBetween>
              <ItemContent
                label='Last Updated'
                value={convertTimestampToDate(asset.lastDataGps?.timestamp)}
              />
              <StatusTag isOnline={asset.isOnline || false} />
            </FlexBetween>

            <Separator />

            <GridContent>
              <ItemContent
                label='Latitude'
                value={latitudeFormat(
                  asset.lastDataGps?.latitude as number,
                  asset.lastDataGps?.longitude as number
                )}
              />
              <ItemContent
                label='Longitude'
                value={longitudeFormat(
                  asset.lastDataGps?.latitude as number,
                  asset.lastDataGps?.longitude as number
                )}
              />
              <ItemContent
                label='Altitude'
                value={(asset.lastDataGps?.altitude as number).toFixed(2)}
              />
            </GridContent>
            <GridContent>
              <ItemContent
                label='Heading'
                value={`${degreesToRadians(
                  asset.lastDataGps?.track as number
                ).toFixed(2)} °`}
              />
              <ItemContent
                label='Avg. Speed'
                value={
                  asset.dataSummary?.gps?.summary.averageSpeed.toFixed(2) +
                  '  Knot'
                }
              />
            </GridContent>

            <Separator />

            <GridSetting custom>
              <ItemSetting>
                <div>Asset Trail</div>
                <CustomSwitch
                  onChange={handleToggleTrail}
                  checked={tmpToggle}
                />
              </ItemSetting>
            </GridSetting>
            <AssetInfo>
              {`Data trail: ${convertTimestampToDate(
                asset.firstDataGps?.timestamp
              )} - ${convertTimestampToDate(asset.lastDataGps?.timestamp)}`}
            </AssetInfo>
          </ContentWrapper>

          <ActionWrapper>
            <GridSetting>
              <Button
                onClick={onClickReport}
                size='small'
                type='primary'
                color='#0957C2'
              >
                View Report
              </Button>
              <Button
                onClick={onClickDashboard}
                size='small'
                type='primary'
                className='btn-accent'
                color='black'
              >
                Dashboard
              </Button>
            </GridSetting>
          </ActionWrapper>
        </>
      )}
    </Container>
  );
};

interface IItemProps {
  label: string;
  value: string;
}

const ItemContent: React.FC<IItemProps> = ({ label, value }) => {
  return (
    <Item>
      <div>{label}</div>
      <p>{value}</p>
    </Item>
  );
};

const AssetInfo = styled.div`
  font-size: 10px;
  opacity: 65%;
`;

const Container = styled.div`
  border-radius: 4px;
  width: 320px;
  overflow: hidden;
  img {
    width: 100%;
    height: 172px;
    object-fit: cover;
  }
`;

const TitleWrapper = styled.div`
  background-color: ${(props) => props.theme.colors.grayLighten_Light};
  padding: 12px 20px;
  display: flex;
  align-items: center;
`;

const Title = styled.div`
  font-size: 16px;
  font-weight: 600;
`;

const ContentWrapper = styled.div`
  padding: 10px;
`;

const ActionWrapper = styled.div`
  padding: 10px 20px 10px 20px;
  background-color: ${(props) => props.theme.colors.grayLighten_Light};
`;

const FlexBetween = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const GridContent = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  align-items: center;
  justify-content: space-between;
`;

const Separator = styled.div`
  height: 1px;
  background-color: ${(props) => props.theme.colors.grayLighten_Default};
  width: 100%;
  margin-top: 5px;
  margin-bottom: 5px;
`;

const Item = styled.div`
  font-size: 12px;
  margin-bottom: 5px;
  div {
    color: ${(props) => props.theme.colors.grayDarken_Default};
    margin-bottom: 5px;
  }
  p {
    color: ${(props) => props.theme.colors.black};
    margin: 0;
    font-size: 12px;
  }
`;

const GridSetting = styled.div<{ custom?: boolean }>`
  display: grid;
  align-items: center;
  grid-template-columns: ${(props) => (props.custom ? '1fr' : '1fr 1fr')};
  gap: 20px;
`;

const ItemSetting = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export default MapVesselPopup;
