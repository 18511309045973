import { Calendar, DatePicker, Popover, Select } from 'antd';
import moment, { Moment, unitOfTime } from 'moment';
import { useMemo } from 'react';
import styled from 'styled-components';
import { CalendarOutlined, SwapRightOutlined } from '@ant-design/icons';

interface IProps {
  custom: boolean;
  startAt: Moment | Date | string;
  endAt: Moment | Date | string;
  disabled?: boolean;
  onChange: (e: any) => void;
  onChangeCustom: (e: any, type: string) => void;
}

const { RangePicker } = DatePicker;
const dateTimeFormat = 'DD-MM-YYYY HH:mm';

const DateRangePicker: React.FC<IProps> = (props) => {
  const hours = useMemo(() => {
    return [...Array(24).keys()].map((item) => ({
      label: item,
      value: item,
    }));
  }, []);

  const minutes = useMemo(() => {
    return [...Array(60).keys()].map((item) => ({
      label: item,
      value: item,
    }));
  }, []);

  function onChangeCalendar(e: Moment, type: string) {
    props.onChangeCustom(e, type);
  }

  function onChangeTime(value: number, type: string, timeType: unitOfTime.All) {
    if (type === 'start') {
      // setStartTime(moment(startTime).set(timeType, value));
      const newStartDate = moment(props.startAt).set(timeType, value);
      props.onChangeCustom(newStartDate, type);
    } else {
      // setEndTime(moment(endTime).set(timeType, value));
      const newEndDate = moment(props.endAt).set(timeType, value);
      props.onChangeCustom(newEndDate, type);
    }
  }

  if (!props.custom) {
    return (
      <RangePicker
        style={{ width: '100%' }}
        disabled={props.disabled}
        showTime
        value={[
          moment(props.startAt, dateTimeFormat),
          moment(props.endAt, dateTimeFormat),
        ]}
        format='DD MMM YYYY HH:mm'
        onChange={props.onChange}
      />
    );
  }

  const content = (type: string) => {
    return (
      <>
        <div style={{ width: 250 }}>
          <Calendar
            value={
              type === 'start' ? moment(props.startAt) : moment(props.endAt)
            }
            fullscreen={false}
            onChange={(e) => onChangeCalendar(e, type)}
          />
        </div>

        <WrapperTime>
          <div>
            <span>Hour</span>
            <Select
              style={{ width: '100%' }}
              options={hours}
              value={
                type === 'start'
                  ? moment(props.startAt).hours()
                  : moment(props.endAt).hours()
              }
              onSelect={(e) => onChangeTime(e, type, 'hours')}
            />
          </div>
          <span>:</span>

          <div>
            <span>Minute</span>
            <Select
              style={{ width: '100%' }}
              options={minutes}
              value={
                type === 'start'
                  ? moment(props.startAt).minute()
                  : moment(props.endAt).minute()
              }
              onSelect={(e) => onChangeTime(e, type, 'minutes')}
            />
          </div>
        </WrapperTime>
      </>
    );
  };

  return (
    <ContainerRangePicker>
      <Popover
        popupVisible={props.disabled}
        placement='bottom'
        title={'Date Start'}
        content={() => content('start')}
        trigger='click'
      >
        <CalendarInput disabled={props.disabled}>
          {moment(props.startAt).format(dateTimeFormat)}
          <CalendarOutlined style={{ color: '#d9d9d9' }} />
        </CalendarInput>
      </Popover>

      <SwapRightOutlined />

      <Popover
        popupVisible={props.disabled}
        placement='bottom'
        title={'Date End'}
        content={() => content('end')}
        trigger='click'
      >
        <CalendarInput disabled={props.disabled}>
          {moment(props.endAt).format(dateTimeFormat)}
          <CalendarOutlined style={{ color: '#d9d9d9' }} />
        </CalendarInput>
      </Popover>
    </ContainerRangePicker>
  );
};

const WrapperTime = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: 1fr auto 1fr;
  gap: 10px;
`;

const ContainerRangePicker = styled.div`
  display: grid;
  align-items: center;
  gap: 10px;
  width: 100%;
  grid-template-columns: 1fr auto 1fr;
`;

interface ICalendarInput {
  disabled?: boolean;
}

const CalendarInput = styled.div<ICalendarInput>`
  border: 1px solid #d9d9d9;
  padding: 0 11px;
  height: 40px;
  border-radius: 5px;
  background-color: ${(props) => (props.disabled ? 'lightgray' : 'white')};
  color: ${(props) => (props.disabled ? 'gray' : 'black')};
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export default DateRangePicker;
