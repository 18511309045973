import moment, { Moment } from 'moment';
import { intervalOptions, rangeOptions } from '../helpers/map';

export type ConfigState = {
  sidebarCollapsed: boolean;
  counterBatch: number;
};

export interface BaseResponseProps<T> {
  code: string;
  message: string;
  payload: T;
}

export interface BaseResponsePaginationProps<T> {
  code: string;
  message: string;
  payload: {
    count: number;
    prev: string;
    next: string;
    results: Array<T>;
  };
}

export interface DefaultQuery {
  limit?: number;
  offset?: number;
}

export const initialConfig: ConfigState = {
  sidebarCollapsed: true,
  counterBatch: 0,
};

export interface DefaultPagination {
  page: number;
  itemPerPage: number;
  totalPage: number;
  data: number;
  totalData: number;
}

export interface StatusErrorProps {
  error: number;
  errorCode: number;
  message: string;
}

export interface DefaultResponseListProps<T> {
  data: T[];
  fields: any;
  pagination: DefaultPagination;
  status: StatusErrorProps;
}

export interface OptionProps {
  value: string;
  label: string;
}

export interface DataFilterProps {
  compId: string;
  massId: string;
  rangeType: string;
  range: {
    startAt: Moment | Date | string;
    endAt: Moment | Date | string;
  };
  interval: string;
}

export const initialDataFilter: DataFilterProps = {
  compId: '',
  massId: '',
  rangeType: rangeOptions[4].value,
  range: {
    startAt: moment().set({ hour: 0, minute: 0, second: 0 }),
    endAt: moment(new Date()),
  },
  interval: intervalOptions[0].value,
};

export interface AppFeatureProps {
  id: string;
  name: string;
  description: string;
  disabled: boolean;
  // icon: JSX.Element;
}
