import { AxiosResponse } from 'axios';
import { httpRequest } from '../helpers/api';
import { SummaryHttpResponse } from '../types/summary.type';

const SummarySvc = {
  getSummary(dto: {
    start: string;
    end: string;
    params?: any;
  }): Promise<AxiosResponse<SummaryHttpResponse>> {
    return httpRequest.get(
      `/summary/aggregated/period/${dto.start}/${dto.end}`,
      { params: dto.params }
    );
  },
};

export default SummarySvc;
