import styled from 'styled-components';
import useAsset from '../hooks/useAsset';

interface IProps {
  isHome?: boolean;
}

const ToggleDataSource: React.FC<IProps> = (props) => {
  const { currentDataSource, setCurrentDataSource } = useAsset();

  return (
    <SelectDataSourceWrapper isHome={props.isHome}>
      <DataSourceItem
        active={currentDataSource === 'gsm'}
        onClick={() => setCurrentDataSource('gsm')}
      >
        <img
          alt='icon-gsm'
          src={
            currentDataSource === 'gsm'
              ? '/images/icon-gsm-active.svg'
              : '/images/icon-gsm.svg'
          }
        />
        <span>GSM</span>
      </DataSourceItem>
      <DataSourceItem
        active={currentDataSource === 'satellite'}
        onClick={() => setCurrentDataSource('satellite')}
      >
        <img
          alt='icon-satellite'
          src={
            currentDataSource === 'satellite'
              ? '/images/icon-satellite-active.svg'
              : '/images/icon-satellite.svg'
          }
        />
        <span>Satellite</span>
      </DataSourceItem>
    </SelectDataSourceWrapper>
  );
};

interface IDataSourceWrapper {
  isHome?: boolean;
}

export const SelectDataSourceWrapper = styled.div<IDataSourceWrapper>`
  position: ${(props) => (props.isHome ? 'absolute' : 'relative')};
  top: ${(props) => (props.isHome ? '10px' : '0')};
  right: ${(props) => (props.isHome ? '340px' : '0')};
  border-radius: 100px;
  border: 1px solid #e8ecf3;
  background: #d2d8e1;
  gap: 4px;
  padding: 4px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: ${(props) => (props.isHome ? '29px' : '38px')};
  width: 240px;
  z-index: 998;
  cursor: pointer;
`;

interface IDataSourceItem {
  active: boolean;
}

export const DataSourceItem = styled.div<IDataSourceItem>`
  border: ${(props) => (props.active ? '1px solid #b7bec9' : 'none')};
  background-color: ${(props) => (props.active ? 'white' : 'none')};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100px;
  cursor: pointer;
  span {
    color: ${(props) => (props.active ? '#0957C2' : 'black')};
    margin-left: 4px;
  }
`;

export default ToggleDataSource;
