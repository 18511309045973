import styled from "styled-components";

interface Props {
  isOnline: boolean;
}

const StatusTag: React.FC<Props> = (props) => {
  return (
    <CustomTag {...props}>{props.isOnline ? "Online" : "Offline"}</CustomTag>
  );
};

const CustomTag = styled.span<Props>`
  border-radius: 5px;
  padding: 0 4px;
  background-color: ${(props) =>
    props.isOnline
      ? props.theme.colors.success_Light
      : props.theme.colors.danger_Light};
  color: ${(props) =>
    props.isOnline
      ? props.theme.colors.success_Dark
      : props.theme.colors.danger_Default};
  font-size: ${(props) => props.theme.fontSize.body2};
  font-weight: ${(props) => props.theme.fontWeight.semiBold};
`;

export default StatusTag;
