import moment from 'moment';
import 'moment-timezone';
import AssetSvc from '../services/AssetSvc';
import SummarySvc from '../services/SummarySvc';
import { AssetType, initialAssetType } from '../types/asset.type';
import { createGlobalState } from 'react-hooks-global-state';
import { dateUtil, roundTimestampsToNearestMinute } from '../helpers/dateUtil';
import { ISummary, ISummarySingleDevice } from '../types/summary.type';
import { emsAssets, singleEngineAssets } from '../helpers/constant';
import axios from 'axios';
import { useMemo } from 'react';
import useFilter from './useFilter';

moment.tz.setDefault('Asia/Jakarta');

interface IParamsSummary {
  start?: string;
  end?: string;
  devcMassId?: string;
  devcType?: string[];
  aggregatedUnit?: string;
  aggregatedLength?: number;
}

interface AssetProps {
  isLoadingAsset: boolean;
  assets: { [T: string]: AssetType };
  assets2: AssetType[];
  asset: AssetType;
  isEMS: boolean;
  isSingleEngine: boolean;
  isBunker: boolean;
  assetObject: { [T: string]: AssetType };
  assetGPSInfo: {
    label: string;
    value: string;
    iconType: string;
    unit?: string;
  }[];
  combinedData: any;
  selectedAssetId: string;
  isLoadingAssetSummary: boolean;
  currentDataSource: 'satellite' | 'gsm';
}

const initialState: AssetProps = {
  isLoadingAsset: false,
  assets: {},
  assets2: [],
  asset: { ...initialAssetType },
  isEMS: false,
  isSingleEngine: false,
  isBunker: false,
  assetObject: {},
  assetGPSInfo: [],
  combinedData: {},
  selectedAssetId: '',
  isLoadingAssetSummary: false,
  currentDataSource: 'gsm',
};

const { useGlobalState } = createGlobalState(initialState);

export default function useAsset() {
  const [isLoadingAsset, setIsLoadingAsset] = useGlobalState('isLoadingAsset');
  const [isLoadingAssetSummary, setIsLoadingAssetSummary] = useGlobalState(
    'isLoadingAssetSummary'
  );
  const [assets, setAssets] = useGlobalState('assets');
  const [assets2, setAssets2] = useGlobalState('assets2');
  const [asset, setAsset] = useGlobalState('asset');
  const [isEMS, setIsEMS] = useGlobalState('isEMS');
  const [isSingleEngine, setIsSingleEngine] = useGlobalState('isSingleEngine');
  const [isBunker, setIsBunker] = useGlobalState('isBunker');
  const [assetObject, setAssetObject] = useGlobalState('assetObject');
  const [combinedData, setCombinedData] = useGlobalState('combinedData');
  const [assetGPSInfo, setAssetGPSInfo] = useGlobalState('assetGPSInfo');
  const [selectedAssetId, setSelectedAssetId] =
    useGlobalState('selectedAssetId');
  const [currentDataSource, setCurrentDataSource] =
    useGlobalState('currentDataSource');

  async function fetchAssets() {
    setIsLoadingAsset(true);
    try {
      const resAssets = await AssetSvc.getAssets();

      if (resAssets.data && resAssets.data.data) {
        setAssets2(resAssets.data.data);
      }
    } catch (error: any) {
      console.error('failed get assets: ', error);
    } finally {
      setIsLoadingAsset(false);
    }
  }

  function generateSeriesParam(params?: IParamsSummary) {
    let start = moment().startOf('day').valueOf();
    let end = moment().valueOf();

    if (params?.start) {
      start = Number(params?.start);
    }

    if (params?.end) {
      end = Number(params?.end);
    }

    const aggregatedUnit = params?.aggregatedUnit || 'MINUTE';

    const seriesParam = dateUtil.autoMaxData(
      dateUtil.msToS({
        range: {
          start,
          end,
        },
        aggregatedUnit,
      })
    );

    return { start, end, seriesParam };
  }

  async function fetchSummary(
    params?: IParamsSummary
  ): Promise<ISummary | undefined> {
    try {
      const { start, end, seriesParam } = generateSeriesParam(params);

      const response = await SummarySvc.getSummary({
        start: Math.round(start / 1000).toString(),
        end: Math.round(end / 1000).toString(),
        params: {
          devcMassId: params?.devcMassId,
          devcType: params?.devcType || ['gps', 'rpm', 'flowmeter', 'ae'],
          aggregatedUnit: params?.aggregatedUnit || seriesParam.aggregatedUnit,
          aggregatedLength:
            params?.aggregatedLength || seriesParam.aggregatedLength,
          timezone: seriesParam.timezone,
        },
      });

      if (
        !response.data.data ||
        (typeof response.data.data === 'object' &&
          Object.keys(response.data.data as any).length === 0)
      ) {
        return undefined;
      }

      return {
        gps: response.data.data.gps
          ? {
              summary: response.data.data.gps.summary,
              data: Object.values(response.data.data.gps.data),
              timestamps: Object.keys(response.data.data.gps.data).map(
                (item) =>
                  roundTimestampsToNearestMinute(Number(item) * 1000) / 1000
              ),
            }
          : undefined,
        rpm: response.data.data.rpm
          ? {
              summary: response.data.data.rpm.summary,
              data: Object.values(response.data.data.rpm.data),
              timestamps: Object.keys(response.data.data.rpm.data).map(
                (item) =>
                  roundTimestampsToNearestMinute(Number(item) * 1000) / 1000
              ),
            }
          : undefined,
        flowmeter: response.data.data.flowmeter
          ? {
              summary: response.data.data.flowmeter.summary,
              data: Object.values(response.data.data.flowmeter.data),
              timestamps: Object.keys(response.data.data.flowmeter.data).map(
                (item) =>
                  roundTimestampsToNearestMinute(Number(item) * 1000) / 1000
              ),
            }
          : undefined,
        ae:
          response.data.data.ae && response.data.data.ae.summary
            ? {
                summary: response.data.data.ae.summary,
                data: Object.values(response.data.data.ae.data),
                timestamps: Object.keys(response.data.data.ae.data).map(
                  (item) =>
                    roundTimestampsToNearestMinute(Number(item) * 1000) / 1000
                ),
              }
            : undefined,
      };
    } catch (error) {
      console.error('failed fetch summary', error);
    }
  }

  async function fetchSummarySingleDevice(params?: IParamsSummary) {
    try {
      const { start, end, seriesParam } = generateSeriesParam(params);

      const response = await SummarySvc.getSummary({
        start: Math.floor(start / 1000).toString(),
        end: Math.floor(end / 1000).toString(),
        params: {
          devcMassId: params?.devcMassId,
          devcType: params?.devcType || ['gps', 'rpm', 'flowmeter', 'ae'],
          aggregatedUnit: params?.aggregatedUnit || seriesParam.aggregatedUnit,
          aggregatedLength:
            params?.aggregatedLength || seriesParam.aggregatedLength,
          timezone: seriesParam.timezone,
        },
      });

      if (
        !response.data.data ||
        (typeof response.data.data === 'object' &&
          (response.data.data as any).length === 0)
      ) {
        return undefined;
      }

      if (
        response.data.data.gps &&
        Object.keys(response.data.data.gps.data).length > 0
      ) {
        return {
          summary: response.data.data.gps.summary,
          data: Object.values(response.data.data.gps.data),
          timestamps: Object.keys(response.data.data.gps.data),
        };
      } else if (
        response.data.data.rpm?.summary &&
        Object.keys(response.data.data.rpm.summary).length > 0
      ) {
        return {
          summary: response.data.data.rpm.summary,
          data: Object.values(response.data.data.rpm.data),
          timestamps: Object.keys(response.data.data.rpm.data),
        };
      }

      if (response.data.data.flowmeter?.data) {
        return {
          summary: response.data.data.flowmeter.summary,
          data: Object.values(response.data.data.flowmeter.data),
          timestamps: Object.keys(response.data.data.flowmeter.data),
        };
      }

      if (response.data.data.ae?.summary) {
        return {
          summary: response.data.data.ae.summary,
          data: Object.values(response.data.data.ae.data),
          timestamps: Object.keys(response.data.data.ae.data),
        };
      }
    } catch (error) {
      console.error('failed fetch single summary', error);
    }
  }

  async function fetchAssetsWithLastData(customParams?: IParamsSummary) {
    setIsLoadingAssetSummary(true);
    try {
      const resAssets = await AssetSvc.getAssets();
      const newResAssets: AssetType[] = [];

      let resAssetsMapped: AssetType[] = resAssets.data.data
        ? resAssets.data.data
        : [];
      let currMassId = customParams?.devcMassId;
      console.log('1 customParams', customParams);
      if (currMassId && currMassId === 'initial') {
        resAssetsMapped = [resAssets.data.data[0]];
      } else if (currMassId) {
        resAssetsMapped = resAssets.data.data.filter(
          (item) => item.massId === currMassId
        );
      }
      console.log('2 customParams', resAssetsMapped);
      if (resAssetsMapped.length > 0) {
        for (let i = 0; i < resAssetsMapped.length; i++) {
          const data = resAssetsMapped[i];

          const resDataSummary: ISummary | undefined = await fetchSummary({
            ...customParams,
            devcMassId:
              customParams?.devcMassId && customParams?.devcMassId !== 'initial'
                ? customParams?.devcMassId
                : data.massId,
          });

          newResAssets.push({
            ...data,
            dataSummary: !resDataSummary ? undefined : { ...resDataSummary },
            isOnline: !resDataSummary
              ? false
              : resDataSummary.gps && resDataSummary.gps.data.length === 0
              ? false
              : moment().valueOf() -
                  (resDataSummary.gps
                    ? resDataSummary.gps.data[
                        resDataSummary.gps.data.length - 1
                      ].timestamp
                    : 1) *
                    1000 >
                600000
              ? false
              : true,
            checked: !resDataSummary ? false : true,
            lastDataGps:
              !resDataSummary || !resDataSummary.gps?.data
                ? undefined
                : resDataSummary.gps.data[resDataSummary.gps.data.length - 1],
            firstDataGps:
              !resDataSummary || !resDataSummary.gps?.data
                ? undefined
                : resDataSummary.gps.data[0],
            showTrail: false,
            isEMS: emsAssets.includes(data.massId) || false,
            isSingleEngine:
              singleEngineAssets.includes(data.massId) ||
              resDataSummary?.rpm?.summary.singleEngine ||
              false,
          });
        }

        if (currMassId) {
          setAssets2(
            resAssets.data.data.map((item) => {
              if (item.massId === newResAssets[0].massId) {
                return {
                  ...newResAssets[0],
                };
              }

              return item;
            })
          );
        } else {
          setAssets2(newResAssets);
        }
      }
    } catch (error: any) {
      console.error('failed get assets & summary: ', error);
    } finally {
      setIsLoadingAssetSummary(false);
    }
  }

  function toggleAssetTrail(params: { all?: boolean; massId?: string }) {
    if (params.all) {
      setAssets2((oldVal) =>
        oldVal.map((item) => ({ ...item, showTrail: !item.showTrail }))
      );
    } else if (params.massId) {
      setAssets2((oldValue) =>
        oldValue.map((item) =>
          item.massId === params.massId
            ? { ...item, showTrail: !item.showTrail }
            : { ...item }
        )
      );
    }
  }

  const { dataFilter } = useFilter();

  const currentAsset = useMemo(() => {
    return assets2.find((item) => item.massId === dataFilter.massId);
  }, [dataFilter.massId, assets2]);

  const dataCompletion = useMemo(() => {
    if (currentAsset && currentAsset.dataSummary) {
      const timestampGPS = currentAsset.dataSummary.gps?.timestamps;
      const timestampRPM = currentAsset.dataSummary.rpm?.timestamps;
      const timestampFM = currentAsset.dataSummary.flowmeter?.timestamps;
      const timestampAE = currentAsset.dataSummary.ae?.timestamps;
      return {
        gps: {
          count: timestampGPS?.length,
          lastTimestamp:
            timestampGPS && timestampGPS.length > 0
              ? timestampGPS[timestampGPS.length - 1] * 1000
              : 0,
        },
        rpm: {
          count: timestampRPM?.length,
          lastTimestamp:
            timestampRPM && timestampRPM.length > 0
              ? timestampRPM[timestampRPM.length - 1] * 1000
              : 0,
        },
        flowmeter: {
          count: timestampFM?.length || 0,
          lastTimestamp: timestampFM
            ? timestampFM[timestampFM.length - 1] * 1000
            : 0,
        },
        ae: {
          count: timestampAE?.length,
          lastTimestamp:
            timestampAE && timestampAE.length > 0
              ? timestampAE[timestampAE.length - 1] * 1000
              : 0,
        },
      };
    }
  }, [currentAsset]);

  return {
    asset,
    assets,
    selectedAssetId,
    isLoadingAsset,
    isEMS,
    isBunker,
    assetObject,
    combinedData,
    assetGPSInfo,
    fetchAssets,
    setCombinedData,
    setAssetObject,
    setIsLoadingAsset,
    setAssets,
    setAsset,
    setIsEMS,
    setIsBunker,
    setAssetGPSInfo,
    setIsSingleEngine,
    isSingleEngine,
    setSelectedAssetId,
    fetchSummary,
    fetchSummarySingleDevice,
    fetchAssetsWithLastData,
    assets2,
    setAssets2,
    toggleAssetTrail,
    isLoadingAssetSummary,
    setCurrentDataSource,
    currentDataSource,
    generateSeriesParam,
    currentAsset,
    dataCompletion,
  };
}
