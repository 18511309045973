import React, { Suspense } from 'react';
import useAuth from '../hooks/useAuth';
import { Navigate, Outlet } from 'react-router-dom';
import { Spin } from 'antd';

const PublicRestrictedRoute: React.FC = () => {
  const { isLoggedIn, loginData, logout } = useAuth();

  if (!isLoggedIn) {
    return <Navigate to='/login' replace />;
  }

  if (loginData.user === '' || loginData.user === undefined) {
    logout();
    return <Navigate to='/login' replace />;
  }

  return (
    <React.Fragment>
      <Suspense
        fallback={
          <div className='full-spin'>
            <Spin spinning={true} />
          </div>
        }
      >
        <Outlet />
      </Suspense>
    </React.Fragment>
  );
};
export default PublicRestrictedRoute;
