import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import formatcoords from 'formatcoords';
import BaseMap from '../../home/components/BaseMap';
import useAsset from '../../../hooks/useAsset';
import axios from 'axios';
import { IGPSData } from '../../../types/summary.type';
import moment from 'moment';

interface IProps {
  lastDataGPS: IGPSData;
  firstDataGPS: IGPSData;
  isDailyReport?: boolean;
  currentPath: number[][];
  massId: string;
}

const ReportBodyVessel = React.forwardRef<any, IProps>((props, ref) => {
  const { lastDataGPS, firstDataGPS, isDailyReport, massId } = props;
  const { assets2 } = useAsset();

  const start = useMemo(() => {
    return {
      position: formatcoords(
        firstDataGPS.latitude,
        firstDataGPS.longitude
      ).format('DD MM ss X', {
        latLonSeparator: ', ',
        decimalPlaces: 0,
      }),
      datetime: moment(firstDataGPS.timestamp * 1000).format(
        'DD MMM YYYY, HH:mm'
      ),
      speed: `${firstDataGPS.speed} Knot`,
    };
  }, [firstDataGPS]);

  const end = useMemo(() => {
    return {
      position: formatcoords(
        lastDataGPS.latitude,
        lastDataGPS.longitude
      ).format('DD MM ss X', {
        latLonSeparator: ', ',
        decimalPlaces: 0,
      }),
      datetime: moment(lastDataGPS.timestamp * 1000).format(
        'DD MMM YYYY, HH:mm'
      ),
      speed: `${lastDataGPS.speed.toFixed(2)} Knot`,
    };
  }, [lastDataGPS]);

  useEffect(() => {
    if (isDailyReport) {
      const renderMapImage = async () => {
        const mapLeft = await generateImage();
        const mapRight = await generateImage(true);
        setMapImages({
          mapLeft,
          mapRight,
        });
      };

      renderMapImage();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDailyReport]);

  const [mapImages, setMapImages] = useState({
    mapLeft: undefined,
    mapRight: undefined,
  });

  async function generateImage(withoutPath?: boolean) {
    try {
      const zoom = withoutPath ? 8 : 11;
      const lat = lastDataGPS.latitude;
      const lng = lastDataGPS.longitude;
      const path = `color:0xff0000ff|weight:5|${props.currentPath.join('|')}`;
      const constructUrl = withoutPath
        ? `https://maps.googleapis.com/maps/api/staticmap?center=${lat},${lng}&zoom=${zoom}&size=535x300&markers=color:red%7C${lat},${lng}&maptype=satellite&key=${process.env.REACT_APP_GMAPS_API_KEY}`
        : `https://maps.googleapis.com/maps/api/staticmap?size=535x300&markers=color:red%7C${lat},${lng}&path=color:yellow|weight:3|${path}&maptype=satellite&key=${process.env.REACT_APP_GMAPS_API_KEY}&sensor=false`;
      const res = await axios({
        method: 'post',
        url: 'https://beta.ramus.id/api-signature/generate-signature',
        data: {
          url: constructUrl,
          privateKey: process.env.REACT_APP_GMAPS_PRIVATE_KEY,
        },
      });
      return res.data.result;
    } catch (error: any) {
      console.error('failed generate static map', error);
    }
  }

  const dataMaps = useMemo(
    () =>
      assets2
        .filter((item) => item.dataSummary && item.massId === massId)
        .map((item) => ({
          ...item,
          showTrail: true,
        })),
    [assets2, massId]
  );

  return (
    <>
      <div style={{ marginBottom: 20, background: 'white' }}>
        <table className='custom-table-daily-report'>
          <tbody>
            <tr>
              <th></th>
              <td>Position</td>
              <td>Time</td>
              <td>Speed</td>
            </tr>
            <tr>
              <th>Start</th>
              <td>{start.position}</td>
              <td>{start.datetime}</td>
              <td>{start.speed}</td>
            </tr>
            <tr>
              <th>End</th>
              <td>{end.position}</td>
              <td>{end.datetime}</td>
              <td>{end.speed}</td>
            </tr>
          </tbody>
        </table>
      </div>
      {isDailyReport ? (
        <DailyReportMapWrapper>
          <img
            alt='map-left'
            style={{ height: 300 }}
            id='map-image'
            src={mapImages.mapLeft}
          />
          <img
            alt='map-right'
            style={{ height: 300 }}
            id='map-image'
            src={mapImages.mapRight}
          />
        </DailyReportMapWrapper>
      ) : (
        <MapCard style={{ height: '75vh' }}>
          <BaseMap
            ref={ref}
            isFullPage={false}
            showSummary={false}
            dataMaps={dataMaps}
          />
        </MapCard>
      )}
    </>
  );
});

export default ReportBodyVessel;

const DailyReportMapWrapper = styled.div`
  display: grid;
  align-items: center;
  gap: 10px;
  grid-template-columns: 1fr 1fr;
  img {
    width: 100%;
  }
`;

const MapCard = styled.div`
  position: relative;
  border-radius: 8px;
  overflow: hidden;
  height: 60vh;
`;
