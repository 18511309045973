import { Button, Spin, Table } from 'antd';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import CardInfo from '../dashboard/components/CardInfo';
import useAsset from '../../hooks/useAsset';
import {
  generateTimestampArray,
  latToDMS,
  longToDMS,
  roundTimestampsToNearestMinute,
  toHHMMSS,
  toKM,
  toKnot,
} from '../../helpers/dateUtil';
import FilterDashboard from '../dashboard/components/FilterDashboard';
import { useLocation, useSearchParams } from 'react-router-dom';
import useFilter from '../../hooks/useFilter';
import { ContainerLoading, LoadingWrapper } from '.';
import {
  InfoCircleOutlined,
  MinusOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import moment from 'moment';
import useCompany from '../../hooks/useCompany';
import { intervalOptions } from '../../helpers/map';
import * as XLSX from 'xlsx';
import { degreesToRadians } from '../../helpers/map-util';
import DataCompletionInfo from '../../components/DataCompletionInfo';

const windowHeight = window.innerHeight;

const ReportLog: React.FC = () => {
  const [summary, setSummary] = useState<any[]>([]);
  const {
    assets2,
    fetchAssets,
    fetchAssetsWithLastData,
    isLoadingAssetSummary,
    dataCompletion,
  } = useAsset();
  const { dataFilter } = useFilter();

  const currAsset = useMemo(() => {
    return assets2.find((item) => item.massId === dataFilter.massId);
  }, [dataFilter, assets2]);

  const { activeCompany, fetchCompanyData } = useCompany();
  const currMassId = currAsset ? currAsset.massId : '';
  const isSingleEngine = currAsset?.isSingleEngine || false;
  // const isEMS = currAsset?.isEMS || false;
  const [searchParams, setSearchParams] = useSearchParams();

  const dataFetchedCompRef = useRef(false);
  const dataFetchedRef = useRef(false);

  useEffect(() => {
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;

    fetchAssets();
    if (activeCompany) {
      fetchCompanyData(activeCompany.compId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeCompany]);

  useEffect(() => {
    if (dataFetchedCompRef.current) return;
    dataFetchedCompRef.current = true;

    const searchParams = new URLSearchParams(document.location.search);
    const queryMassId = searchParams.get('massId');
    const queryStart = searchParams.get('start');
    const queryEnd = searchParams.get('end');
    const queryAggregatedUnit = searchParams.get('aggregatedUnit');

    fetchAssetsWithLastData({
      aggregatedUnit: queryAggregatedUnit
        ? queryAggregatedUnit
        : intervalOptions[0].value,
      start: queryStart ? queryStart : undefined,
      end: queryEnd ? queryEnd : undefined,
      devcMassId: queryMassId
        ? queryMassId
        : dataFilter.massId
        ? dataFilter.massId
        : undefined,
      aggregatedLength: 1,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [dataTables, setDataTables] = useState<any>([]);
  const { isLoadingFilter } = useFilter();
  const [unit, setUnit] = useState<'KM' | 'NM' | 'MILES'>('KM');
  const [speed, setSpeed] = useState<'KNOT' | 'KM/H'>('KNOT');
  const [flowUnit, setFlowUnit] = useState<'lph' | 'lpm'>('lph');

  const columns: any = [
    {
      title: 'Date & Time',
      dataIndex: 'dateTime',
      key: 'dateTime',
      width: 180,
      fixed: 'left',
      align: 'center',
    },
    {
      title: 'Coordinate',
      dataIndex: 'coordinate',
      key: 'coordinate',
      width: 210,
      align: 'center',
      render: (coordinate: string) => {
        if (!coordinate || typeof coordinate === 'undefined') {
          return '-';
        }
        const currValue = coordinate.split(' ');
        if (currValue.length === 2) {
          return `${latToDMS(currValue[0])}, ${longToDMS(currValue[1])}`;
        } else {
          return '-';
        }
      },
    },
    {
      title: 'Heading',
      dataIndex: 'heading',
      key: 'heading',
      width: 80,
      align: 'center',
      render: (value: any) =>
        typeof value === 'undefined'
          ? '-'
          : `${degreesToRadians(Number(value)).toFixed(2)} °`,
    },
    {
      title: 'Speed (knot)',
      dataIndex: 'speed',
      key: 'speed',
      width: 80,
      align: 'center',
      render: (value: any) =>
        typeof value === 'undefined' ? '-' : parseFloat(value).toFixed(2),
    },
    {
      title: 'Distance (KM)',
      dataIndex: 'distance',
      key: 'distance',
      width: 80,
      align: 'center',
      render: (value: any) =>
        typeof value === 'undefined' ? '-' : parseFloat(value).toFixed(2),
    },
    {
      title: isSingleEngine ? 'MAIN ENGINE' : 'PORT',
      dataIndex: 'port',
      key: 'port',
      align: 'center',
      children: [
        {
          title: 'RPM (RPM)',
          dataIndex: 'portRPM',
          key: 'portRPM',
          width: 100,
          align: 'center',
          render: (value: any) =>
            typeof value === 'undefined' ? '-' : parseFloat(value).toFixed(2),
        },
        {
          title: 'Run. Hours (hh:mm:ss)',
          dataIndex: 'portRunningHour',
          key: 'portRunningHour',
          width: 100,
          align: 'center',
          render: (value: any) => (typeof value === 'undefined' ? '-' : value),
        },
        {
          title: 'Fuel Cons. (Liter)',
          dataIndex: 'portFuelCons',
          key: 'portFuelCons',
          width: 100,
          align: 'center',
          render: (value: any, record: any) => {
            return typeof value === 'undefined'
              ? '-'
              : parseFloat(value).toFixed(2);
          },
        },
        {
          title: 'IN Flow (L/h)',
          dataIndex: 'portInFlow',
          key: 'portInFlow',
          width: 100,
          align: 'center',
          render: (value: string) =>
            typeof value === 'undefined' ? '-' : parseFloat(value).toFixed(2),
        },
        {
          title: 'OUT Flow (L/h)',
          dataIndex: 'portOutFlow',
          key: 'portOutFlow',
          width: 100,
          align: 'center',
          render: (value: string) =>
            typeof value === 'undefined' ? '-' : parseFloat(value).toFixed(2),
        },
        {
          title: 'IN Temp. (°C)',
          dataIndex: 'portInTemp',
          key: 'portInTemp',
          width: 100,
          align: 'center',
          render: (value: string) =>
            typeof value === 'undefined' ? '-' : parseFloat(value).toFixed(2),
        },
        {
          title: 'OUT Temp. (°C)',
          dataIndex: 'portOutTemp',
          key: 'portOutTemp',
          width: 100,
          align: 'center',
          render: (value: string) =>
            typeof value === 'undefined' ? '-' : parseFloat(value).toFixed(2),
        },
        {
          title: (
            <span>
              IN Density (kg/m<sup>3</sup>)
            </span>
          ),
          dataIndex: 'portInDens',
          key: 'portInDens',
          width: 100,
          align: 'center',
          render: (value: string) =>
            typeof value === 'undefined' ? '-' : parseFloat(value).toFixed(2),
        },
        {
          title: (
            <span>
              OUT Density (kg/m<sup>3</sup>)
            </span>
          ),
          dataIndex: 'portOutDens',
          key: 'portOutDens',
          width: 100,
          align: 'center',
          render: (value: string) =>
            typeof value === 'undefined' ? '-' : parseFloat(value).toFixed(2),
        },
      ],
    },
    {
      title: 'STARBOARD',
      dataIndex: 'stb',
      key: 'stb',
      hide: isSingleEngine ? true : false,
      align: 'center',
      children: [
        {
          title: 'RPM (RPM)',
          dataIndex: 'stbRPM',
          key: 'stbRPM',
          width: 100,
          align: 'center',
          render: (value: string) =>
            typeof value === 'undefined' ? '-' : parseFloat(value).toFixed(2),
        },
        {
          title: 'Run. Hours (hh:mm:ss)',
          dataIndex: 'stbRunningHour',
          key: 'stbRunningHour',
          width: 100,
          align: 'center',
          render: (value: any) => value || null,
        },
        {
          title: 'Fuel Cons. (Liter)',
          dataIndex: 'stbFuelCons',
          key: 'stbFuelCons',
          width: 100,
          align: 'center',
          render: (value: string) =>
            typeof value === 'undefined' ? '-' : parseFloat(value).toFixed(2),
        },
        {
          title: 'IN Flow (L/h)',
          dataIndex: 'stbInFlow',
          key: 'stbInFlow',
          width: 100,
          align: 'center',
          render: (value: string) =>
            typeof value === 'undefined' ? '-' : parseFloat(value).toFixed(2),
        },
        {
          title: 'OUT Flow (L/h)',
          dataIndex: 'stbOutFlow',
          key: 'stbOutFlow',
          width: 100,
          align: 'center',
          render: (value: string) =>
            typeof value === 'undefined' ? '-' : parseFloat(value).toFixed(2),
        },
        {
          title: 'IN Temp. (°C)',
          dataIndex: 'stbInTemp',
          key: 'stbInTemp',
          width: 100,
          align: 'center',
          render: (value: string) =>
            typeof value === 'undefined' ? '-' : parseFloat(value).toFixed(2),
        },
        {
          title: 'OUT Temp. (°C)',
          dataIndex: 'stbOutTemp',
          key: 'stbOutTemp',
          width: 100,
          align: 'center',
          render: (value: string) =>
            typeof value === 'undefined' ? '-' : parseFloat(value).toFixed(2),
        },
        {
          title: 'IN Density (kg/m3)',
          dataIndex: 'stbInDens',
          key: 'stbInDens',
          width: 100,
          align: 'center',
          render: (value: string) =>
            typeof value === 'undefined' ? '-' : parseFloat(value).toFixed(2),
        },
        {
          title: 'OUT Density (kg/m3)',
          dataIndex: 'stbOutDens',
          key: 'stbOutDens',
          width: 100,
          align: 'center',
          render: (value: string) =>
            typeof value === 'undefined' ? '-' : parseFloat(value).toFixed(2),
        },
      ],
    },
    {
      title: 'ME Fuel Cons. (Liter)',
      dataIndex: 'meFuelCons',
      key: 'meFuelCons',
      width: 150,
      align: 'center',
      render: (value: string) =>
        typeof value === 'undefined' ? '-' : parseFloat(value).toFixed(2),
    },
    {
      title: 'AE1',
      dataIndex: 'ae1',
      key: 'ae1',
      align: 'center',
      children: [
        {
          title: 'Run. Hour (hh:mm:ss)',
          dataIndex: 'ae1RunHour',
          key: 'ae1RunHour',
          width: 100,
          align: 'center',
          render: (value: any) => (typeof value === 'undefined' ? '-' : value),
        },
        {
          title: 'Fuel Cons. (Liter)',
          dataIndex: 'ae1FuelCons',
          key: 'ae1FuelCons',
          width: 100,
          align: 'center',
          render: (value: string) =>
            typeof value === 'undefined' ? '-' : parseFloat(value).toFixed(2),
        },
      ],
    },
    {
      title: 'AE2',
      dataIndex: 'ae2',
      key: 'ae2',
      align: 'center',
      children: [
        {
          title: 'Run. Hour (hh:mm:ss)',
          dataIndex: 'ae2RunHour',
          key: 'ae2RunHour',
          width: 100,
          align: 'center',
          render: (value: any) => (typeof value === 'undefined' ? '-' : value),
        },
        {
          title: 'Fuel Cons. (Liter)',
          dataIndex: 'ae2FuelCons',
          key: 'ae2FuelCons',
          width: 100,
          align: 'center',
          render: (value: string) =>
            typeof value === 'undefined' ? '-' : parseFloat(value).toFixed(2),
        },
      ],
    },
    {
      title: 'AE3',
      dataIndex: 'ae3',
      key: 'ae3',
      align: 'center',
      children: [
        {
          title: 'Run. Hour (hh:mm:ss)',
          dataIndex: 'ae3RunHour',
          key: 'ae3RunHour',
          width: 100,
          align: 'center',
          render: (value: any) => (typeof value === 'undefined' ? '-' : value),
        },
        {
          title: 'Fuel Cons. (Liter)',
          dataIndex: 'ae3FuelCons',
          key: 'ae3FuelCons',
          width: 100,
          align: 'center',
          render: (value: any) =>
            typeof value === 'undefined' ? '-' : parseFloat(value).toFixed(2),
        },
      ],
    },
  ].filter((item) => !item.hide);

  function convertData(data: any, convertToNull?: boolean) {
    if (typeof data === 'number' || data === 0) {
      return data;
    } else {
      return !convertToNull ? undefined : null;
    }
  }

  useEffect(() => {
    if (currAsset && currMassId && currAsset.dataSummary) {
      const generateDataLog = () => {
        let newCategories: any[] = generateTimestampArray(
          moment(dataFilter.range.startAt).unix(),
          moment(dataFilter.range.endAt).unix(),
          dataFilter.interval === 'MINUTE'
            ? 60
            : dataFilter.interval === 'HOUR'
            ? 3600
            : 86400
        );

        const dataTables = newCategories.map((timestamp) => {
          const gps = currAsset.dataSummary?.gps?.data.find(
            (item) =>
              timestamp ===
              roundTimestampsToNearestMinute(item.timestamp * 1000) / 1000
          );

          const singleEngineRPM = currAsset.dataSummary?.rpm?.data.find(
            (item) => {
              if (isSingleEngine) {
                if (
                  item['MAINENGINE'] &&
                  roundTimestampsToNearestMinute(
                    item['MAINENGINE'].timestamp * 1000
                  ) /
                    1000 ===
                    timestamp
                ) {
                  return item['MAINENGINE'];
                } else if (
                  item['PORT'] &&
                  roundTimestampsToNearestMinute(
                    item['PORT'].timestamp * 1000
                  ) /
                    1000 ===
                    timestamp
                ) {
                  return item['PORT'];
                } else if (
                  item['STARBOARD'] &&
                  roundTimestampsToNearestMinute(
                    item['STARBOARD'].timestamp * 1000
                  ) /
                    1000 ===
                    timestamp
                ) {
                  return item['STARBOARD'];
                }
              } else {
                return undefined;
              }
            }
          );

          const portRPM = currAsset.dataSummary?.rpm?.data.find(
            (item) =>
              item['PORT'] &&
              roundTimestampsToNearestMinute(item['PORT'].timestamp * 1000) /
                1000 ===
                timestamp
          );

          const stbRPM = currAsset.dataSummary?.rpm?.data.find(
            (item) =>
              item['STARBOARD'] &&
              roundTimestampsToNearestMinute(
                item['STARBOARD'].timestamp * 1000
              ) /
                1000 ===
                timestamp
          );

          const flowmeter = currAsset.dataSummary?.flowmeter?.data.find(
            (item) => {
              if (
                item.PORT_IN &&
                roundTimestampsToNearestMinute(item.PORT_IN.timestamp * 1000) /
                  1000 ===
                  timestamp
              ) {
                return item;
              } else if (
                item.PORT_OUT &&
                roundTimestampsToNearestMinute(item.PORT_OUT.timestamp * 1000) /
                  1000 ===
                  timestamp
              ) {
                return item;
              } else {
                return undefined;
              }
            }
          );

          const ae1 = currAsset.dataSummary?.ae?.data.find((item: any) => {
            if (
              item.AE1 &&
              roundTimestampsToNearestMinute(item.AE1.timestamp * 1000) /
                1000 ===
                timestamp
            ) {
              return item;
            }
          });

          const ae2 = currAsset.dataSummary?.ae?.data.find((item: any) => {
            if (
              item.AE2 &&
              roundTimestampsToNearestMinute(item.AE2.timestamp * 1000) /
                1000 ===
                timestamp
            ) {
              return item;
            }
          });

          const ae3 = currAsset.dataSummary?.ae?.data.find((item: any) => {
            if (
              item.AE3 &&
              roundTimestampsToNearestMinute(item.AE3.timestamp * 1000) /
                1000 ===
                timestamp
            ) {
              return item;
            }
          });

          let portRunningHour = '-';

          if (
            isSingleEngine &&
            singleEngineRPM &&
            singleEngineRPM[0] &&
            singleEngineRPM[0].runningTime
          ) {
            portRunningHour = toHHMMSS(singleEngineRPM[0].runningTime);
          } else if (
            portRPM?.PORT?.runningTime &&
            convertData(portRPM?.PORT?.runningTime)
          ) {
            portRunningHour = toHHMMSS(portRPM?.PORT?.runningTime);
          } else if (portRPM?.PORT?.rpm === 0) {
            portRunningHour = toHHMMSS(0);
          }

          return {
            id: timestamp,
            dateTime: moment(timestamp * 1000).format('DD MMM YYYY, HH:mm:ss'),
            coordinate: gps ? `${gps.latitude} ${gps.longitude}` : undefined,
            speed: convertData(gps?.speed),
            distance: convertData(gps?.distance),
            heading: convertData(gps?.track),
            // PORT/SINGLE ENGINE
            portRPM:
              isSingleEngine && singleEngineRPM
                ? Object.values(singleEngineRPM)[0].rpm
                : convertData(portRPM?.PORT?.rpm),
            portRunningHour,
            portFuelCons:
              isSingleEngine && flowmeter
                ? convertData(flowmeter.meFuelCons)
                : convertData(flowmeter?.PORT_FuelConsVolume),
            portInFlow: convertData(flowmeter?.PORT_InFlow),
            portOutFlow: convertData(flowmeter?.PORT_OutFlow),
            portInTemp: convertData(flowmeter?.PORT_IN.temperature),
            portOutTemp: convertData(flowmeter?.PORT_OUT.temperature),
            portInDens: convertData(flowmeter?.PORT_InDens),
            portOutDens: convertData(flowmeter?.PORT_OutDens),
            // STARBOARD ENGINE
            stbRPM: isSingleEngine
              ? undefined
              : convertData(stbRPM?.STARBOARD?.rpm),
            stbRunningHour: isSingleEngine
              ? undefined
              : toHHMMSS(
                  stbRPM?.STARBOARD?.rpm === 0
                    ? 0
                    : convertData(stbRPM?.STARBOARD?.runningTime)
                ),
            stbFuelCons: isSingleEngine
              ? undefined
              : convertData(flowmeter?.STARBOARD_FuelConsVolume),
            stbInFlow: isSingleEngine
              ? undefined
              : convertData(flowmeter?.STARBOARD_InFlow),
            stbOutFlow: isSingleEngine
              ? undefined
              : convertData(flowmeter?.STARBOARD_OutFlow),
            stbInTemp: isSingleEngine
              ? undefined
              : convertData(flowmeter?.STARBOARD_IN.temperature),
            stbOutTemp: isSingleEngine
              ? undefined
              : convertData(flowmeter?.STARBOARD_OUT.temperature),
            stbInDens: isSingleEngine
              ? undefined
              : convertData(flowmeter?.STARBOARD_InDens),
            stbOutDens: isSingleEngine
              ? undefined
              : convertData(flowmeter?.STARBOARD_OutDens),
            meFuelCons: flowmeter
              ? convertData(
                  flowmeter.PORT_FuelConsVolume +
                    flowmeter.STARBOARD_FuelConsVolume
                )
              : undefined,
            // AE
            ae1RunHour: ae1?.AE1.runningSeconds
              ? toHHMMSS(ae1?.AE1.runningSeconds)
              : undefined,
            ae1FuelCons: convertData(ae1?.AE1.fuelConsumption),
            ae2RunHour: ae2?.AE2.runningSeconds
              ? toHHMMSS(ae2?.AE2.runningSeconds)
              : undefined,
            ae2FuelCons: convertData(ae2?.AE2.fuelConsumption),
            ae3RunHour: ae3?.AE3.runningSeconds
              ? toHHMMSS(ae3?.AE3.runningSeconds)
              : undefined,
            ae3FuelCons: convertData(ae3?.AE3.fuelConsumption),
          };
        });

        setDataTables(dataTables);
      };

      generateDataLog();
      generateSummary();
    }

    // eslint-disable-next-spline react-hooks/exhaustive-deps
  }, [currAsset, currMassId]);

  const handleToggleData = (groupId: string) => {
    const newSummary = summary.map((item: any) =>
      item.groupId === groupId
        ? {
            ...item,
            show: !item.show,
          }
        : {
            ...item,
          }
    );
    setSummary(newSummary);
  };

  const generateSummary = () => {
    let currSummary: any = [];
    if (currAsset && currAsset.dataSummary) {
      const data = currAsset.dataSummary;
      currSummary = [
        {
          groupId: '0',
          show: true,
          contents: [
            {
              label: 'General Summary',
              datas: [
                {
                  label: 'Total Cruise',
                  value: data.gps ? toKM(data.gps.summary.totalDistance) : '-',
                  unit: unit,
                  icon: 'marker',
                },
                {
                  label: 'Average Speed',
                  value: data.gps ? toKnot(data.gps.summary.averageSpeed) : '-',
                  unit: speed,
                  icon: 'marker',
                },
                // {
                //   label: "Average Speed",
                //   value: toKnot(data.gps.calculatedSpeedKMh),
                //   unit: speed,
                //   icon: "marker",
                // },
                {
                  label: 'Distance',
                  value: data.gps ? toKM(data.gps.summary.distance) : '-',
                  unit: unit,
                  icon: 'marker',
                },
              ],
            },
          ],
        },
        {
          groupId: '1',
          show: false,
          contents: [
            {
              label: 'Average RPM',
              datas: isSingleEngine
                ? [
                    {
                      label: 'MAIN ENGINE',
                      value: data.rpm?.summary.average.MAINENGINE.toFixed(2),
                      unit: 'RPM',
                      icon: 'marker',
                    },
                  ]
                : [
                    {
                      label: 'PORT',
                      value: data.rpm?.summary.average.PORT.toFixed(2),
                      unit: 'RPM',
                      icon: 'marker',
                    },
                    {
                      label: 'STARBOARD',
                      value: data.rpm?.summary.average.STARBOARD.toFixed(2),
                      unit: 'RPM',
                      icon: 'marker',
                    },
                  ],
            },
            {
              label: 'Running Hour',
              datas: isSingleEngine
                ? [
                    {
                      label: 'MAIN ENGINE',
                      value: data.rpm
                        ? toHHMMSS(
                            data.rpm.summary.runningTime.PORT ||
                              data.rpm.summary.runningTime.STARBOARD ||
                              0
                          )
                        : '-',
                      unit: 'RPM',
                      icon: 'marker',
                    },
                  ]
                : [
                    {
                      label: 'PORT',
                      value: data.rpm
                        ? toHHMMSS(data.rpm.summary.runningTime.PORT)
                        : '-',
                      // unit: "RPM",
                      icon: 'marker',
                    },
                    {
                      label: 'STARBOARD',
                      value: data.rpm
                        ? toHHMMSS(data.rpm.summary.runningTime.STARBOARD)
                        : '-',
                      // unit: "RPM",
                      icon: 'marker',
                    },
                  ],
            },
            {
              label: 'Average Flow',
              datas: isSingleEngine
                ? [
                    {
                      label: 'MAIN ENGINE',
                      value:
                        data.flowmeter &&
                        data.flowmeter.summary &&
                        data.flowmeter.summary.portEngineCons &&
                        data.flowmeter.summary.portEngineCons !== undefined &&
                        data.flowmeter.summary.portEngineCons !== 0 &&
                        data.rpm?.summary.runningTime &&
                        Object.keys(data.rpm.summary.runningTime).length > 0 &&
                        data.rpm.summary.runningTime.PORT !== 0
                          ? data.flowmeter.summary.portAverageFlow.toFixed(2)
                          : 0,
                      unit: 'Liter',
                      icon: 'marker',
                    },
                  ]
                : [
                    {
                      label: 'PORT',
                      value:
                        data.flowmeter &&
                        data.flowmeter.summary &&
                        data.flowmeter.summary.portEngineCons &&
                        data.flowmeter.summary.portEngineCons !== undefined &&
                        data.flowmeter.summary.portEngineCons !== 0 &&
                        data.rpm?.summary.runningTime &&
                        Object.keys(data.rpm.summary.runningTime).length > 0 &&
                        data.rpm.summary.runningTime.PORT !== 0
                          ? data.flowmeter.summary.portAverageFlow.toFixed(2)
                          : 0,
                      unit: 'Liter',
                      icon: 'marker',
                    },
                    {
                      label: 'STARBOARD',
                      value:
                        data.flowmeter &&
                        data.flowmeter.summary &&
                        data.flowmeter.summary.stbEngineCons &&
                        data.flowmeter.summary.stbEngineCons !== undefined &&
                        data.flowmeter.summary.stbEngineCons !== 0 &&
                        data.rpm?.summary.runningTime &&
                        Object.keys(data.rpm.summary.runningTime).length > 0 &&
                        data.rpm.summary.runningTime.STARBOARD !== 0
                          ? data.flowmeter.summary.starboardAverageFlow.toFixed(
                              2
                            )
                          : 0,
                      unit: 'Liter',
                      icon: 'marker',
                    },
                  ],
            },
            {
              label: 'Total Engine Cons.',
              datas: isSingleEngine
                ? [
                    {
                      label: 'MAIN ENGINE',
                      value:
                        data.flowmeter &&
                        data.flowmeter.summary &&
                        data.flowmeter.summary &&
                        data.flowmeter.summary.portEngineCons &&
                        data.flowmeter.summary.portEngineCons !== undefined
                          ? data.flowmeter.summary.portEngineCons.toFixed(2)
                          : 0,
                      unit: 'Liter',
                      icon: 'marker',
                    },
                  ]
                : [
                    {
                      label: 'PORT',
                      value:
                        data.flowmeter &&
                        data.flowmeter.summary &&
                        data.flowmeter.summary &&
                        data.flowmeter.summary.portEngineCons &&
                        data.flowmeter.summary.portEngineCons !== undefined
                          ? data.flowmeter.summary.portEngineCons.toFixed(2)
                          : 0,
                      unit: 'Liter',
                      icon: 'marker',
                    },
                    {
                      label: 'STARBOARD',
                      value:
                        data.flowmeter &&
                        data.flowmeter.summary &&
                        data.flowmeter.summary.stbEngineCons &&
                        data.flowmeter.summary.stbEngineCons !== undefined
                          ? data.flowmeter.summary.stbEngineCons.toFixed(2)
                          : 0,
                      unit: 'Liter',
                      icon: 'marker',
                    },
                  ],
            },
          ],
        },
        {
          groupId: '2',
          show: false,
          contents: [
            {
              label: 'AE1',
              datas: [
                {
                  label: 'Running Time',
                  value:
                    data.ae?.summary && data.ae.summary.runningSeconds
                      ? toHHMMSS(data.ae.summary.runningSeconds.AE1)
                      : 0,
                  icon: 'marker',
                },
                {
                  label: 'Fuel Consumption',
                  value: data.ae?.summary.fuelConsumption
                    ? data.ae.summary.fuelConsumption.AE1.toFixed(2)
                    : 0,
                  unit: 'Liter',
                  icon: 'marker',
                },
              ],
            },
            {
              label: 'AE2',
              datas: [
                {
                  label: 'Running Time',
                  value: data.ae?.summary.runningSeconds
                    ? toHHMMSS(data.ae.summary.runningSeconds.AE2)
                    : 0,
                  icon: 'marker',
                },
                {
                  label: 'Fuel Consumption',
                  value: data.ae?.summary.fuelConsumption
                    ? data.ae.summary.fuelConsumption.AE2.toFixed(2)
                    : 0,
                  unit: 'Liter',
                  icon: 'marker',
                },
              ],
            },
            {
              label: 'AE3',
              datas: [
                {
                  label: 'Running Time',
                  value: data.ae?.summary.runningSeconds
                    ? toHHMMSS(data.ae.summary.runningSeconds.AE3)
                    : 0,
                  icon: 'marker',
                },
                {
                  label: 'Fuel Consumption',
                  value: data.ae?.summary.fuelConsumption
                    ? data.ae.summary.fuelConsumption.AE3.toFixed(2)
                    : 0,
                  unit: 'Liter',
                  icon: 'marker',
                },
              ],
            },
          ],
        },
      ];
    }

    setSummary(currSummary);
  };

  const handleSetURLParams = (data: any) => {
    setSearchParams(data);
  };

  const downloadExcel = () => {
    const start = moment(dataFilter.range.startAt).format(
      'DD_MM_YYYY_HH_mm_ss'
    );
    const end = moment(dataFilter.range.endAt).format('DD_MM_YYYY_HH_mm_ss');
    const range_excel = start + '-' + end;

    const newRefinedData = dataTables.map((item: any) => {
      const newItem = {} as any;
      Object.keys(item).forEach((key) => {
        if (key === 'coordinate') {
          newItem[key] = item[key] ? item[key].split(' ').join(', ') : null;
        } else if (
          key === 'timestamp' ||
          key === 'dateTime' ||
          typeof item[key] === 'string'
        ) {
          newItem[key] = item[key];
        } else if (typeof item[key] === 'number') {
          newItem[key] = parseFloat(item[key].toFixed(2));
        } else {
          newItem[key] = 0;
        }
      });
      return newItem;
    });
    const data = XLSX.utils.json_to_sheet(newRefinedData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, data, 'data');
    XLSX.writeFile(
      wb,
      `${currAsset?.massName.split(' ').join('_')}-${range_excel}.xlsx`
    );
  };

  const location = useLocation();

  return (
    <React.Fragment>
      {dataCompletion && (
        <DataCompletionInfo
          loading={false}
          {...dataCompletion}
          pathname={location.pathname}
        />
      )}
      <ReportActionContent>
        <FilterDashboard
          isLog
          setURLParams={handleSetURLParams}
          searchParams={searchParams}
        />
        <Button
          loading={isLoadingAssetSummary && dataTables.length === 0}
          disabled={isLoadingAssetSummary && dataTables.length === 0}
          onClick={downloadExcel}
          style={{ marginBottom: 8, background: '#54C95A' }}
        >
          <span style={{ color: 'white' }}>Export XLSX</span>
        </Button>
      </ReportActionContent>

      {isLoadingAssetSummary || isLoadingFilter ? (
        <ContainerLoading>
          <LoadingWrapper>
            <Spin />
            <div>Loading data...</div>
          </LoadingWrapper>
        </ContainerLoading>
      ) : dataTables && dataTables.length > 0 ? (
        <Content>
          {summary.map((group: any) => (
            <ContentWrapper
              onClick={() => handleToggleData(group.groupId)}
              odd={group.groupId === '0'}
              key={group.groupId}
            >
              <HandleAccordion>
                {group.show ? <MinusOutlined /> : <PlusOutlined />}
              </HandleAccordion>
              {group.contents.map((content: any) => (
                <Wrapper key={content.label}>
                  <ContentItem>
                    <h3>{content.label}</h3>
                    {group.groupId === '0' ? (
                      <FlexRow show={group.show}>
                        {content.datas.map((data: any, idx: number) => (
                          <CardInfo
                            key={idx}
                            label={data.label}
                            value={data.value}
                            iconType='marker'
                            unit={data.unit || undefined}
                          />
                        ))}
                      </FlexRow>
                    ) : (
                      <FlexColumn show={group.show}>
                        {content.datas.map((data: any, idx: number) => (
                          <CardInfo
                            key={idx}
                            label={data.label}
                            value={data.value}
                            iconType='marker'
                            unit={data.unit || undefined}
                          />
                        ))}
                      </FlexColumn>
                    )}
                  </ContentItem>
                </Wrapper>
              ))}
            </ContentWrapper>
          ))}

          <Table
            className='custom-small-table'
            style={{ marginTop: 20 }}
            size='small'
            bordered
            columns={columns}
            pagination={{
              pageSize: 500,
            }}
            scroll={{ y: windowHeight - 300, x: 700 }}
            dataSource={dataTables}
          />
        </Content>
      ) : (
        <ContainerLoading>
          <LoadingWrapper>
            <InfoCircleOutlined />
            <div>Data not found</div>
          </LoadingWrapper>
        </ContainerLoading>
      )}
    </React.Fragment>
  );
};

export default ReportLog;

export const ReportActionContent = styled.div`
  display: grid;
  grid-template-columns: 1fr 160px;
  align-items: flex-end;
  padding-right: 20px;
`;

const HandleAccordion = styled.div`
  position: absolute;
  top: 12px;
  right: 10px;
`;

const Content = styled.div`
  padding: 20px 20px;
  height: auto;
  background-color: ${(props) => props.theme.colors.grayLighten_Default};
`;

interface ContentWrapperProps {
  odd: boolean;
}

const ContentWrapper = styled.div<ContentWrapperProps>`
  border-bottom: 1px solid ${(props) => props.theme.colors.grayLighten_Dark};
  padding: 10px 0;
  display: grid;
  gap: 20px 15px;
  position: relative;
  cursor: pointer;
  grid-template-columns: ${(props) => (props.odd ? '1fr' : 'repeat(4, 1fr)')};
`;

const Wrapper = styled.div``;

const ContentItem = styled.div`
  h3 {
    font-weight: 600;
    margin: 0;
  }
`;

interface FlexProps {
  show: boolean;
}

const FlexColumn = styled.div<FlexProps>`
  padding-top: 10px;
  display: ${(props) => (props.show ? 'flex' : 'none')};
  flex-direction: column;
  gap: 15px;
`;

const FlexRow = styled.div<FlexProps>`
  padding-top: 10px;
  display: ${(props) => (props.show ? 'grid' : 'none')};
  gap: 15px;
  grid-template-columns: repeat(4, 1fr);
`;
