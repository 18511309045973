import { Button, DatePicker, Select } from 'antd';
import styled from 'styled-components';
import CustomForm from '../../../components/CustomForm';
import AuthSvc from '../../../services/AuthSvc';
import { AssetType } from '../../../types/asset.type';
import { intervalOptions, rangeOptions } from '../../../helpers/map';
import moment, { Moment } from 'moment';
import 'moment-timezone';
import type { RangePickerProps } from 'antd/es/date-picker';
import useFilter from '../../../hooks/useFilter';
import useCompany from '../../../hooks/useCompany';
import { useEffect, useMemo, useState } from 'react';
import AssetSvc from '../../../services/AssetSvc';
import useAsset from '../../../hooks/useAsset';
import { DataFilterProps, initialDataFilter } from '../../../types/config.type';
import { useLocation } from 'react-router';
import useMapConfig from '../../../hooks/useMapConfig';
import useAuth from '../../../hooks/useAuth';
import DateRangePicker from '../../../components/DateRangePicker';

interface Props {
  setURLParams: (params: string[][]) => void;
  searchParams: URLSearchParams;
  isLog?: boolean;
  isHomeSummaryTable?: boolean;
}

const { RangePicker } = DatePicker;
const dateTimeFormat = 'DD-MM-YYYY HH:mm';

const FilterDashboard: React.FC<Props> = (props) => {
  const { isLoadingFilter, setDataFilter, dataFilter } = useFilter();
  const { isLoadingAssetSummary, assets2, fetchAssetsWithLastData } =
    useAsset();
  const [localFilter, setLocalFilter] = useState<DataFilterProps>({
    ...initialDataFilter,
  });
  const [localAsssts, setLocalAssets] = useState<AssetType[]>([]);
  const {
    availableCompanies,
    activeCompany,
    isLoadingCompanyData,
    setAvailableCompaniesRaw,
  } = useCompany();
  const { showTableSummary } = useMapConfig();
  const location = useLocation();
  const { loginData } = useAuth();

  // const defaultToHour = localFilter.massId === '42' || props.isHomeSummaryTable;
  const defaultToHour = props.isHomeSummaryTable;

  useEffect(() => {
    if (loginData && loginData.company && loginData.company.available) {
      setAvailableCompaniesRaw(loginData.company.available);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loginData.company]);

  useEffect(() => {
    if (activeCompany && assets2.length > 0) {
      const searchParams = new URLSearchParams(document.location.search);
      const queryMassId = searchParams.get('massId');
      const queryCompId = searchParams.get('compId');
      const queryStart = searchParams.get('start');
      const queryEnd = searchParams.get('end');
      const queryAggregatedUnit = searchParams.get('aggregatedUnit');
      const queryRangeType = searchParams.get('value');

      setLocalFilter((old) => ({
        ...old,
        massId: queryMassId || localFilter.massId || assets2[0].massId,
        compId: queryCompId || localFilter.compId || activeCompany.compId,
        interval: queryAggregatedUnit
          ? queryAggregatedUnit
          : defaultToHour
          ? intervalOptions[1].value // set default interval to hour
          : intervalOptions[0].value,
        rangeType: queryRangeType || rangeOptions[4].value,
        range: {
          startAt: queryStart
            ? moment(Number(queryStart))
            : initialDataFilter.range.startAt,
          endAt: queryEnd
            ? moment(Number(queryEnd))
            : initialDataFilter.range.endAt,
        },
      }));

      setDataFilter((current) => ({
        ...current,
        range: {
          startAt: queryStart
            ? moment(Number(queryStart))
            : current.range.startAt,
          endAt: queryEnd ? moment(Number(queryEnd)) : current.range.endAt,
        },
        massId: queryMassId || assets2[0].massId,
        compId: queryCompId || activeCompany.compId,
        interval: queryAggregatedUnit
          ? queryAggregatedUnit
          : defaultToHour
          ? intervalOptions[1].value // set default interval to hour
          : intervalOptions[0].value,
      }));

      let currAssets2: AssetType[] = [];
      if (location.pathname.includes('home') && showTableSummary) {
        currAssets2 = assets2.filter(
          (item) =>
            item.dataSummary &&
            item.dataSummary?.gps &&
            item.dataSummary?.gps.data.length > 0
        );
      } else {
        currAssets2 = [...assets2];
      }

      // setLocalAssets(assets2);
      setLocalAssets(currAssets2);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeCompany, assets2, showTableSummary]);

  const onChangeCompany = async (compId: string) => {
    await AuthSvc.setCompany(compId);
    const assetsResponse = await AssetSvc.getAssets();

    if (assetsResponse.data && assetsResponse.data.data) {
      // Filter if data gps exists
      setLocalAssets(assetsResponse.data.data);
      setLocalFilter((current) => ({
        ...current,
        compId,
        massId: assetsResponse.data.data[0].massId,
      }));
    }
  };

  const onChangeAsset = async (assetId: string) => {
    setLocalFilter((current) => ({ ...current, massId: assetId }));
  };

  const onChangeRangeType = (value: string) => {
    let rangeType: any;

    const found = rangeOptions.find((item) => item.value === value);

    if (found) {
      rangeType = found.value;
    }

    let interval = localFilter.interval;
    let startAt = moment();
    let endAt = moment();
    if (value === 'today' || value === 'last_day') {
      interval = intervalOptions[0].value;
    } else {
      interval = intervalOptions[2].value;
    }
    switch (value) {
      case 'last_days_30':
        startAt = moment().subtract(30, 'day');
        endAt = moment();
        break;
      case 'last_month':
        startAt = moment()
          .subtract(1, 'month')
          .startOf('month')
          .set({ hour: 0, minute: 0, second: 0 });
        endAt = moment()
          .subtract(1, 'month')
          .endOf('month')
          .set({ hour: 23, minute: 59, second: 59 });
        break;
      case 'last_days_7':
        startAt = moment().subtract(7, 'days');
        endAt = moment();
        break;
      case 'last_week':
        startAt = moment().subtract(1, 'week').startOf('week');
        endAt = moment();
        break;
      case 'today':
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        startAt = moment().set({ hour: 0, minute: 0, second: 0 });
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        endAt = moment();
        break;

      default:
        break;
    }
    setLocalFilter((current) => ({
      ...current,
      rangeType,
      range: {
        startAt,
        endAt,
      },
      interval,
    }));
  };

  const onChangeDateRange: RangePickerProps['onChange'] = (
    dates,
    dateStrings
  ) => {
    console.log('onChangeDateRange', onChangeDateRange);
    setLocalFilter((oldData) => ({
      ...oldData,
      rangeType: rangeOptions[6].value,
      range: {
        startAt: moment(dateStrings[0]),
        endAt: moment(dateStrings[1]),
      },
    }));
  };

  const onChangeDateRangeCustom = (date: Moment, type: string) => {
    setLocalFilter((oldData) => ({
      ...oldData,
      rangeType: rangeOptions[6].value,
      range: {
        startAt: type === 'start' ? date : oldData.range.startAt,
        endAt: type === 'end' ? date : oldData.range.endAt,
      },
    }));
  };

  const onChangeInterval = (interval: string) => {
    setLocalFilter((oldVal) => ({
      ...oldVal,
      interval,
    }));
  };

  const isLoadingData = useMemo(() => {
    if (isLoadingFilter || isLoadingCompanyData || isLoadingAssetSummary) {
      return true;
    }
    return false;
  }, [isLoadingFilter, isLoadingCompanyData, isLoadingAssetSummary]);

  const handleOnApplyFilter = async () => {
    try {
      setDataFilter({ ...localFilter });
      const queryParams: string[][] = [];
      let interval: string = localFilter.interval;

      if (defaultToHour) {
        interval = intervalOptions[1].value; // set default interval to hour
      }

      queryParams.push(
        ['start', localFilter.range.startAt.valueOf().toString()],
        ['end', localFilter.range.endAt.valueOf().toString()],
        ['mode', 'period'],
        ['value', localFilter.rangeType],
        ['aggregatedUnit', interval],
        ['massId', localFilter.massId],
        ['compId', localFilter.compId]
      );
      props.setURLParams(queryParams);

      await fetchAssetsWithLastData({
        start: localFilter.range.startAt.valueOf().toString(),
        end: localFilter.range.endAt.valueOf().toString(),
        devcMassId:
          showTableSummary && location.pathname.includes('home')
            ? undefined
            : localFilter.massId,
        aggregatedUnit: localFilter.interval,
        aggregatedLength:
          location.pathname.includes('/report-data-log') ||
          location.pathname.includes('/report-bms-log') ||
          location.pathname.includes('/report-summary')
            ? 1
            : undefined,
      });
    } catch (error) {
      console.error('error handleApplyFilter', error);
    }
  };

  const newIntervalOptions = useMemo(() => {
    let currentIntervalOptions = intervalOptions;
    if (
      ['last_days_30', 'last_month', 'last_days_7', 'last_week'].includes(
        localFilter.rangeType
      )
    ) {
      currentIntervalOptions = currentIntervalOptions.map((item) => {
        if (['MINUTE', 'HOUR'].includes(item.value)) {
          return {
            ...item,
            disabled: true,
          };
        } else {
          return item;
        }
      });
    }

    return currentIntervalOptions;
  }, [
    localFilter.rangeType,
    localFilter.range.startAt,
    localFilter.range.endAt,
  ]);

  const isInvalidDateRange = useMemo(() => {
    const startTimestamp = moment(localFilter.range.startAt).valueOf();
    const endTimestamp = moment(localFilter.range.endAt).valueOf();

    return startTimestamp > endTimestamp ? true : false;
  }, [localFilter.range.startAt, localFilter.range.endAt]);

  return (
    <FilterWrapper
      isLastDay={localFilter.rangeType === 'last_day'}
      isHomeSummaryTable={props.isHomeSummaryTable}
    >
      {!props.isHomeSummaryTable && (
        <CustomForm label='Company'>
          <Select
            value={localFilter.compId}
            style={{ width: '100%' }}
            onChange={onChangeCompany}
            disabled={
              isLoadingData ||
              (availableCompanies && availableCompanies.length === 1)
            }
            options={
              availableCompanies &&
              availableCompanies.map((comp) => ({
                value: comp.compId,
                label: comp.compName,
              }))
            }
          />
        </CustomForm>
      )}
      <CustomForm label='Asset'>
        <Select
          value={localFilter.massId}
          style={{
            width:
              localFilter.rangeType === 'last_day' || props.isHomeSummaryTable
                ? '100%'
                : 160,
          }}
          onChange={onChangeAsset}
          disabled={isLoadingData}
          loading={isLoadingData}
          options={localAsssts.map((item) => ({
            value: item.massId,
            label: item.massName,
          }))}
        />
      </CustomForm>
      {!props.isHomeSummaryTable && (
        <CustomForm label='Range Type'>
          <Select
            value={localFilter.rangeType}
            style={{ width: 140 }}
            disabled={isLoadingData || localAsssts.length === 0}
            loading={isLoadingData}
            onChange={onChangeRangeType}
            options={rangeOptions}
          />
        </CustomForm>
      )}

      {localFilter.rangeType && localFilter.rangeType !== 'last_day' ? (
        <CustomForm
          label='Range'
          errorMessage='Filter date range invalid'
          error={isInvalidDateRange}
        >
          {/* <RangePicker
            style={{ width: '100%' }}
            disabled={isLoadingData || localAsssts.length === 0}
            showTime
            value={[
              moment(localFilter.range.startAt, dateTimeFormat),
              moment(localFilter.range.endAt, dateTimeFormat),
            ]}
            format='DD MMM YYYY HH:mm'
            onOk={onOK}
            onCalendarChange={onCalendarChange}
            onChange={onChangeDateRange}
          /> */}
          <DateRangePicker
            startAt={localFilter.range.startAt}
            endAt={localFilter.range.endAt}
            custom={true}
            disabled={isLoadingData || localAsssts.length === 0}
            onChange={(e) => console.log(e)}
            onChangeCustom={(e, type) => onChangeDateRangeCustom(e, type)}
          />
        </CustomForm>
      ) : (
        false
      )}
      <CustomForm label='Interval'>
        <Select
          value={localFilter.interval}
          style={{ width: 100 }}
          disabled={isLoadingData || localAsssts.length === 0}
          loading={isLoadingData}
          onChange={onChangeInterval}
          options={newIntervalOptions}
        />
      </CustomForm>
      <Button
        disabled={isInvalidDateRange}
        loading={isLoadingData}
        style={{ width: 120 }}
        onClick={handleOnApplyFilter}
        type='primary'
      >
        Apply
      </Button>
    </FilterWrapper>
  );
};

interface IFilterWrapper {
  isLastDay: boolean;
  isHomeSummaryTable?: boolean;
}

const FilterWrapper = styled.div<IFilterWrapper>`
  display: grid;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
  gap: 10px;
  grid-template-columns: ${(props) =>
    props.isHomeSummaryTable
      ? '1fr 400px 100px 120px'
      : props.isLastDay
      ? '1fr 1fr 140px 100px 120px'
      : '1fr 160px 140px 1fr 100px 120px'};
  padding: 10px 20px;
  height: 80px;
`;

export default FilterDashboard;
