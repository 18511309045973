import { TitleCard } from '../../screens/dashboard';
import HighchartsReact from 'highcharts-react-official';
import highchartsMore from 'highcharts/highcharts-more.js';
import Highcharts from 'highcharts';
import { useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import {
  generateArrays,
  generateDataTable,
  hChartConfig,
  hColors,
} from '../../helpers/chart';
import React from 'react';
import useFilter from '../../hooks/useFilter';
import { IFlowmeterData, IRPMData, ISummary } from '../../types/summary.type';
import {
  mergeArraysAndSkipDuplicates,
  roundTimestampsToNearestMinute,
  roundValue,
} from '../../helpers/dateUtil';
import { EmptyChart } from './HChartAEFuelCons';

highchartsMore(Highcharts);

interface IProps {
  data?: ISummary;
  isDailyReport?: boolean;
  isSingleEngine?: boolean;
  columns: number[];
}

const HChartMeFuelCons = React.forwardRef<any, IProps>(
  ({ data, isDailyReport, isSingleEngine, columns }, ref) => {
    const [dataChart, setDataChart] = useState<any>();
    const [summary, setSummary] = useState<{ max: number; avg: number }>({
      max: 0,
      avg: 0,
    });
    const { dataFilter } = useFilter();

    const generateYAxis = () => {
      let yAxis: any = [];
      if (!isSingleEngine) {
        yAxis = [
          {
            labels: {
              format: '{value}',
              style: {
                color: hColors.black,
              },
            },
            title: {
              text: 'Liter/hour',
              style: {
                color: hColors.black,
              },
            },
            lineColor: hColors.black,
            lineWidth: 1,
            tickColor: hColors.black,
            tickWidth: 1,
            tickLength: 5,
          },
        ];
      } else {
        yAxis = [
          {
            labels: {
              format: '{value}',
              style: {
                color: hColors.black,
              },
            },
            title: {
              text: 'Liter/hour',
              style: {
                color: hColors.black,
              },
            },
            lineColor: hColors.black,
            lineWidth: 1,
            tickColor: hColors.black,
            tickWidth: 1,
            tickLength: 5,
          },
          {
            labels: {
              format: '{value}',
              style: {
                color: hColors.purple,
              },
            },
            title: {
              text: 'RPM',
              style: {
                color: hColors.purple,
              },
            },
            lineColor: hColors.purple,
            lineWidth: 1,
            tickColor: hColors.purple,
            tickWidth: 1,
            tickLength: 5,
            opposite: true,
          },
        ];
      }

      return yAxis;
    };

    const dataTable = useMemo(() => {
      if (data) {
        return generateDataTable(data);
      }
    }, [data]);

    useEffect(() => {
      if (data) {
        const createDataSeries = () => {
          let currChartOption: any = {
            ...hChartConfig,
            yAxis: generateYAxis(),
          };

          // const categories = data.flowmeter?.data.map((fm: IFlowmeterData) => {
          //   let timestamp = 0;
          //   if (fm.PORT_IN) {
          //     timestamp = fm.PORT_IN.timestamp;
          //   } else if (fm.PORT_OUT) {
          //     timestamp = fm.PORT_OUT.timestamp;
          //   } else if (fm.STARBOARD_IN) {
          //     timestamp = fm.STARBOARD_IN.timestamp;
          //   } else if (fm.STARBOARD_OUT) {
          //     timestamp = fm.STARBOARD_OUT.timestamp;
          //   }
          //   return moment(timestamp * 1000).format('DD/MM/YY HH:mm');
          // });

          let newCategories: any[] = mergeArraysAndSkipDuplicates(
            data.flowmeter?.timestamps || []
          ).sort((a, b) => a - b);

          const dataSeries: any = [];
          let allPortStbFuelCons: number[] = [];
          let allMeFuelConsData: number[] = [];

          Object.keys(data).forEach((key) => {
            if (
              key === 'flowmeter' &&
              data['flowmeter']?.data &&
              data['flowmeter']?.data.length > 0
            ) {
              if (!isSingleEngine) {
                data['flowmeter'].data.forEach((item) => {
                  allPortStbFuelCons.push(item.PORT_FuelConsVolume || 0);
                  allPortStbFuelCons.push(item.STARBOARD_FuelConsVolume || 0);
                  allMeFuelConsData.push(item.meFuelCons || 0);
                });

                // MULTILPE ENGINE
                const portFuelConsDataSeries: (number | undefined)[] = [];
                const stbFuelConsDataSeries: (number | undefined)[] = [];

                if (data[key]) {
                  newCategories.forEach((item: any) => {
                    const isTimestampSame = data[key]?.data.find(
                      (data: IFlowmeterData) =>
                        data &&
                        data.PORT_IN &&
                        item ===
                          roundTimestampsToNearestMinute(
                            data.PORT_IN.timestamp * 1000
                          ) /
                            1000
                    );

                    if (isTimestampSame) {
                      portFuelConsDataSeries.push(
                        isTimestampSame.PORT_FuelConsVolume < 0
                          ? 0
                          : isTimestampSame.PORT_FuelConsVolume
                      );
                    } else {
                      portFuelConsDataSeries.push(undefined);
                    }
                  });
                }

                if (data[key]) {
                  newCategories.forEach((item: any) => {
                    const isTimestampSame = data[key]?.data.find(
                      (data: IFlowmeterData) =>
                        data &&
                        data.STARBOARD_IN &&
                        item ===
                          roundTimestampsToNearestMinute(
                            data.STARBOARD_IN.timestamp * 1000
                          ) /
                            1000
                    );

                    if (isTimestampSame) {
                      stbFuelConsDataSeries.push(
                        isTimestampSame.STARBOARD_FuelConsVolume < 0
                          ? 0
                          : isTimestampSame.STARBOARD_FuelConsVolume
                      );
                    } else {
                      stbFuelConsDataSeries.push(undefined);
                    }
                  });
                }

                // Port Fuel Cons
                dataSeries.push({
                  name: 'Port Fuel Cons.',
                  type: 'column',
                  yAxis: 0,
                  data: portFuelConsDataSeries,
                  tooltip: {
                    valueSuffix: ' Liter',
                  },
                  color: hColors.purple,
                  zIndex: 3,
                });

                // Starboard Fuel Cons
                dataSeries.push({
                  name: 'Stb. Fuel Cons.',
                  type: 'column',
                  yAxis: 0,
                  data: stbFuelConsDataSeries,
                  tooltip: {
                    valueSuffix: ' Liter',
                  },
                  color: hColors.teal,
                  zIndex: 3,
                });
              } else {
                data['flowmeter'].data.forEach((item) => {
                  allMeFuelConsData.push(
                    item.meFuelCons ||
                      item.PORT_FuelConsVolume ||
                      item.STARBOARD_FuelConsVolume
                  );
                });

                const meFuelConsDataSeries: (number | undefined)[] = [];

                if (data[key]) {
                  newCategories.forEach((item: any) => {
                    const isTimestampSame = data[key]?.data.find(
                      (data: IFlowmeterData) =>
                        data &&
                        data.PORT_IN &&
                        item ===
                          roundTimestampsToNearestMinute(
                            data.PORT_IN.timestamp * 1000
                          ) /
                            1000
                    );

                    if (isTimestampSame) {
                      const meFuelCons =
                        isTimestampSame.PORT_FuelConsVolume +
                        (isTimestampSame.STARBOARD_FuelConsVolume || 0);
                      meFuelConsDataSeries.push(
                        meFuelCons < 0 ? 0 : meFuelCons
                      );
                    } else {
                      meFuelConsDataSeries.push(undefined);
                    }
                  });
                }

                // SINGLE ENGINE
                dataSeries.push({
                  name: 'ME. Fuel Cons.',
                  type: 'column',
                  yAxis: 1,
                  data: meFuelConsDataSeries,
                  tooltip: {
                    valueSuffix: ' Liter',
                  },
                  color: hColors.black,
                  zIndex: 3,
                });
              }
            }

            if (key === 'rpm' && isSingleEngine) {
              const meDataSeries: (number | undefined)[] = [];

              newCategories.forEach((item: any) => {
                const isTimestampSame = data[key]?.data.find(
                  (data: IRPMData) => {
                    if (
                      data['MAINENGINE'] &&
                      item ===
                        roundTimestampsToNearestMinute(
                          data['MAINENGINE'].timestamp * 1000
                        ) /
                          1000
                    ) {
                      return data;
                    } else if (
                      data['PORT'] &&
                      item ===
                        roundTimestampsToNearestMinute(
                          data['PORT'].timestamp * 1000
                        ) /
                          1000
                    ) {
                      return data;
                    } else if (
                      data['STARBOARD'] &&
                      item ===
                        roundTimestampsToNearestMinute(
                          data['STARBOARD'].timestamp * 1000
                        ) /
                          1000
                    ) {
                      return data;
                    }
                  }
                );

                if (isTimestampSame) {
                  meDataSeries.push(
                    isTimestampSame['MAINENGINE']
                      ? isTimestampSame['MAINENGINE'].rpm
                      : isTimestampSame['PORT']
                      ? isTimestampSame['PORT'].rpm
                      : isTimestampSame['STARBOARD']
                      ? isTimestampSame['STARBOARD'].rpm
                      : 0
                  );
                } else {
                  meDataSeries.push(undefined);
                }
              });

              dataSeries.push({
                name: 'ME. RPM',
                type: 'column',
                yAxis: 0,
                data: meDataSeries,
                tooltip: {
                  valueSuffix: ' RPM',
                },
                color: hColors.purpleOpacity,
                borderColor: hColors.purple,
                zIndex: 3,
              });
            }
          });

          if (allPortStbFuelCons.length > 0 || allMeFuelConsData.length > 0) {
            const maxFuelCons = !isSingleEngine
              ? allPortStbFuelCons.sort((a, b) => b - a)[0]
              : allMeFuelConsData.sort((a, b) => b - a)[0];

            const dataFuelConsFiltered = (
              !isSingleEngine ? allPortStbFuelCons : allMeFuelConsData
            ).filter((item) => item > 0);

            const avgFuelCons =
              dataFuelConsFiltered.reduce((acc, curr) => acc + curr, 0) /
              dataFuelConsFiltered.length;

            setSummary({
              max: maxFuelCons,
              avg: avgFuelCons,
            });

            dataSeries.push({
              name: 'Max Fuel Cons.',
              type: 'line',
              yAxis: 0,
              marker: {
                enabled: false,
              },
              data: generateArrays(maxFuelCons, newCategories.length),
              tooltip: {
                valueSuffix: ' Liter',
              },
              color: hColors.red,
              zIndex: 5,
            });

            dataSeries.push({
              name: 'Avg. Fuel Cons.',
              type: 'line',
              yAxis: 0,
              marker: {
                enabled: false,
              },
              data: generateArrays(avgFuelCons, newCategories.length),
              tooltip: {
                valueSuffix: ' Liter',
              },
              color: hColors.yellow,
              zIndex: 5,
            });
          }

          const newDataCategories = newCategories.map((item) =>
            moment(item * 1000).format('DD/MM/YY HH:mm')
          );

          currChartOption = {
            ...currChartOption,
            xAxis: [
              {
                categories: newDataCategories,
                crosshair: true,
              },
            ],
            series: dataSeries.map((serie: any) => {
              return {
                ...serie,
                data: [...serie.data].map((value: number | null) => {
                  return typeof value === 'undefined'
                    ? null
                    : (value && typeof value === 'number') || value === 0
                    ? Number(value.toFixed(2))
                    : null;
                }),
              };
            }),
          };

          setDataChart(currChartOption);
        };

        createDataSeries();
      }

      // eslint-disable-next-spline react-hooks/exhaustive-deps
    }, [data, isSingleEngine]);

    return (
      <>
        {!isDailyReport && <TitleCard title='ME Fuel Consumption' />}
        <div ref={ref} style={{ height: '100%' }}>
          {dataChart && dataChart.series.length === 0 ? (
            <EmptyChart>
              <h3>Empty Data</h3>
              <p>Data series empty</p>
            </EmptyChart>
          ) : (
            <HighchartsReact
              containerProps={{
                style: {
                  height: isDailyReport ? '70%' : '90%',
                },
              }}
              highcharts={Highcharts}
              options={{
                ...dataChart,
                chart: {
                  zoomType: 'xy',
                  // height: height || 480,
                  // width,
                },
              }}
              allowChartUpdate={true}
              immutable={true}
            />
          )}

          {isDailyReport &&
            dataFilter.interval === 'HOUR' &&
            data &&
            dataTable && (
              <table
                className='custom-table-daily-report'
                style={{
                  width: isDailyReport ? '100%' : '90%',
                  margin: '0 auto',
                }}
              >
                <tbody>
                  <tr>
                    <th>Hour</th>
                    {columns.map((item) => (
                      <td key={item}>{item}</td>
                    ))}
                  </tr>
                  {!isSingleEngine ? (
                    <>
                      <tr>
                        <th>Port Fuel Cons.</th>
                        {dataTable.flowmeter &&
                        dataTable.flowmeter.data.length > 0
                          ? dataTable.flowmeter.data.map(
                              (item: IFlowmeterData, idx: number) => (
                                <td key={`port-fuel-${idx}`}>
                                  {roundValue(item.portFuelCons || 0)}
                                </td>
                              )
                            )
                          : generateArrays(
                              0,
                              columns ? columns?.length : 24
                            ).map((item) => <td>{item}</td>)}
                      </tr>
                      <tr>
                        <th>Stb. Fuel Cons.</th>
                        {dataTable.flowmeter &&
                        dataTable.flowmeter.data.length > 0
                          ? dataTable.flowmeter.data.map(
                              (item: IFlowmeterData, idx: number) => (
                                <td key={`stb-fuel-${idx}`}>
                                  {roundValue(item.stbFuelCons || 0)}
                                </td>
                              )
                            )
                          : generateArrays(
                              0,
                              columns ? columns?.length : 24
                            ).map((item) => <td>{item}</td>)}
                      </tr>
                    </>
                  ) : (
                    <>
                      <tr>
                        <th>ME Fuel Cons.</th>
                        {dataTable.flowmeter &&
                        dataTable.flowmeter.data.length > 0
                          ? dataTable.flowmeter.data.map(
                              (item: IFlowmeterData, idx: number) => (
                                <td key={`me-fuel-${idx}`}>
                                  {roundValue(item.meFuelCons || 0)}
                                </td>
                              )
                            )
                          : generateArrays(
                              0,
                              columns ? columns?.length : 24
                            ).map((item) => <td>{item}</td>)}
                      </tr>
                    </>
                  )}
                  <tr>
                    <th>Avg. Fuel Cons.</th>
                    <td key='avg-fuel' colSpan={24}>
                      {roundValue(summary.avg || 0)}
                    </td>
                  </tr>
                  <tr>
                    <th>Max. Fuel Cons.</th>
                    <td key='max-fuel' colSpan={24}>
                      {roundValue(summary.max || 0)}
                    </td>
                  </tr>
                </tbody>
              </table>
            )}
        </div>
      </>
    );
  }
);

export default HChartMeFuelCons;
