import {
  ISummary,
  initialAEData,
  initialFlowmeterData,
  initialGPSData,
  initialPORTRPMData,
  initialSTBRPMData,
} from '../types/summary.type';

export const hChartConfig = {
  chart: {
    zoomType: 'xy',
    height: 480,
    // width: "100%",
    reflow: true,
    animation: false,
  },
  title: {
    text: null,
  },
  credits: {
    enabled: false,
  },
  plotOptions: {
    series: {
      groupPadding: 0.2,
      pointPadding: 0,
      animation: false,
    },
  },
  tooltip: {
    shared: true,
  },
};

export const hColors = {
  orange: '#ffa216',
  lightOrange: '#FFC977',
  yellow: '#FFBC00',
  pink: '#F34584',
  red: '#FE4545',
  purple: '#6F57E9',
  purpleOpacity: 'rgb(111 87 233 / 80%)',
  teal: '#02CCCC',
  tealOpacity: 'rgb(2 204 204 / 80%)',
  green: '#35CD69',
  lightGreen: 'lime',
  darkBlue: '#163E8A',
  blue: '#1b71f3',
  lightBlue: '#6BA6FF',
  black: '#000000',
  lightBlack: 'rgba(0,0,0,.1)',
};

export function generateArrays(value: number, length: number) {
  const a = [];
  for (let i = 0; i < length; i++) {
    a.push(Number(value.toFixed(2)));
  }
  return a;
}

export function fillEmptyDataTable(
  arr: any[],
  length: number,
  type: 'rpm' | 'gps' | 'flowmeter' | 'ae'
) {
  for (let i = 0; i < length; i++) {
    if (type === 'rpm') {
      arr.push({
        initialPORTRPMData,
        initialSTBRPMData,
      });
    } else if (type === 'gps') {
      arr.push({ ...initialGPSData });
    } else if (type === 'ae') {
      arr.push({ ...initialAEData });
    } else if (type === 'flowmeter') {
      arr.push({ ...initialFlowmeterData });
    }
  }

  return arr;
}

export const generateDataTable = (data: ISummary) => {
  if (data) {
    const newData = {
      gps: {
        ...data['gps'],
        data:
          data['gps'] && data['gps'].data.length < 24
            ? fillEmptyDataTable(
                data['gps'].data,
                24 - data['gps'].data.length,
                'gps'
              )
            : data['gps']?.data,
      },
      rpm: {
        ...data['rpm'],
        data:
          data['rpm'] && data['rpm'].data && data['rpm'].data.length < 24
            ? fillEmptyDataTable(
                data['rpm'].data,
                24 - data['rpm'].data.length,
                'rpm'
              )
            : data['rpm']?.data,
      },
      flowmeter: {
        ...data['flowmeter'],
        data:
          data['flowmeter'] && data['flowmeter'].data.length < 24
            ? fillEmptyDataTable(
                data['flowmeter'].data,
                24 - data['flowmeter'].data.length,
                'flowmeter'
              )
            : data['flowmeter']
            ? data['flowmeter'].data
            : [],
      },
      ae: {
        ...data['ae'],
        data:
          data['ae'] && data['ae'].data.length < 24
            ? fillEmptyDataTable(
                data['ae'].data,
                24 - data['ae'].data.length,
                'ae'
              )
            : data['ae']?.data,
      },
    };

    return newData;
  }
};
